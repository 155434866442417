define(["./functioningEditionModule",
    "../arrays/arrays",
    "text!./nlgExceptionalWorkingHoursEdition.html"
], function (functioningEditionModule, arrays, template) {
    "use strict";

    functioningEditionModule.directive("nlgExceptionalWorkingHoursEdition", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                exceptionalWorkingHours: "=",
                ngDisabled: "=?"
            },
            controller: ["$scope", function ($scope) {

                $scope.exceptionalWorkingHourOptions = {
                    records: $scope.exceptionalWorkingHours,
                    columns: [{
                        header: {
                            disabled: "ngDisabled",
                            template: "<a name='add' class='btn btn-xs green' style='margin-left: 5px;' ng-click='addExceptionalWorkingHour()'><span class='fa fa-plus'></span></a>"
                        },
                        cell: {
                            disabled: "ngDisabled",
                            template: "<a name='remove' class='btn btn-xs red' style='margin-left: 5px; margin-top: 4px;' ng-click='removeExceptionalWorkingHour(record)'><span class='fa fa-trash'></span></a>"
                        },
                        width: "2%",
                        cellName: "buttons"
                    }, {
                        header: "edition.functioning.date",
                        cell: {
                            type: "date",
                            model: "record.date",
                            disabled: "ngDisabled"
                        },
                        width: "5%",
                        cellName: "date"
                    }, {
                        header: "edition.functioning.exceptionalWorkingHourType",
                        cell: {
                            type: "singleTagInput",
                            model: "record.exceptionalWorkingHourType",
                            getTags: "exceptionalWorkingHoursTypes",
                            ngDisabled: "ngDisabled"
                        },
                        width: "25%",
                        cellName: "exceptionalWorkingHourType"
                    }, {
                        header: "edition.functioning.startTime",
                        cell: {
                            type: "time",
                            model: "record.startTime",
                            disabled: "ngDisabled"
                        },
                        width: "10%",
                        cellName: "startTime"
                    }, {
                        header: "edition.functioning.endTime",
                        cell: {
                            type: "time",
                            model: "record.endTime",
                            disabled: "ngDisabled"
                        },
                        width: "10%",
                        cellName: "endTime"
                    }, {
                        header: "edition.functioning.reason",
                        cell: {
                            type: "text",
                            model: "record.reason",
                            disabled: "ngDisabled"
                        },
                        width: "50%",
                        cellName: "reason"
                    }]
                };

                $scope.addExceptionalWorkingHour = function () {
                    $scope.exceptionalWorkingHours.push({date: new Date()});
                };
                $scope.removeExceptionalWorkingHour = function (exception) {
                    arrays.removeAt($scope.exceptionalWorkingHourOptions.records, $scope.exceptionalWorkingHourOptions.records.indexOf(exception));
                };

                $scope.exceptionalWorkingHoursTypes = [
                    "br.com.neolog.model.ExceptionalWorkingHours$ExceptionalWorkingHourType.WORKING",
                    "br.com.neolog.model.ExceptionalWorkingHours$ExceptionalWorkingHourType.NOT_WORKING"
                ];
            }]
        };
    }]);
});

