define(["angular",
    "angular-translate",
    "angular-bootstrap",
    "../violation/violation"
], function (angular) {
    "use strict";

    /**
     * @ngdoc overview
     * @name cancellationModule
     * @description
     * Módulo com funcionalidades relacionados ao cancelamento de viagens.
     * */
    return angular.module("commonsCancellationModule", [
        "pascalprecht.translate",
        "ui.bootstrap",
        "violationModule"
    ]);
});