define([
    "./nlgTagInputModule",
    "text!./nlgTagInputGetIconView.html"
], function (module, template) {
    return module.directive("nlgTagInputGetIcon", ["nlgTagInputIconMap", function (nlgTagInputIconMap) {
        "use strict";

        /**
         * @ngdoc directive
         * @name module.directive:nlgTagInputGetIcon
         * @param {string=} iconAttribute atributo dentro do match que define o ícone,
         * caso vazio o atributo será o próprio match.model (casos de enum).
         * */

        return {
            restrict: "E",
            scope: {
                iconAttribute: "@?",
                match: "="
            },
            template: template,
            link: function (scope) {
                var enumToIcon = nlgTagInputIconMap.iconMap();
                var attributeIcon = scope.iconAttribute ? scope.match[scope.iconAttribute] : scope.match.model;
                scope.iconSource = enumToIcon[attributeIcon] || enumToIcon["default"];
            }
        };
    }]);

});