define([
    "./sortingModule",
    "angular",
    "text!./nlgSortingResult.html"
], function (sortingModule, angular, template) {
    "use strict";

    return sortingModule.service("sortingListModal", ["$modal", function ($modal) {

        return function (filterSort) {
            return $modal.open({
                template: template,
                size: "md",
                controller: ["$scope", function (modalScope) {
                    modalScope.sortModal = angular.copy(filterSort.sortableFields);
                    modalScope.isActivated = filterSort.sortingActivated;

                    //elimina residuo da api
                    modalScope.confirmAlteration = function () {
                        angular.forEach(modalScope.sortModal, function (field) {
                            delete field.selected;
                        });
                        filterSort.sortableFields = modalScope.sortModal;
                        filterSort.sortingActivated = modalScope.isActivated;
                        modalScope.$close(filterSort);
                    };
                }]
            }).result;
        };

    }]);
});