define(["angular", "three", "three-orbit"], function (angular) {
    "use strict";

    /**
     * @ngdoc overview
     * @name threeModule
     * @description
     * Módulo com funcionalidades para exibição e manipulação de gráficos 3D.
     * */
    return angular.module("threeModule", []);
});