define([
    "angular",
    "./regionModule"
], function (angular, regionModule) {
    "use strict";
    regionModule.provider("regionServiceUrls", [function () {

        var regionGroupsUrl;
        var regionsByRegionGroupUrlSupplier;

        this.$get = [function () {
            return {
                regionGroupsUrl: regionGroupsUrl,
                regionsByRegionGroupUrlSupplier: regionsByRegionGroupUrlSupplier
            };
        }];

        function validateUrl(url) {
            if (!angular.isObject(url)) {
                throw new Error("url is not an object: " + url);
            }
            var urlHasOwnProperty = Object.prototype.hasOwnProperty.bind(url);
            if (!urlHasOwnProperty("regionGroupsUrl")) {
                throw new Error("region groups url must be defined");
            }
            if(!urlHasOwnProperty("regionsByRegionGroupUrlSupplier")){
                throw new Error("regions by region group supplier must be defined");
            }
            if(!angular.isFunction(url.regionsByRegionGroupUrlSupplier)){
                throw new Error("regions by region group supplier is not a function");
            }
        }

        this.setUrl = function (url) {
            validateUrl(url);
            regionGroupsUrl = url.regionGroupsUrl;
            regionsByRegionGroupUrlSupplier = url.regionsByRegionGroupUrlSupplier;
        };
    }]);
});
