define(["angular",
    "./nlgSelectionModule"
], function (angular, selectionModule) {
    "use strict";

    selectionModule.provider("nlgSelectionService", [function () {

        var selectionOrderBasketUrl = null;

        this.setOrderBasketSelectionUrl = function (url) {
            selectionOrderBasketUrl = url;
        };

        this.$get = ["$http", "loadingService", function ($http, loadingService) {
            return {
                getOrderNodeIdentifiers: function (patches) {
                    return loadingService($http.post(selectionOrderBasketUrl, patches).then(function (response) {
                        return response.data;
                    }));
                }
            };
        }];
    }]);
});