define([
    "./menuCommonsModule"
], function (module) {
    "use strict";

    return module.service("menuCommonsService", ["$translate", function ($translate) {
        var self = this;

        self.filterMenu = function (userMenu, key, searchText) {
            return userMenu.reduce(function (filteredMenu, menuItem) {
                var filteredSubMenus = menuItem.subMenus.reduce(function (filteredSubMenus, menuItem) {
                    if (hasText(menuItem, key, searchText)) {
                        filteredSubMenus.push(menuItem);
                        return filteredSubMenus;
                    }
                    if (self.hasSubMenus(menuItem)) {
                        var subMenus = reduceSubMenus(menuItem.subMenus, key, searchText);
                        if (subMenus.length) {
                            menuItem.subMenus = subMenus;
                            filteredSubMenus.push(menuItem);
                        }
                    }
                    return filteredSubMenus;
                }, []);
                if (filteredSubMenus.length) {
                    menuItem.subMenus = filteredSubMenus;
                    filteredMenu.push(menuItem);
                }
                return filteredMenu;
            }, []);
        };

        function hasText(menuItem, key, searchText) {
            var translated = $translate.instant(menuItem[key]).toLowerCase();
            return translated.indexOf(searchText.toLowerCase()) !== -1;
        }

        function reduceSubMenus(menuItem, key, searchText) {
            return menuItem.reduce(function (filteredMenuItems, menuItem) {
                if (hasText(menuItem, key, searchText)) {
                    filteredMenuItems.push(menuItem);
                }
                if (self.hasSubMenus(menuItem)) {
                    var filteredSubMenus = reduceSubMenus(menuItem.subMenus, key, searchText);
                    if (filteredSubMenus.length) {
                        var exists = filteredMenuItems.some(function (item) {
                            return item.bundle === menuItem.bundle;
                        });
                        if (!exists) {
                            menuItem.subMenus = filteredSubMenus;
                            filteredMenuItems.push(menuItem);
                        }
                    }
                }
                return filteredMenuItems;
            }, []);
        }

        self.hasSubMenus = function (menuItem) {
            return menuItem.subMenus && menuItem.subMenus.length;
        };

        self.hasMenuItems = function (menuItems) {
            return menuItems.length > 0;
        };

        self.isMenuActive = function (menu, isSubMenuActive) {
            return menu.subMenus.some(function (subMenus) {
                return hasSubmenuActive(subMenus, isSubMenuActive);
            });
        };

        function hasSubmenuActive(subMenu, isSubMenuActive) {
            if (isSubMenuActive(subMenu)) {
                return true;
            }
            if (self.hasSubMenus(subMenu)) {
                return subMenu.subMenus.some(function (subMenu) {
                    return hasSubmenuActive(subMenu, isSubMenuActive);
                });
            }
        }

        self.sortMenus = function (menus) {
            menus.forEach(function (menu) {
                sortSubMenus(menu.subMenus);
                menu.subMenus.forEach(function (subMenus) {
                    sortSubMenus(subMenus.subMenus);
                    return self.sortMenus(subMenus.subMenus);
                });
            });
        };

        function sortSubMenus(subMenus) {
            return subMenus.sort(function (subMenu1, subMenu2) {
                var subMenuName1 = translate(subMenu1);
                var subMenuName2 = translate(subMenu2);
                return subMenuName1.localeCompare(subMenuName2);
            });
        }

        function translate(value) {
            return $translate.instant(value.key).toLowerCase();
        }

    }]);
});