define(["./violationModule", "text!./innerArgumentsMessage.html"], function (violationModule, innerArgumentsMessage) {
    "use strict";

    /**
     * @ngdoc directive
     * @name violationModule.directive:innerArgumentsMessage
     * @restrict E
     * @description
     * Diretiva que parametriza uma mensagem com argumentos.
     *
     * @param {string} bundle chave do bundle a ser interpolado.
     * @param {string} bundle chave do bundle a ser interpolado.
     * */
    return violationModule.directive("innerArgumentsMessage", [function () {
        return {
            restrict: "E",
            template: innerArgumentsMessage,
            scope: {
                bundle: "<",
                arguments: "<"
            }
        };
    }]);
});