define(["angular", "./violationModule", "text!./nlgViolationsInnerBody.html", "../arrays/arrays"], function (angular, violationModule, template, arrays) {
    "use strict";

    /**
     * @ngdoc directive
     * @name directives.directive:nlgViolationsInnerBody
     * @description
     * Diretiva que exibe violações de viagens (para ser embutido em modal).
     *
     * */
    return violationModule.directive("nlgViolationsInnerBody", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                result: "=",
                canContinue: "=",
                hideRoutingContext: "<"
            },
            controller: ["$scope", "$q", function ($scope, $q) {
                $scope.summary = $scope.result.summary;
                $scope.messagesByCreatedDU = $scope.result.summary.messagesByCreatedDU;
                $scope.messagesByDU = $scope.result.summary.messagesByDU;

                $scope.messagesByCreatedTrip = $scope.result.summary.messagesByCreatedTrip;
                $scope.messagesByTrip = $scope.result.summary.messagesByTrip;

                $scope.hasMessagesByCreatedDU = $scope.messagesByCreatedDU.length;
                $scope.hasMessagesByDU = Object.keys($scope.messagesByDU).length;

                $scope.hasMessagesByCreatedTrip = $scope.messagesByCreatedTrip.length;
                $scope.hasMessagesByTrip = Object.keys($scope.messagesByTrip).length;

                $scope.hasErrors = $scope.summary.errors.length;
                $scope.hasWarnings = hasWarningMessages($scope.summary.warnings);
                $scope.hasMessages = $scope.result.summary.allMessages.length;

                $scope.messagesByIdentifier = $scope.result.summary.messagesByIdentifier;
                $scope.hasPlanMessages = $scope.result.summary.messages.length;

                $scope.canContinue = $scope.result.success;

                $scope.violationMessagesOfCreatedDUPaginationOptions = {
                    pagination: {
                        maxPageSizes: [10, 25, 50, 100],
                        maxPageSize: 10,
                        onPaginate: function (firstResult, maxResults) {
                            return $q.when({
                                firstResult: firstResult,
                                maxResults: maxResults,
                                count: $scope.messagesByCreatedDU.length,
                                result: angular.copy($scope.messagesByCreatedDU).splice(firstResult, maxResults)
                            });
                        }
                    },
                    onRegisterPagination: function (paginationApi) {
                        paginationApi.seekPage(1);
                    }
                };

                $scope.violationMessagesOfDUPaginationOptions = {
                    pagination: {
                        maxPageSizes: [10, 25, 50, 100],
                        maxPageSize: 10,
                        onPaginate: function (firstResult, maxResults) {
                            var duCodes = Object.keys($scope.messagesByDU);
                            return $q.when({
                                firstResult: firstResult,
                                maxResults: maxResults,
                                count: duCodes.length,
                                result: duCodes.splice(firstResult, maxResults)
                            });
                        }
                    },
                    onRegisterPagination: function (paginationApi) {
                        paginationApi.seekPage(1);
                    }
                };

                $scope.violationMessagesOfCreatedTripsPaginationOptions = {
                    pagination: {
                        maxPageSizes: [10, 25, 50, 100],
                        maxPageSize: 10,
                        onPaginate: function (firstResult, maxResults) {
                            return $q.when({
                                firstResult: firstResult,
                                maxResults: maxResults,
                                count: $scope.messagesByCreatedTrip.length,
                                result: angular.copy($scope.messagesByCreatedTrip).splice(firstResult, maxResults)
                            });
                        }
                    },
                    onRegisterPagination: function (paginationApi) {
                        paginationApi.seekPage(1);
                    }
                };

                $scope.violationMessagesOfTripsPaginationOptions = {
                    pagination: {
                        maxPageSizes: [10, 25, 50, 100],
                        maxPageSize: 10,
                        onPaginate: function (firstResult, maxResults) {
                            var tripCodes = Object.keys($scope.messagesByTrip);
                            return $q.when({
                                firstResult: firstResult,
                                maxResults: maxResults,
                                count: tripCodes.length,
                                result: tripCodes.splice(firstResult, maxResults)
                            });
                        }
                    },
                    onRegisterPagination: function (paginationApi) {
                        paginationApi.seekPage(1);
                    }
                };

                //planMessages
                $scope.planMessagesPaginationOptions = {
                    pagination: {
                        maxPageSizes: [10, 25, 50, 100],
                        maxPageSize: 10,
                        onPaginate: function (firstResult, maxResults) {
                            var messagesIdentifier = Object.keys($scope.messagesByIdentifier);
                            return $q.when({
                                firstResult: firstResult,
                                maxResults: maxResults,
                                count: messagesIdentifier.length,
                                result: messagesIdentifier.splice(firstResult, maxResults)
                            });
                        }
                    },
                    onRegisterPagination: function (paginationApi) {
                        paginationApi.seekPage(1);
                    }
                };

                $scope.getMessageType = function (typeString) {
                    return typeString.replace(/^.*\./, "");
                };

                function hasWarningMessages(warnings) {
                    var warningsFiltered = arrays.filter(warnings,function (warning) {
                        return warning.messages.length > 0;
                    });
                    return warningsFiltered.length > 0;
                }

            }]
        };
    }]);
});
