define(["./mapsModule", "../arrays/arrays", "lodash"], function (mapsModule, arrays, _) {
    "use strict";

    /**
     * @ngdoc service
     * @name mapsModule.mapsPathService
     * @description
     * Serviço que recebe uma rota e processa um caminho a partir de uma variação.
     * **Atenção:** Serão adicionadas propriedades nas rotas.
     * */
    return mapsModule.service("mapsPathService", ["mapsLatLngApi", "$http", "$q", "mapsPathServiceUrl", function (mapsLatLngApi, $http, $q, mapsPathServiceUrl) {
        var mapsLatLng = null;
        var routePathUrl = mapsPathServiceUrl.getRoutePathUrl();

        return function (route) {
            return mapsLatLngApi.then(function (mapsLatLngApi) {
                mapsLatLng = mapsLatLngApi;
            }).then(function () {
                var positions = _.map(route.stops, "address.geographicInfo");
                return (function retry(positions) {
                    return routePath(positions, route.pathProviderVariation, route.country)
                        .catch(function (error) {
                           if(positions.length === 2) {
                               return positions;
                           } else if (isRouteNotFound(error) && positions.length > 2) {
                                return $q.all([
                                    retry(positions.slice(0, (positions.length + 1) / 2)),
                                    retry(positions.slice(positions.length / 2)),
                                ]).then(function (route1, route2) {
                                        return route1.concat(route2);
                                });
                            } else {
                                return $q.reject(error);
                            }
                        });
                })(positions)
                    .then(function (path) {
                        var coordinates = toLatLng(_.flattenDeep(path));
                        var result = createResultObject(coordinates);
                        return populateLegDirections(route)
                            .then(function () {
                                route.results = [result];
                                return route;
                            });
                    });
            });
        };

        function isRouteNotFound(error) {
            return error.status === 422;
        }

        // Resolve as legs de retorno à origem ou início de logística reversa
        function populateLegDirections(route) {
            var promises = [];
            if (route.startingLeg) {
                var startingLegPositions = _.map(route.startingLeg, "address.geographicInfo");
                promises.push(routePath(startingLegPositions, route.pathProviderVariation, route.country)
                    .then(function (path) {
                        var coordinates = toLatLng(path);
                        route.startingLeg.directions = createResultObject(coordinates, true);
                    }));
            }
            if (route.endingLeg) {
                var endingLegPositions = _.map(route.endingLeg, "address.geographicInfo");
                promises.push(routePath(endingLegPositions, route.pathProviderVariation, route.country)
                    .then(function (path) {
                        var coordinates = toLatLng(path);
                        route.endingLeg.directions = createResultObject(coordinates, true);
                    }));
            }
            return $q.all(promises);
        }

        function toLatLng(positions) {
            var coordinates = [];
            arrays.each(positions, function (coordinate) {
                if(coordinate !== undefined) {
                    coordinates.push(mapsLatLng.toLatLng(coordinate));
                }
            });
            return coordinates;
        }

        function createResultObject(coordinates, startOrEndingLeg) {
            var legs = [];
            if (startOrEndingLeg) {
                legs = [{
                    steps: [{
                        path: coordinates,
                        travel_mode: "DRIVING"
                    }]
                }];
            }
            var result = {
                request: {
                    travelMode: "DRIVING"
                },
                routes: [{
                    legs: legs,
                    overview_path: coordinates
                }]
            };
            return result;
        }

        function routePath(positions, variation, country) {
            return $http.post(routePathUrl, positions, {
                params: {
                    variation: variation,
                    country: country
                }
            }).then(function (response) {
                return response.data;
            });
        }
    }]);
});