define(["./genericParamsModule", "../arrays/arrays"], function (genericParamsModule, arrays) {
    "use strict";

    return genericParamsModule.filter("genericParamDataRepeatedValue", ["entityFormatFilter", function (entityFormatFilter) {
        return function genericParamDataRepeatedValue(object) {
            if (!object) {
                return "";
            }

            if (arrays.isArray(object)) {
                return arrays.map(object, genericParamDataRepeatedValue).join("; ");
            }

            var propertiesAndValues = object.propertiesAndValues;
            return arrays.map(propertiesAndValues, function (propertyAndValue) {
                    return "\"" + entityFormatFilter(propertyAndValue.key) + "\"" +
                        " = " +
                        "\"" + entityFormatFilter(propertyAndValue.value) + "\"";
                }).join(",");
        };
    }]);
});