define([
    "./monitorModule"
], function (module) {
    "use strict";

    return module.controller("MonitorApplicationController", [
        "$scope",
        "monitorService",
        "applicationName",
        "instance",
        "loadingService",
        function ($scope, monitorService, applicationName, instance, loadingService) {
            $scope.applicationName = applicationName;
            $scope.instance = instance;

            $scope.endpoints = monitorService.ENDPOINTS.map(function (endpoint) {
                return {
                    name: endpoint,
                    value: null,
                    error: null
                };
            });

            $scope.loadEndpoint = function (endpoint) {
                endpoint.value = null;
                endpoint.error = null;

                return loadingService(monitorService.findEndpoint(instance, endpoint.name)
                    .then(function (data) {
                        endpoint.value = data;
                    })
                    .catch(function (error) {
                        endpoint.error = error;
                    }));
            };
        }]);
});