define([
    "./nlgNullableCheckboxModule"
], function (nlgNullableCheckboxModule) {
    "use strict";

    /**
     * @ngdoc directive
     * @name directives.directive:nlgNullableCheckbox
     * @element input
     * @restrict A
     * @description
     * Diretiva que permite usar o checkbox com os valores verdadeiro, falso ou nulo.
     * @example
     * <example module="FrontEndWeb">
     *     <file name="index.html">
     *       <input type="checkbox" ng-model="selection" nlg-nullable-checkbox>
     *       </br>
     *       <span>Value = {{selection}}</span>
     *     </file>
     * </example>
     */
    nlgNullableCheckboxModule.directive("nlgNullableCheckbox", [function () {
        return {
            restrict: "A",
            require: "ngModel",
            link: function (scope, element, attrs, ngModelController) {
                if (element.prop("tagName") !== "INPUT" || element.attr("type").toUpperCase() !== "CHECKBOX") {
                    throw new Error("element must be an input[type=checkbox]");
                }
                ngModelController.$formatters = [];
                ngModelController.$parsers = [];
                ngModelController.$render = function () {
                    var viewValue = ngModelController.$viewValue;
                    element.data("checked", viewValue);
                    switch (viewValue) {
                        case true:
                            element.prop("indeterminate", false);
                            element.prop("checked", true);
                            break;
                        case false:
                            element.prop("indeterminate", false);
                            element.prop("checked", false);
                            break;
                        default:
                            element.prop("indeterminate", true);
                    }
                };

                element.bind("click", function () {
                    var nextValue;
                    switch (element.data("checked")) {
                        case false:
                            nextValue = true;
                            break;
                        case true:
                            nextValue = null;
                            break;
                        default:
                            nextValue = false;
                    }
                    ngModelController.$setViewValue(nextValue);
                    scope.$apply(ngModelController.$render);
                });
            }
        };
    }]);
});