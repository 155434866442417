define(["./timeModule", "angular"], function (timeModule, angular) {
    "use strict";

    /**
     * @ngdoc filter
     * @name timeModule.duration
     * @description
     * Filtro para exibir uma duração.
     * @param {number} duration Duração em milissegundos.
     * @returns {string} string com a duração.
     * @throws Joga erro quando a duração não é um número.
     * */
    return timeModule.filter("duration", ["jsonFilter", function (jsonFilter) {
        var SUFFIX = {
            DAY: "d",
            HOUR: "h",
            MINUTE: "m",
            SECOND: "s"
        };

        return function (duration) {
            if (angular.isUndefined(duration) || duration === null) {
                return "";
            }

            if (!angular.isNumber(duration)) {
                throw new Error("Duration should be the number of milliseconds. Got: " + jsonFilter(duration));
            }

            if (duration === 0) {
                return "0";
            }

            var seconds = parseInt(duration / 1000, 10);
            var minutes = parseInt(seconds / 60, 10);
            var hours = parseInt(minutes / 60, 10);
            var days = parseInt(hours / 24, 10);

            if(duration < 60000) {
                return (
                    stringForTime(seconds % 60, SUFFIX.SECOND)
                ).trim();
            }

            return (
                stringForTime(days, SUFFIX.DAY) +
                stringForTime(hours % 24, SUFFIX.HOUR) +
                stringForTime(minutes % 60, SUFFIX.MINUTE)
            ).trim();
        };

        function stringForTime(time, suffix) {
            if (time > 0) {
                return time + suffix + " ";
            }
            return "";
        }
    }]);
});