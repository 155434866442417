define([
    "angular",
    "../path/path",
], function (angular) {
    "use strict";

    return angular.module("nlgOperationOverviewModule", [
        "pathModule"
    ]);
});
