define(["./violationModule", "angular"], function (violationModule, angular) {
    "use strict";

    return violationModule.filter("dtoLink", ["entityFormatFilter", "dtoLinkService", function (entityFormat, dtoLinkService) {
        function createLink(value) {
            var anchorElement = angular.element("<a>" + entityFormat(value) + "</a>")
                .attr("target", "_blank")
                .attr("ui-sref", dtoLinkService.getRouteForDto(value));
            return anchorElement[0].outerHTML;
        }

        return function (param) {
            if (!param) {
                return "";
            }
            if (!angular.isArray(param) && !param.dtos && !param.type) {
                return entityFormat(param);
            }
            if (param.type) {
                return createLink(param);
            }
            return param.dtos.map(createLink).join(", ");
        };
    }]);
});