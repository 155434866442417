define(["angular", "./translateModule"], function (angular, translateModule) {
    "use strict";

    /**
     * @ngdoc filter
     * @name filters.filter:recursiveTranslate
     *
     * @function
     * @param {string} translationId chave de bundle cujo valor pode conter outros translationIds, ou texto que possua translationIds.
     * @param {string} interpolateParams parâmetros para a mensagem raiz.
     * @return {string} texto internacionalizado.
     *
     * @description
     */
    return translateModule.filter("recursiveTranslate", ["$filter", function ($filter) {
        var translateFilter = $filter("translate");
        return function recursiveTranslate(translationId, interpolateParams) {
            var translatedText = translateFilter(translationId, interpolateParams);
            if (translationId === translatedText) {
                return translatedText;
            }
            var bundleKeyRegex = /(\w+\.)+\w+/;
            var bundleMatch = bundleKeyRegex.exec(translatedText);
            if (bundleMatch) {
                return recursiveTranslate(translatedText.replace(bundleMatch[0], translateFilter(bundleMatch[0])));
            }
            return translatedText;
        };
    }]);
});