define([
    "./threeModule",
    "./threeBoxMeshFactory",
    "./threeDrumMeshFactory",
    "./threeVehicleMeshFactory",
    "./threePalletMeshFactory",
    "./threeTubeMeshFactory",
], function (threeModule) {
    "use strict";

    return threeModule.service("threeMeshFactory", [
        "threeBoxMeshFactory",
        "threeDrumMeshFactory",
        "threeVehicleMeshFactory",
        "threePalletMeshFactory",
        "threeTubeMeshFactory",
        function (threeBoxMeshFactory, threeDrumMeshFactory, threeVehicleMeshFactory, threePalletMeshFactory, threeTubeMeshFactory) {
            var typeToFactory = {
                "br.com.neolog.service.arrangement.viewer.model.Object3DType.DRUM": threeDrumMeshFactory,
                "br.com.neolog.service.arrangement.viewer.model.Object3DType.TUBE": threeTubeMeshFactory,
                "br.com.neolog.service.arrangement.viewer.model.Object3DType.PALLET": threePalletMeshFactory,
                "br.com.neolog.service.arrangement.viewer.model.Object3DType.VEHICLE": threeVehicleMeshFactory,
                "br.com.neolog.service.arrangement.viewer.model.Object3DType.ADDITIONAL_OBJECT": threeBoxMeshFactory
            };

            return function (objectType) {
                return typeToFactory[objectType] || threeBoxMeshFactory;
            };
        }]);
});