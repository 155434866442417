define(["angular", "angular-bootstrap"], function (angular) {
    "use strict";

    /**
     * @ngdoc overview
     * @name uiBootstrapModule
     * @description
     * Este módulo contém algumas configurações sobre o ui.bootstrap.
     * */
    return angular.module("uiBootstrapModule", [
        "ui.bootstrap"
    ]);
});