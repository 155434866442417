define([
    "./monitorModule",
    "uuid"
], function (module, uuid) {
    "use strict";

    return module.config(["$httpProvider", function ($httpProvider) {
        $httpProvider.interceptors.push(["$window", function ($window) {
            return {
                "request": function (config) {
                    config.headers["SESSION_ID"] =
                        "FRONT_END;"
                        + getCurrentUserName() + ";"
                        + uuid();
                    return config;
                }
            };

            function getCurrentUserName() {
                // nao pode depender do authenticationService, pois gera dependência cíclica
                return $window.localStorage.getItem("currentUsername") // CPL
                    || JSON.parse($window.localStorage.getItem("ngStorage-current.user.name")) // R2 / WFA
                    || "<null>";
            }
        }]);
    }]);


});