define([
    "angular",
    "./mapsModule"
], function (angular, mapsModule) {
    "use strict";
    mapsModule.provider("routeServiceUrl", [function () {

        var routeUrlForNodeIdentifier;
        var routeUrlForTripIds;
        var alterStopsUrl;

        this.$get = [function () {
            return {
                getRouteUrlForNodeIdentifier: getRouteUrlForNodeIdentifier,
                getRouteUrlForTripIds: getRouteUrlForTripIds,
                getAlterStopsUrl: getAlterStopsUrl
            };
        }];

        function getRouteUrlForNodeIdentifier() {
            return routeUrlForNodeIdentifier;
        }

        function getRouteUrlForTripIds() {
            return routeUrlForTripIds;
        }

        var getAlterStopsUrl = function () {
            return alterStopsUrl;
        };

        this.configure = function (server) {
            routeUrlForNodeIdentifier = server.routeUrlForNodeIdentifier;
            routeUrlForTripIds = server.routeUrlForTripIds;
            alterStopsUrl = server.alterStopsUrl;
        };
    }]);
});

