define(["./violationModule", "text!./messagesModal.html", "lodash"], function (violationModule, template, _) {
    "use strict";

    /**
     * @ngdoc service
     * @name violationModule.messagesModal
     * @description
     * Serviço que exibe um modal de propósito geral com o `array` de mensagens passado.
     *
     * @param {string} header Texto a ser exibido no cabeçalho do modal
     * @param {string|string[]} messageList `array` de mensagens a serem internacionalizadas e parametrizadas
     * @returns {promise} Promessa que é resolvida quando o modal é fechado.
     * */
    return violationModule.service("messagesModal", ["$modal", function ($modal) {
        function showModal(header, messageList, cancellable, confirmation) {
            return $modal.open({
                template: template,
                controller: ["$scope", function ($scope) {
                    $scope.header = header;
                    $scope.messageList = _.isArray(messageList) ? messageList : [messageList];
                    $scope.cancellable = !!cancellable;
                    $scope.confirmation = !!confirmation;

                    $scope.getBundle = function (message) {
                        return message.keyBundle || message;
                    };
                }]
            }).result;
        }

        var messagesModal = function (header, messageList) {
            return showModal(header, messageList, false, false)
                .catch(function () {
                    // modal apenas informativo, não pode ser "cancelado"
                });
        };
        messagesModal.cancellable = function (header, messageList) {
            return showModal(header, messageList, true, false);
        };
        messagesModal.confirmation = function(header, messageList){
          return showModal(header, messageList, true, true);
        };

        return messagesModal;
    }]);
});