define(["./collectionsModule"], function (collectionsModule) {
    "use strict";

    return collectionsModule.service("Set", ["Iterables", "EntrySetMap", function (Iterables, EntrySetMap) {
        /**
         * @ngdoc service
         * @name collectionsModule.Set
         * @description
         * Implementação de conjuntos.
         * <p>
         * Utiliza um {@link EntrySetMap} para armazenar valores, então as mesmas questões
         * de performance se aplicam à esta implementação.
         * */
        function Set() {
            this.map = new EntrySetMap();
        }

        /**
         * @ngdoc service
         * @name collectionsModule.Set#copyOf
         * @methodOf collectionsModule.Set
         * @description
         * Cria uma cópia de um iterable.
         * @param {object[]} iterable Iterable à ser copiado.
         * @returns {collectionsModule.Set} conjunto copiado.
         * */
        Set.copyOf = function (iterable) {
            return new Set().addAll(iterable);
        };

        /**
         * @ngdoc service
         * @name collectionsModule.Set#of
         * @methodOf collectionsModule.Set
         * @description
         * Cria um conjunto com os elementos fornecidos.
         * @param {object...} value varargs de elementos à serem adicionados no conjunto.
         * @returns {collectionsModule.Set} conjunto com os elementos fornecidos.
         * */
        Set.of = function () {
            return new Set().addAll(arguments);
        };

        /**
         * @ngdoc service
         * @name collectionsModule.Set#add
         * @methodOf collectionsModule.Set
         * @description
         * Adiciona um valor ao conjunto. Valores repetidos não são adicionados.
         * @param {*} value valor à ser adicionado.
         * @returns {collectionsModule.Set} Este conjunto.
         * */
        Set.prototype.add = function (value) {
            this.map.put(value);
            return this;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.Set#addAll
         * @methodOf collectionsModule.Set
         * @description
         * Adiciona todos os valores fornecidos ao conjunto.
         * @param {iterable} values valores à serem adicionados.
         * @returns {collectionsModule.Set} Este conjunto.
         * */
        Set.prototype.addAll = function (values) {
            var self = this;
            Iterables.iterate(values, function (value) {
                self.add(value);
            });
            return self;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.Set#contains
         * @methodOf collectionsModule.Set
         * @description
         * Verifica se um valor foi adicionado ao conjunto.
         * @param {*} value Valor à ser verificado.
         * @returns {boolean} Se o elemento pertence ao conjunto.
         * */
        Set.prototype.contains = function (value) {
            return this.map.containsKey(value);
        };

        /**
         * @ngdoc service
         * @name collectionsModule.Set#containsAll
         * @methodOf collectionsModule.Set
         * @description
         * Verifica se todos os valores fornecidos estão contidos no conjunto.
         * <p>
         * Se nenhum valor for fornecido, é considerado que todos os elementos estão
         * contidos.
         * @param {iterable} values Valores à serem verificados.
         * @returns {boolean} Se os elementos pertencem ao conjunto.
         * */
        Set.prototype.containsAll = function (values) {
            var self = this, contains = true;
            Iterables.iterate(values, function (value) {
                contains = contains && self.contains(value);
            });
            return contains;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.Set#remove
         * @methodOf collectionsModule.Set
         * @description
         * Remove um valor do conjunto.
         * <p>
         * Se o valor não está presente no conjunto, nada é feito.
         * @param {*} value Valor à ser removido.
         * @returns {collectionsModule.Set} Este conjunto.
         * */
        Set.prototype.remove = function (value) {
            this.map.remove(value);
            return this;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.Set#removeAll
         * @methodOf collectionsModule.Set
         * @description
         * Remove todos os valores fornecidos do conjunto.
         * @param {iterable} values Valores à serem removidos.
         * @returns {collectionsModule.Set} Este conjunto.
         * */
        Set.prototype.removeAll = function (values) {
            var self = this;
            Iterables.iterate(values, function (value) {
                self.remove(value);
            });
            return self;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.Set#clear
         * @methodOf collectionsModule.Set
         * @description
         * Remove todos os valores conjunto.
         * @returns {collectionsModule.Set} Este conjunto.
         * */
        Set.prototype.clear = function () {
            this.map.clear();
            return this;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.Set#size
         * @methodOf collectionsModule.Set
         * @description
         * @returns {number} A quantidade de valores neste conjunto.
         * */
        Set.prototype.size = function () {
            return this.map.size();
        };

        /**
         * @ngdoc service
         * @name collectionsModule.Set#isEmpty
         * @methodOf collectionsModule.Set
         * @description
         * @returns {boolean} Se não tem nenhum elemento no conjunto.
         * */
        Set.prototype.isEmpty = function () {
            return this.map.isEmpty();
        };

        /**
         * @ngdoc service
         * @name collectionsModule.Set#each
         * @methodOf collectionsModule.Set
         * @description
         * Executa uma callback para cada elemento do conjunto.
         * @returns {collectionsModule.Set} Este conjunto.
         * */
        Set.prototype.each = function (callback) {
            var self = this;
            self.map.each(function (value, key) {
                return callback.call(self, key, self);
            });
            return self;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.Set#toArray
         * @methodOf collectionsModule.Set
         * @description
         * Retorna uma representação do set como um array.
         * @returns {*[]} Este conjunto convertido para array.
         * */
        Set.prototype.toArray = function () {
            var result = [];
            this.each(function (value) {
                result.push(value);
            });
            return result;
        };

        return Set;
    }]);
});