define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";


    return templateFactoryModule.service("templateFactoryPieTemplate", [function () {
        return function (config) {
            var element = angular.element("<div>");
            if (config.context) {
                element.attr("pie-chart", config.context);
            }
            if (config.width) {
                element.attr("size", config.width);
            }
            if (config.height) {
                element.attr("size", config.height);
            }
            return element;
        };
    }]);
});