define([
    "angular",
    "./nlgScriptSqlQueryReportModule",
    "text!./nlgScriptSqlFilterResult.html",
    "json!./paths.json",
    "../arrays/arrays",
], function (angular, module, template, paths, arrays) {
    "use strict";
    return module.directive("nlgScriptSqlFilterResultDirective", [function () {
        return {
            restrict: "E",
            scope: {
                sqlBaseUrl: "@",
                sqlFilterServiceUrl: "@",
                filterService: "=",
                domain: "@",
                query: "="
            },
            template: template,
            controller: ["$scope",
                "$parse",
                "autocompleteFactory",
                "downloadBinary",
                "$http",
                "loadingService",
                "messagesModal",
                "$q",
                "nlgScriptSqlServiceFactory",
                function (
                    $scope,
                    $parse,
                    autocompleteFactory,
                    downloadBinary,
                    $http,
                    loadingService,
                    messagesModal,
                    $q,
                    nlgScriptSqlServiceFactory) {

                    var queryData;
                    var paginationApi;
                    var nlgScriptSqlService = nlgScriptSqlServiceFactory($scope.sqlBaseUrl);

                    $scope.fileType = "xlsx";
                    $scope.preSelectedFilter = null;

                    $scope.tableOptions = {
                        records: [],
                        columns: [],
                        pagination: {
                            onPaginate: function (firstResult, maxResults) {
                                return nlgScriptSqlService.executeQuery($scope.query.id, queryData, firstResult, maxResults)
                                    .then(function (reg) {
                                        showModalIfEmptyResults(reg);
                                        var headers = nlgScriptSqlService.getArrayOfTranslatedHeaders(reg.data.headers);
                                        $scope.tableOptions.columns = getTableDefinition(headers, reg.data.projections);
                                        return {
                                            firstResult: firstResult,
                                            maxResults: maxResults,
                                            count: reg.data.count,
                                            result: nlgScriptSqlService.getArrayOfRowValues(reg.data.tuples)
                                        };
                                    });
                            }
                        },
                        onRegisterPagination: function (api) {
                            paginationApi = api;
                        }
                    };

                    function showModalIfEmptyResults(result) {
                        if (result.data.tuples.length === 0) {
                            messagesModal("dialog.success", [{
                                keyBundle: "group.query.report.empty.result",
                                parameters: []
                            }]);
                        }
                    }

                    function getTableDefinition(aliases, projections) {
                        var columns = [];
                        arrays.each(aliases, function (alias, index) {
                            var formatter = "";
                            arrays.each(projections, function (projection) {
                                if (projection.name === alias) {
                                    var rawFormatter = nlgScriptSqlService.getProjectionFormatter(projection.formatter);
                                    formatter = nlgScriptSqlService.getPipeFormatter(rawFormatter);
                                }
                            });
                            columns.push({
                                header: alias,
                                cell: "{{record[" + index + "]" + formatter + "}}",
                                width: "115px",
                                //Usado expression para considerar o indice zero.
                                sortBy: "{{" + index + "}}",
                                cellName: alias
                            });
                        });
                        return columns;
                    }

                    $scope.executeQuery = function (selectedFilters) {
                        var selectedFiltersData = {};
                        arrays.each(selectedFilters, function (filterField) {
                            selectedFiltersData[filterField.fieldName] = getValue(filterField);
                        });
                        queryData = selectedFiltersData;
                        return loadingService(paginationApi.seekPage(1));
                    };

                    $scope.exportSpreadsheet = function (queryId, fileType) {
                        return loadingService(nlgScriptSqlService.exportSpreadsheet(queryId, queryData, fileType)).catch(function () {
                            messagesModal("dialog.warning", [{
                                keyBundle: "spreadsheetExportDialog.failed",
                                parameters: []
                            }]);
                        });
                    };

                    function getValue(filterField) {
                        var value = filterField.value;
                        if (value !== null && angular.isDefined(value)) {
                            return value;
                        }
                        return filterField.multipleValue;
                    }
                }]
        };
    }]);
});
