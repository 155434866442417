define(["./mapsModule"], function (mapsModule) {
    "use strict";

    return mapsModule.directive("nlgRouteOverlayPath", [function () {
        return {
            restrict: "E",
            scope: {
                path: "=?",
                paths: "=?",
                toggle: "=?"
            },
            require: "mapControl",
            link: function (scope, attrs, element, mapControlController) {
                scope.mapControlController = mapControlController;
            },
            controller: ["$scope", "uiGmapGoogleMapApi", "mapsLatLngApi", "$attrs", function ($scope, uiGmapGoogleMapApi, mapsLatLngApiPromise, $attrs) {
                var mapApi, mapsLatLngApi, map, polylines = [];
                uiGmapGoogleMapApi.then(function (_mapApi) {
                    return mapApi = _mapApi;
                }).then(function () {
                    return $scope.mapControlController.getMapControl();
                }).then(function (mapControl) {
                    map = mapControl.getGMap();
                    return mapsLatLngApiPromise;
                }).then(function (_mapsLatLngApi) {
                    mapsLatLngApi = _mapsLatLngApi;
                }).then(function () {
                    $scope.$watchCollection("path", function (path) {
                        drawPaths([path]);
                    });
                    $scope.$watchCollection("paths", drawPaths);
                    if($attrs.toggle){
                        $scope.$watch("toggle", togglePath);
                    }
                });

                function clearPaths() {
                    polylines.forEach(function (polyline) {
                        polyline.setMap(null);
                    });
                    polylines = [];
                }

                function drawPaths(paths) {
                    clearPaths();
                    (paths || []).forEach(function (value) {
                        if (!value || !value.path) {
                            return;
                        }
                        var pathPolyline = new mapApi.Polyline({
                            path: value.path.map(mapsLatLngApi.toLatLng),
                            geodesic: true,
                            strokeColor: value.color || "#000000",
                            strokeOpacity: 1.0,
                            strokeWeight: 2
                        });
                        pathPolyline.setMap(map);
                        polylines.push(pathPolyline);
                    });
                }

                function togglePath(toggle) {
                    if (toggle) {
                        if ($scope.paths && $scope.paths.length > 0) {
                            drawPaths($scope.paths);
                        } else {
                            drawPaths([$scope.path]);
                        }
                    } else {
                        clearPaths();
                    }
                }
            }]
        };
    }]);
});