define(["./routingConfigModule", "text!./routingSelectionDialog.html"], function (routingConfigModule, template) {
    "use strict";

    /**
     * @ngdoc service
     * @name routingConfigModule.routingSelectionDialog
     * @description
     * Serviço que permite a seleção de um contexto de otimização e
     * um decorador de conversão.
     *
     * @param {RoutingConfigParam} routingConfigParam Parâmetro utilizado para filtrar os contextos de otimização.
     * O parâmetro é um objeto que possui os seguintes campos:
     * * `source`: {routingConfigModule.OptimizationSource} Nome do OptimizationSource.
     * * `flow`: {routingConfigModule.FlowType} Nome do FlowType.
     * * `trips`: {number[]} Lista com os ids das viagens envolvidas na operação.
     * * `loads`: {number[]} Lista com os ids das cargas envolvidas na operação.
     * * `dus`: {number[]} Lista com os ids das DUs envolvidas na operação.
     * * `single`: {boolean} Indica se apenas contextos de otimização devem ser retornados (sem contextos de divisão).
     *
     * @return {Promise} promessa que é resolvida com um objeto contendo `routingConfig` e `converterDecorator`.
     * */
    return routingConfigModule.provider("routingSelectionDialog", [function () {
        var serviceUrl;
        this.setRoutingConfigSelectorServiceUrl = function (routingConfigSelectorServiceUrl) {
            serviceUrl = routingConfigSelectorServiceUrl;
        };

        var showConverterDecorator = false;
        this.showConverterDecorator = function () {
            showConverterDecorator = true;
        };

        this.$get = ["$modal", "$http", "$q", "remoteExceptionHandler", "messagesModal",
            function ($modal, $http, $q, remoteExceptionHandler, messagesModal) {
                return function (routingConfigParam) {
                    return $http.post(serviceUrl, routingConfigParam).then(function (response) {
                        return response.data;
                    }, function (error) {
                        remoteExceptionHandler()(error);
                        return $q.reject();
                    }).then(function (contexts) {
                        if (contexts.length === 1) {
                            return {
                                routingConfig: contexts[0],
                                converterDecorator: null
                            };
                        } else if (contexts.length === 0) {
                            return messagesModal("dialog.warning", [{
                                keyBundle: "entity.routingConfigSelector.noRoutingConfigs"
                            }]).then(function () {
                                return $q.reject();
                            });
                        } else {
                            return $modal.open({
                                template: template,
                                controller: ["$scope", function ($scope) {
                                    $scope.config = {};
                                    $scope.showConverterDecorator = showConverterDecorator;
                                    $scope.routingConfigs = function () {
                                        return contexts;
                                    };
                                }]
                            }).result;
                        }
                    });
                };
            }];
    }]);
});
