define([
    "./monitorModule",
    "text!./MonitorOverviewView.html",
    "text!./MonitorApplicationView.html"
], function (module, monitorOverviewView, monitorApplicationView) {
    "use strict";

    return module.config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("monitor.overview", {
                url: "/overview",
                controller: "MonitorOverviewController",
                template: monitorOverviewView
            })
            .state("monitor.application", {
                url: "/application?instance",
                controller: "MonitorApplicationController",
                template: monitorApplicationView,
                resolve: {
                    applicationName: ["instance", function (instance) {
                        return instance
                            .replace(/^\/*/, "")
                            .replace(/\/*$/, "")
                            .toUpperCase();
                    }],
                    instance: ["$stateParams", function ($stateParams) {
                        return $stateParams.instance;
                    }]
                }
            });
    }]);
});