define(["angular", "./nlgPermissionModule", "../functions/functions", "lodash"], function (angular, nlgPermissionModule, functions, _) {
    "use strict";

    /**
     * @ngdoc service
     * @name directives.permissionService
     * @description
     * Serviço para adquirir informações relativos à permissões e autorizações.
     * */
    return nlgPermissionModule.provider("permissionService", [function () {

        var authorizationUrl = null;

        this.setAuthorizationUrl = function (url) {
            authorizationUrl = validateUrl(url);
        };

        function validateUrl(url) {
            if (!_.isString(url) || !url.trim()) {
                throw new Error("permissionService: Authorization url must be a string. Received: " + url);
            }
            return url;
        }

        this.$get = ["$http", function ($http) {

            return {
                /**
                 * @ngdoc method
                 * @name directives.permissionService#canRead
                 * @methodOf directives.permissionService
                 * @description
                 * Determina se o usuário tem permissão de leitura para um grupo.
                 * @param {string} groupName Nome do grupo.
                 * @returns {Promise<boolean>} Promessa que determina se tem permissão de leitura ou não.
                 * */
                canRead: function (groupName) {
                    return this.getAuthorization().then(function (authorization) {
                        var groupPermissions = authorization(groupName);
                        return groupPermissions.canRead;
                    });
                },
                /**
                 * @ngdoc method
                 * @name directives.permissionService#getAuthorization
                 * @methodOf directives.permissionService
                 * @description
                 * Método para buscar todas as autorizações para um usuário.
                 * @returns {Promise<Function(groupName)>} Função que recebe o nome de um grupo e retorna um objeto com
                 * `canRead` e `canWrite`.
                 * */
                getAuthorization: functions.memoize(function () {
                    return $http.get(authorizationUrl).then(function (response) {
                        var allAuthorizations = response.data;
                        return function (group) {
                            if (angular.isUndefined(group)) {
                                throw new Error("group name is undefined");
                            }
                            var authorization = allAuthorizations[group] || {
                                    mayRead: false,
                                    mayWrite: false
                                };
                            return {
                                canRead: authorization.mayRead,
                                canWrite: authorization.mayWrite
                            };
                        };
                    });
                }, 10000)
            };
        }];
    }]);
});
