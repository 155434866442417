define([
    "./timeModule",
    "./durationFilter",
    "./preciseDurationFilter",
    "./nlgTimepicker",
    "./datePicker",
    "./dateTimePicker",
    "./dateInterval",
    "./dateTimeInterval",
    "./datePickerButton",
    "./nlgDurationInput",
    "./smallDateTimePicker",
    "./timeService",
    "./dateService",
    "./dateTimeZoneFormat",
    "./nlgDurationMinutes"
], function () {
});