define([
    "./chartingModule",
    "text!./pieChart.html",
    "c3",
    "../arrays/arrays",
    "angular",
], function (chartingModule, template, c3, arrays, angular) {
    "use strict";

    /**
     * @ngdoc directive
     * @name chartingModule.directive:pieChart
     * @description
     * Diretiva que exibe um gráfico de pizza
     *
     * @param {object[]=} pieChart dados a serem representados no gráfico
     *
     * @example
     * <example module="FrontEndWeb">
     *     <file name="index.html">
     *         <div ng-controller="example">
     *            <div pie-chart="pieChartInput"></div>
     *         </div>
     *     </file>
     *     <file name="index.js">
     *         angular.module("FrontEndWeb").controller("example", function () {
     *              $scope.pieChartInput = (function () {
     *                  return {
     *                      configs: {
     *                          chartTitle: "The Chart Title",
     *                          type: "pie",
     *                          colors: {
     *                              "data1": "#f310a3",
     *                              "data2": "#b7ccd0"
     *                          }
     *                      },
     *                      data: [
     *                          {
     *                              label: "data1",
     *                              value: 123
     *                          },
     *                          {
     *                              label: "data2",
     *                              value: 321
     *                          }
     *                      ]
     *                  }
     *              }());
     *         });
     *     </file>
     * </example>
     * */
    chartingModule.directive("pieChart", ["$translate", "chartingService", "chartLocale", function ($translate, chartingService, chartLocale) {
        return {
            restrict: "A",
            scope: {
                context: "=pieChart",
                colorFunction: "=?"
            },
            template: template,
            link: function ($scope, $element) {
                var properties = $scope.context.configs.properties || $scope.context.configs.chartViewConfig.properties;
                var addLabel = properties.translateLabels === "true";

                var chartLabels = [];
                var chart = c3.generate(processChartConfiguration($scope.context, properties.valueType));

                $scope.$on("$filterRefresh", function () {
                    chart.show();
                });

                $scope.$watch(function () {
                    return $scope.context.data;
                }, function (newContextData) {
                    var dataToProcess = angular.copy(newContextData);
                    chart.load(dataToUpdate(processData(dataToProcess)));
                }, true);

                function processData(newContextData) {

                    var totalValue = 0;
                    if (properties.rank) {
                        newContextData.sort(function (a, b) {
                            return b.value - a.value;
                        });
                    }
                    var ranked = chartingService.ranking(newContextData, properties.rank);
                    ranked.forEach(function (item) {
                        item.label = forceTranslation(addLabel, item.label);
                        totalValue += item.value;
                    });
                    $scope.totalValue = chartLocale.formatNumberData(properties.valueType, totalValue);
                    return ranked;
                }

                function dataToUpdate(newContextData) {
                    var updateData = {
                        columns: [],
                        unload: []
                    };

                    var newLabels = [];
                    newContextData.forEach(function (item) {
                        newLabels.push(item.label);
                        updateData.columns.push([item.label, item.value]);
                    });

                    chartLabels.forEach(function (label) {
                        if (!arrays.contains(newLabels, label)) {
                            updateData.unload.push(label);
                        }
                    });
                    chartLabels = newLabels;

                    return updateData;
                }

                function processChartConfiguration(context, valueType) {
                    var pieChart = $element.find(".pie-chart");
                    if (!pieChart || pieChart.length !== 1) {
                        throw ".pie-chart element wasn't found.";
                    }
                    var chartData = {
                        bindto: pieChart[0],
                        data: getDataConfiguration("charting.notApplicable"),
                        pie: getPieChartConfiguration(valueType, properties)
                    };
                    chartData.data.colors = getChartColorConfiguration(context.configs.colors);
                    context.data.forEach(function (item) {
                        chartLabels.push(item.label);
                        chartData.data.columns.push([item.label, item.value]);
                    });
                    chartData.tooltip = {
                        format: {
                            title: function (d) {
                                return d;
                            },
                            value: function (value, ratio) {
                                var percentage = ratio * 100;
                                return chartLocale.formatNumberData(valueType, value)
                                    + " (" + chartLocale.formatNumberData("percentage", percentage) + ")";
                            }
                        }
                    };
                    return chartData;
                }

                function forceTranslation(addLabel, label) {
                    if (addLabel) {
                        return $translate.instant("charting.nameLabel.pie." + label);
                    }
                    return $translate.instant(label);
                }

                function getDataConfiguration(emptyMessage) {
                    if (!emptyMessage) {
                        emptyMessage = "Não há dados.";
                    }
                    return {
                        columns: [],
                        type: "pie",
                        empty: {
                            label: {
                                text: $translate.instant(emptyMessage),
                            }
                        }
                    };
                }

                function getPieChartConfiguration(typeFormat, properties) {
                    return {
                        label: {
                            threshold: properties.threshold || 0,
                            format: chartLocale.createFormatter(typeFormat)
                        }
                    };
                }

                function getChartColorConfiguration(colorMap) {
                    if ((colorMap === undefined || !colorMap.length)) {
                        if ($scope.context.configs.properties && $scope.context.configs.properties.color === "true") {
                            var colors = {};
                            angular.forEach($scope.context.configs.properties, function (value, prop) {
                                if (prop.substring(0, 6) === "color-") {
                                    var color = $translate.instant(prop.substring(6));
                                    colors[color] = value;
                                }
                            });
                            return colors;
                        }
                    }
                    var translatedColors = {};
                    for (var colorEntry in colorMap) {
                        var translatedLabel = forceTranslation(addLabel, colorEntry);
                        translatedColors[translatedLabel] = colorMap[colorEntry];
                    }
                    return translatedColors;
                }
            }
        };
    }]);
});
