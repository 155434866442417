define([
    "./monitorModule",
    "text!./nlgMonitorRequestEnv.html",
    "lodash"
], function (module, template, _) {
    "use strict";

    return module.service("nlgMonitorRequestEnvV1Service", [
        "loadingService",
        "remoteExceptionHandler",
        "monitorService",
        function (
            loadingService,
            remoteExceptionHandler,
            monitorService) {

            function NlgMonitorRequestEnvV1Service(instance) {
                this.instance = instance;
            }

            NlgMonitorRequestEnvV1Service.prototype.createEnv = function (data) {
                return {
                    profiles: data.profiles,
                    sources: convertEnvironment(data)
                };
            };

            function convertEnvironment(environment) {
                return _.chain(environment)
                    .pickBy(function (value, key) {
                        return key !== "profiles";
                    })
                    .map(function (value, key) {
                        return {
                            name: key,
                            properties: convertProperties(value)
                        };
                    })
                    .value();
            }

            function convertProperties(properties) {
                return _.map(properties, function (value, key) {
                    return {
                        name: key,
                        value: value
                    };
                });
            }

            NlgMonitorRequestEnvV1Service.prototype.computeValue = function (property) {
                var self = this;
                return loadingService(monitorService.findEndpoint(self.instance, "env/" + property.name)
                    .catch(remoteExceptionHandler()))
                    .then(function (data) {
                        property.computedValue = data[property.name];
                    });
            };

            NlgMonitorRequestEnvV1Service.prototype.updateValue = function (property, newValue) {
                var self = this;
                return loadingService(monitorService.updateEnv(self.instance, property.name, newValue)
                    .catch(remoteExceptionHandler()))
                    .then(function () {
                        return self.computeValue(property);
                    });
            };

            NlgMonitorRequestEnvV1Service.prototype.createNewProperty = function (name, value) {
                var self = this;
                return loadingService(monitorService.updateEnv(self.instance, name, value)
                    .catch(remoteExceptionHandler()));
            };

            return NlgMonitorRequestEnvV1Service;
        }]);
});