define(["./menuModule", "text!./menuView.html"], function (menuModule, menuView) {
    menuModule.directive("nlgMenu", [function () {
        return {
            restrict: "E",
            template: menuView,
            controller: "menuController",
            scope: {
                menuClosed: "=menuClosed",
                menuCollapsed: "="
            }
        };
    }]);
});