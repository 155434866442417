define(["angular", "./numberModule"], function (angular, numberModule) {
    "use strict";

    /**
     * @ngdoc filter
     * @name filters.filter:dimension
     *
     * @function
     * @param {dimension} dimension Uma dimensão à ser representada como string.
     * Ela deve ter as propriedades `width`, `height` e `length`.
     * @return {string} representação da dimensão.
     *
     * @description
     * Exibe uma dimensão em metros de maneira amigável ao usuário.
     * Uma dimensão contém os atributos `width`, `height` e `length`, e serão exibidos como CLA.
     * Além disso, também será exibido a unidade em metros.
     *
     * @example
     *  <example module="FrontEndWeb">
     *     <file name="index.html">
     *         <span>{{ {width: 10, height: 15, length: 20 } | dimension }}</span><br>
     *     </file>
     * </example>
     */
    return numberModule.filter("dimension", ["$filter", function ($filter) {
        var numberFilter = $filter("number");
        return function (dimension) {
            if (dimension === null || angular.isUndefined(dimension)) {
                return "";
            }
            return numberFilter(dimension.length, 3) + " x " +
                numberFilter(dimension.width, 3) + " x " +
                numberFilter(dimension.height, 3) + " (m)";
        };
    }]);
});