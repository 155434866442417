define(["./simulationModule"], function (module) {
    "use strict";

    /**
     * @ngdoc directive
     * @name simulationModule.directive:nlgSimulationDrawer
     * @description
     * Aaaa

     * @example
     * <example module="FrontEndWeb">
     *     <file name="index.html">
     *         <div ng-controller="example">
     *              X: <input class="form-control" type="number" ng-model="modelX"><br>
     *              Y: <input class="form-control" type="number" ng-model="modelY"><br>
     *              width: <input class="form-control" type="number" ng-model="modelWidth"><br>
     *              height: <input class="form-control" type="number" ng-model="modelHeight"><br>
     *              <nlg-simulation-drawer style="display: block; width: 300px; height: 300px;" rectangles="[ {x: modelX, y: modelY, width: modelWidth, height: modelHeight} ]"></nlg-simulation-drawer>
     *         </div>
     *     </file>
     *     <file name="index.js">
     *         angular.module("FrontEndWeb").controller("example", function ($scope) {
     *              $scope.modelX = 250;
     *              $scope.modelY = 250;
     *              $scope.modelWidth = 100;
     *              $scope.modelHeight = 100;
     *         });
     *     </file>
     * </example>
     */
    return module.directive("nlgSimulationDrawer", ["simulationDrawerService", function (simulationDrawerService) {
        return {
            restrict: "E",
            scope: {
                rectangles: "=?rectangles"
            },
            controller: ["$scope", "$element", function ($scope, $element) {
                var two = simulationDrawerService.createTwo().appendTo($element[0]);

                simulationDrawerService.drawOnTwo(two, $scope.rectangles);

                $scope.$watchCollection("rectangles", function(newValue){
                    simulationDrawerService.drawOnTwo(two, newValue);
                });
            }]
        };
    }]);
});