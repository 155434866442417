define([
    "angular",
    "../form/form",
    "../number/number",
    "../time/time",
    "../nodeCommands/nodeCommands",
    "../violation/violation",
    "../filter/filter",
    "../maps/maps",
    "../nlgBasket/nlgBasket",
    "../nlgTemplate/nlgTemplate"
], function (angular) {
    "use strict";

    return angular.module("nlgAnalysisTreeModule", [
        "entityDTOModule",
        "formModule",
        "numberModule",
        "timeModule",
        "nodeCommandsModule",
        "violationModule",
        "filterModule",
        "mapsModule",
        "nlgBasketModule",
        "nlgTemplateModule"
    ]);
});