define(["./timeModule", "text!./nlgTimepicker.html", "lodash"], function nlgTimepicker(timeModule, template, _) {
    "use strict";

    return timeModule.directive("nlgTimepicker", ["timeService", function (timeService) {
        return {
            template: template,
            scope: {
                "ngDisabled": "&?",
                "nlgPlaceholder": "&?",
                "nullable": "@?"
            },
            require: "ngModel",
            link: function ($scope, $element, $attrs, ngModelController) {
                var lastValidDate = 0;
                $element.on("blur", function () {
                    ngModelController.$render();
                    $scope.$digest();
                });

                $scope.update = function () {
                    ngModelController.$setViewValue({
                        hour: $scope.model.hour,
                        minute: $scope.model.minute
                    });
                };

                ngModelController.$formatters.push(function (modelValue) {
                    if (!isValidTime(modelValue)) {
                        return {hour: null, minute: null};
                    }

                    if (modelValue instanceof Date) {
                        lastValidDate = {
                            hours: modelValue.getHours(),
                            minutes: modelValue.getMinutes()
                        };
                    } else {
                        lastValidDate = timeService.toTime(modelValue);
                    }
                    return {
                        hour: lastValidDate.hours,
                        minute: lastValidDate.minutes
                    };
                });

                ngModelController.$parsers.push(function (viewValue) {
                    var hour = viewValue.hour;
                    var minute = viewValue.minute;
                    if (hour === null || minute === null) {
                        return null;
                    }
                    return timeService.toMillis(hour, minute);
                });

                ngModelController.$validators.validateParcialInput = function (modelValue, viewValue) {
                    return (viewValue.hour === null) === (viewValue.minute === null);
                };

                ngModelController.$validators.validateRequired = function (modelValue) {
                    return !!(!$attrs.required || ($attrs.required && _.isNumber(modelValue)));
                };

                ngModelController.$render = function () {
                    $scope.model = ngModelController.$viewValue;
                };

                $element.closest("form,ng-form").on("reset", function () {
                    $scope.model = {hour: null, minute: null};
                    $scope.update();
                });
            },
            controller: ["$scope", "$translate", function ($scope, $translate) {
                $scope.getPlaceholderHours = function () {
                    return getPlaceholderpart("timepicker.hourFormat", function (time) {
                        return time.getHours();
                    });
                };

                $scope.getPlaceholderMinutes = function () {
                    return getPlaceholderpart("timepicker.minuteFormat", function (time) {
                        return time.getMinutes();
                    });
                };

                function getPlaceholderpart(formatBundle, getter) {
                    if (!$scope.nlgPlaceholder) {
                        return $translate.instant(formatBundle);
                    }
                    var time = $scope.nlgPlaceholder();
                    if (isValidTime(time)) {
                        return _.padStart(getter(new Date(time)), 2, "0");
                    }
                    return "";
                }
            }]
        };

        function isValidTime(time) {
            if (!time && time !== 0) {
                return false;
            }
            return !_.isNaN(new Date(time).getTime());
        }
    }]);
});