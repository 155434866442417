define(["./arrangementModule", "text!./nlgArrangementObjectDescription.html"], function (arrangementModule, nlgArrangementObjectDescriptionTemplate) {
    "use strict";

    return arrangementModule.directive("nlgArrangementObjectDescription", [function () {
        return {
            restrict: "E",
            template: nlgArrangementObjectDescriptionTemplate,
            scope: true,
            controller: ["$scope", "HashSet", "Object3DInfo", function ($scope, HashSet, Object3DInfo) {
                $scope.deliveryUnitDatas = new HashSet(function (objectDescription) {
                    return objectDescription.id;
                });

                $scope.$on("nlgArrangement.selectionChanged", function (event, selectedObjects) {
                    $scope.deliveryUnitDatas.clear();
                    selectedObjects.each(function (meshAndObject) {
                        var duData = meshAndObject.object.extraInfo[Object3DInfo.DELIVERY_UNIT_DATA];
                        $scope.deliveryUnitDatas.add({
                            "id": duData.deliveryUnit.id,
                            "orders": duData.orders,
                            "products": duData.products,
                            "deliveryUnitWeight": duData.deliveryUnitWeight,
                            "deliveryUnitVolume": duData.deliveryUnitVolume,
                            "quantityOfShipmentUnits": duData.quantityOfShipmentUnits,
                            "shipmentUnitProductQuantity": duData.shipmentUnitProductQuantity,
                            "shipmentUnitWrapper": duData.shipmentUnitWrapper,
                            "shipmentUnitWrapperCategory": duData.shipmentUnitWrapperCategory,
                            "width": duData.width,
                            "length": duData.length,
                            "height": duData.height,
                            "shipmentUnitWeight": duData.shipmentUnitWeight,
                            "shipmentUnitVolume": duData.shipmentUnitVolume,
                            "pickUpWindowStart": duData.pickUpWindowStart,
                            "deliveryWindowStart": duData.deliveryWindowStart,
                            "deliveryWindowEnd": duData.deliveryWindowEnd,
                            "loadingLocality": duData.loadingLocality,
                            "unloadingLocality": duData.unloadingLocality,
                            "unloadingLocalityAddress": duData.unloadingLocalityAddress,
                            "rotation": "orientation." + meshAndObject.object.rotation.name
                        });
                    });
                });
            }]
        };
    }]);
});