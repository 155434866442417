define([
    "../nlgHealthCheckerModule"
], function (nlgHealthCheckerModule) {
    "use strict";

    return nlgHealthCheckerModule.service("numberCheckerHistoryGraph", [
        "numberFilter",
        function (numberFilter) {
            return {
                canHandle: function (reasonReading) {
                    return reasonReading.type === "NumberReason"
                        || isPercentage(reasonReading);
                },
                create: function (reasonReading) {
                    var _isPercentage = isPercentage(reasonReading);
                    return {
                        configureData: function (defaultConfiguration, datas) {
                            defaultConfiguration.json = datas;
                            return defaultConfiguration;
                        },
                        configureChart: function (defaultConfiguration) {
                            defaultConfiguration.axis.y = {
                                padding: 0,
                                min: _isPercentage ? 0 : undefined,
                                max: _isPercentage ? 100 : undefined,
                                tick: {
                                    format: function (x) {
                                        return numberFilter(x, 2);
                                    }
                                }
                            };
                            return defaultConfiguration;
                        }
                    };
                }
            };

            function isPercentage(reasonReading) {
                return reasonReading.type === "PercentageReason";
            }
        }]);
});
