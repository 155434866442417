define([
    "./positionModule"
], function (positionModule) {
    "use strict";

    /**
     * Limita o elemento à extremidade inferior da visualização atual da página,
     * de tal forma que não seja parcialmente "escondido". Para isso ser possível,
     * é assumido que a propriedade 'bottom' afete o posicionamento do elemento.
     *
     * Como a propriedade CSS de posicionamento é adicionada após o elemento
     * ser compilado e renderizado, é provável que dê para visualizar um efeito
     * de mudança de posição logo após sua criação.
     *
     * ex: <div style="position: absolute" nlg-limit-to-screen-bottom-bound></div>
     */
    return positionModule.directive("nlgLimitToScreenBottomBound", [function () {
        return {
            restrict: "A",
            link: function(scope, element) {
                element.ready(function() {
                    var unwrappedElement = element[0]; // element vem envelopado num objeto jqLite
                    var bottomBound = unwrappedElement.getBoundingClientRect().bottom;
                    var windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
                    if (bottomBound > windowHeight) {
                        element.css({bottom: "0px"});
                    }
                });
            }
        };
    }]);
});
