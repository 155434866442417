define(["./genericParamsModule"], function (crudModule) {
    "use strict";

    return crudModule.service("genericParamDataService", ["$http", "genericParamsUrls", function ($http, genericParamsUrls) {
        var serviceUrl = genericParamsUrls.basePath + "/" + genericParamsUrls.genericParamDataUrl + "/";

        return {
            save: function (definition, genericParamDataManagementDTO) {
                return $http.post(serviceUrl + definition.id, genericParamDataManagementDTO)
                    .then(function (req) {
                        return req.data;
                    });
            },
            transfer: function (definition, genericParamTransferDTO) {
                return $http.post(serviceUrl + definition.id + "/transfer", genericParamTransferDTO)
                    .then(function (req) {
                        return req.data;
                    });
            },
            getTransferMetaInf: function (definition) {
                return $http.get(serviceUrl + definition.id + "/transfer")
                    .then(function (req) {
                        return req.data;
                    });
            },
            getComponents: function (definition) {
                return $http.get(genericParamsUrls.genericParamComponents.replace("{definition-id}", definition.id))
                    .then(function (req) {
                        return req.data;
                    });
            }
        };
    }]);
});