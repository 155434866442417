define([
    "./xlsModule",
    "alasql",
    "angular"
], function (xlsModule, alasql, angular) {
    "use strict";

    return xlsModule.service("xlsService", ["$translate", "$sce", function ($translate, $sce) {
        return function xlsService(sheetName, dataToExport, spreadsheetStyle) {
            sheetName = $translate.instant(sheetName);
            var exportOptions = {
                sheets: {}
            };
            exportOptions.sheets[sheetName] = spreadsheetStyle;

            var sanitizedDataToExport = [];
            dataToExport.forEach(function (data) {
                var obj = {};
                angular.forEach(data, function (value, key) {
                    // Converts reserved characters to HTML entities (e.g.: & => &amp;)
                    // reference: https://developer.mozilla.org/en-US/docs/Glossary/Entity
                    this[key] = $sce.getTrustedHtml(value);
                }, obj);
                sanitizedDataToExport.push(obj);
            });

            alasql("SELECT * INTO XLSXML('" + sheetName + ".xls', ?) FROM  ?", [exportOptions, sanitizedDataToExport]);
        };
    }]);
});