define(["./collectionsModule"], function (collectionsModule) {
    "use strict";

    return collectionsModule.service("CircularList", [function () {
        var EMPTY_OBJ = {};

        /**
         * @ngdoc service
         * @name collectionsModule.CircularList
         * @description
         * Implementação de uma lista circular, ou seja, uma lista com uma capacidade máxima
         * e que exclui as entradas mais antigas caso, ao adicionar um item, a capacidade seja
         * excedida.
         * */
        function CircularList(maxListSize) {
            maxListSize = maxListSize || 50;

            this.items = [];
            this.lastIndex = -1;

            var i;
            for (i = 0; i < maxListSize; i++) {
                this.items.push(EMPTY_OBJ);
            }
        }

        CircularList.prototype.add = function (item) {
            var index = (this.lastIndex + 1) % this.items.length;
            this.lastIndex = index;
            this.items[index] = item;
            return this;
        };

        CircularList.prototype.toArray = function () {
            var item;
            var i;
            var result = [];
            var length = this.items.length;
            var start = this.lastIndex + 1;
            for (i = 0; i < length; i++) {
                var index = (start + i) % length;
                item = this.items[index];
                if (item !== EMPTY_OBJ) {
                    result.push(item);
                }
            }
            return result;
        };

        return CircularList;
    }]);
});