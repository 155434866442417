define([
    "./arrangementModule",
    "lodash"
], function (module, _) {
    "use strict";

    return module.provider("arrangementUrls", [function () {
        var urls = {
            arrangementDescriptionForTrip: null, // server.baseUrl + server.services.arrangement.base
            arrangementDescriptionForLoad: null, // server.baseUrl + server.services.arrangement.base + load
            arrangementDescriptionForDU: null, // server.baseUrl + server.services.arrangement.base + du
            clp: null, // server.baseUrl + server.services.arrangement.clp
            removeOverload: null, // server.baseUrl + server.services.arrangement.removeRemaining
            layerReport: null // server.baseUrl + server.services.arrangement.report
        };

        this.$get = [function () {
            return {
                getArrangementDescriptionForTripUrl: function getArrangementDescriptionForTripUrl() {
                    checkUrls(urls);
                    return urls.arrangementDescriptionForTrip;
                },
                getArrangementDescriptionForLoadUrl: function getArrangementDescriptionForLoadUrl() {
                    checkUrls(urls);
                    return urls.arrangementDescriptionForLoad;
                },
                getArrangementDescriptionForDUUrl: function getArrangementDescriptionForDUUrl() {
                    checkUrls(urls);
                    return urls.arrangementDescriptionForDU;
                },
                getClpUrl: function getClpUrl() {
                    checkUrls(urls);
                    return urls.clp;
                },
                getRemoveOverloadUrl: function getRemoveOverloadUrl() {
                    checkUrls(urls);
                    return urls.removeOverload;
                },
                getLayerReportUrl: function getLayerReportUrl() {
                    checkUrls(urls);
                    return urls.layerReport;
                }
            };
        }];

        this.configure = function (newUrls) {
            checkUrls(newUrls);
            urls = newUrls;
        };

        function checkUrls(newUrls) {
            var errors = Object.keys(urls).map(function (key) {
                var url = newUrls[key];
                if (!_.isString(url) || url.trim().length === 0) {
                    return key;
                }
                return null;
            }).filter(_.negate(_.isNil));
            if (errors.length > 0) {
                throw new Error("arrangementRemoteService: some urls are missing: " + errors.join(","));
            }
        }
    }]);
});