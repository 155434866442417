define(["d3"], function (d3) {
    "use strict";

    var colors = [
        "#1f77b4",
        "#ff7f0e",
        "#2ca02c",
        "#d62728",
        "#9467bd",
        "#8c564b",
        "#e377c2",
        "#7f7f7f",
        "#bcbd22",
        "#17becf"];

    return {
        /**
         * @function
         * Retorna uma cor arbitrária, dado um número.
         * São retornadas no máximo 10 cores diferentes.
         * @param {number} number número para geração da cor.
         * @returns {string} código hexadecimal da cor gerada.
         * */
        getColor: function getColor(number) {
            return colors[number % colors.length];
        },

        /**
         * @function
         * Retorna uma função que retorna uma entre 20 cores para um dado input.
         * Sempre que o mesmo input for fornecido, será retornado a mesma
         * cor.
         * <p>
         * *Atenção:* Ao utilizar a escala com funções é realizada a comparação
         * com utilizando o #toString da função e o resultado do #toString pode
         * ser diferente do esperado quando a build é minimificada e o corpo da
         * função é alterado.
         *
         * @returns {function(input)} função que gera uma cor para um input.
         * */
        scale20: function () {
            return d3.scale.category20();
        },

        getVariation: function(color, seed){
            // Apenas 20 tons distintos devem ser suficientes
            seed = seed % 20;
            /*jshint bitwise: false*/
            // Número pares negativos e ímpares positivos
            var variation = ((seed & 1) * 2 - 1) * seed;
            return d3.rgb(color).brighter(variation/10).toString();
        }
    };
});