define([
    "angular",
    "./chartingModule",
    "c3",
    "../arrays/arrays",
    "lodash",
    "d3",
], function (angular, chartingModule, c3, arrays, _, d3) {
    "use strict";

    chartingModule.directive("discreteAxisChart", ["$translate", "chartLocale", function ($translate, chartLocale) {
        return {
            restrict: "E",
            scope: {
                type: "@",
                context: "=",
                width: "@?",
                height: "@?",
                maxValueBySeries: "=?"
            },
            template: "<div><h3 class='chart-title' translate></h3><div class='discrete-axis-chart'></div></div>",
            link: function (scope, element, attrs) {
                if (scope.context.configs.chartType === "RANGE") {
                    scope.type = "range";
                }
                if (!arrays.contains(["timeseries", "category", "range"], scope.type)) {
                    throw new Error("Invalid discrete axis chart type: " + scope.type);
                }

                scope.maxValueBySeries = scope.maxValueBySeries || {};
                scope.width = parseInt(scope.width) || undefined;
                scope.height = parseInt(scope.height) || undefined;

                var properties = scope.context.configs.properties || scope.context.configs.chartViewConfig.properties;

                var translate = properties.translateLabels === "true";
                var translateLabelFunction = translate === true ?
                    function (label) {
                        return $translate.instant("charting.nameLabel.discreteAxis." + label);
                    }
                    : function (label) {
                        return label;
                    };

                if (!angular.isDefined(attrs.hideTitle)) {
                    element.find("h3.chart-title").text(scope.context.configs.chartTitle);
                }

                var pivotSeries = arrays.filter(scope.context.configs.properties.series, function (item) {
                    return item.role === scope.type;
                })[0];

                var seriesBehavior = {
                    timeseries: {
                        getAxisFormat: function () {
                            return "%m/%Y";
                        },
                        formatPivotSeriesValue: function (seriesTimestamp) {
                            var date;
                            // Fix para não exibir NaN no IE
                            if (isNaN(seriesTimestamp)) {
                                var datePattern = /^\s*(\d{4})\/(\d+)/;
                                var month;
                                var parts = datePattern.exec(seriesTimestamp);
                                date = new Date(NaN);

                                if (parts) {
                                    month = +parts[2];
                                    date.setFullYear(parts[1], month - 1);
                                    if (month !== date.getMonth() + 1) {
                                        date.setTime(NaN);
                                    }
                                }
                                return new Date(date.getFullYear(), date.getMonth());
                            } else {
                                date = new Date(seriesTimestamp);
                                return new Date(date.getFullYear(), date.getMonth());
                            }
                        }
                    },
                    category: {
                        getAxisFormat: function () {
                            return undefined;
                        },
                        formatPivotSeriesValue: function (seriesDatum, allSeriesData, index) {
                            var unitOfMeasure = scope.context.configs.properties.unitOfMeasure;
                            var upperBound = allSeriesData.upperBound[index];
                            if (!upperBound) {
                                return $translate.instant("charting.from") + " " + allSeriesData.lowerBound[index] + unitOfMeasure;
                            }
                            return _.join([
                                $translate.instant("charting.of"),
                                allSeriesData.lowerBound[index] + unitOfMeasure,
                                $translate.instant("charting.until"),
                                upperBound + unitOfMeasure
                            ], " ");
                        }
                    },
                    range: {
                        getAxisFormat: function () {
                            return undefined;
                        },
                        formatPivotSeriesValue: function (seriesDatum, allSeriesData, index) {
                            var unitOfMeasure = scope.context.configs.properties.unitOfMeasure;

                            var upperBound = angular.isDefined(allSeriesData.upperBound) ? allSeriesData.upperBound[index] : null;
                            if (!upperBound) {
                                return $translate.instant("charting.from") + " " + allSeriesData.lowerBound[index] + unitOfMeasure;
                            }
                            return _.join([
                                $translate.instant("charting.of"),
                                allSeriesData.lowerBound[index] + unitOfMeasure,
                                $translate.instant("charting.until"),
                                upperBound + unitOfMeasure
                            ], " ");
                        }
                    }
                };

                function getChartAxisType() {
                    if (scope.type === "timeseries") {
                        return "timeseries";
                    }
                    if (scope.type === "category" || scope.type === "range") {
                        return "category";
                    }
                }

                function translatedSeriesName() {
                    scope.context.configs.properties.series.forEach(function (data) {
                        if (data.role === "column") {
                            data.translatedName = translateLabelFunction(data.name);
                        }
                    });
                }

                translatedSeriesName();
                var chartConfig = processChartConfig(scope.context.data);
                var chart = c3.generate({
                    bindto: element.find("div.discrete-axis-chart")[0],
                    size: {
                        height: scope.height,
                        width: scope.width
                    },
                    data: {
                        x: pivotSeries.name,
                        columns: chartConfig.columns,
                        axes: chartConfig.axes,
                        types: chartConfig.types,
                        empty: {
                            label: {
                                text: $translate.instant("charting.notApplicable")
                            }
                        }
                    },
                    legend: {
                        show: !angular.isDefined(attrs.hideLegend),
                        position: "right"
                    },
                    grid: {
                        x: {
                            show: true
                        },
                        y: {
                            show: true
                        }
                    },
                    axis: {
                        x: {
                            type: getChartAxisType(),
                            tick: {
                                rotate: 35,
                                multiline: false,
                                format: seriesBehavior[scope.type].getAxisFormat()
                            }
                        },
                        y2: {
                            show: scope.context.configs.properties.groupedChartY2AxisLabel ? true : false,
                            max: chartConfig.maxValueByAxis.y2,
                            min: 0,
                            padding: {bottom: 0},
                            label: {
                                text: $translate.instant(scope.context.configs.properties.groupedChartY2AxisLabel || ""),
                                position: "outer-middle"
                            },
                            tick: {
                                format: onlyIntegerOnYFormat
                            }
                        },
                        y: {
                            max: chartConfig.maxValueByAxis.y,
                            min: 0,
                            padding: {bottom: 0},
                            label: {
                                text: $translate.instant(scope.context.configs.properties.groupedChartYAxisLabel || ""),
                                position: "outer-middle"
                            },
                            tick: {
                                format: onlyIntegerOnYFormat
                            }
                        }
                    },
                    tooltip: {
                        format: {
                            value: function (value) {
                                if (!(Number(value) === value && value % 1 === 0)) {
                                    return chartLocale.formatDecimal(value);
                                }
                                return (value);
                            }
                        }
                    }
                });

                function onlyIntegerOnYFormat(value) {
                    if (angular.isDefined(properties.showOnlyIntegerOnY) && properties.showOnlyIntegerOnY === "true") {
                        if (value !== Math.floor(value)) {
                            d3.selectAll(".c3-axis-y g.tick").filter(function () {
                                var text = d3.select(this).select("text").text();
                                return +text === value;
                            }).style("opacity", 0);
                            return "";
                        }
                    }
                    return value;
                }

                scope.$watch("context.data", function (newContextData) {
                    var chartConfig = processChartConfig(newContextData);
                    chart.load(chartConfig);
                }, true);

                function processChartConfig(contextData) {
                    var columnSeries = arrays.filter(scope.context.configs.properties.series, function (item) {
                        return item.role === "column";
                    });

                    var allSeriesData = {};
                    allSeriesData[pivotSeries.name] = [];
                    columnSeries.forEach(function (eachSeries) {
                        allSeriesData[eachSeries.name] = [];
                    });

                    contextData.forEach(function (series) {
                        for (var property in series) {
                            if (series.hasOwnProperty(property)) {
                                var currentSeries = allSeriesData[property];
                                if (!allSeriesData[property]) {
                                    currentSeries = [];
                                    allSeriesData[property] = currentSeries;
                                }
                                allSeriesData[property].push(series[property]);
                            }
                        }
                    });
                    var config = {
                        axes: {},
                        types: {},
                        columns: [],
                        maxValueByAxis: {},
                        unload: true,
                        done: function () {
                            chart.flush();
                        }
                    };

                    columnSeries.forEach(function (series) {
                        var seriesName = translate === true ? series.translatedName : series.name;
                        var seriesYAxis = angular.equals(series.yAxis, "left") ? "y" : "y2";
                        config.axes[seriesName] = seriesYAxis;
                        config.types[seriesName] = series.type;
                        config.columns.push([seriesName].concat(allSeriesData[series.name]));
                        // se dois charts (ex. bar e line) usarem o mesmo eixo, é usado o maior dos valores.
                        if (angular.isUndefined(config.maxValueByAxis[seriesYAxis])) {
                            config.maxValueByAxis[seriesYAxis] = scope.maxValueBySeries[series.name];
                        } else {
                            if (config.maxValueByAxis[seriesYAxis] < scope.maxValueBySeries[series.name]) {
                                config.maxValueByAxis[seriesYAxis] = scope.maxValueBySeries[series.name];
                            }
                        }
                    });

                    if (!allSeriesData[pivotSeries.name].length) {
                        return config;
                    }

                    var formattedCategories = {};
                    allSeriesData[pivotSeries.name].forEach(function (seriesDatum, index) {
                        if (!formattedCategories[pivotSeries.name]) {
                            formattedCategories[pivotSeries.name] = [];
                        }
                        formattedCategories[pivotSeries.name][index] = seriesBehavior[scope.type].formatPivotSeriesValue(seriesDatum, allSeriesData, index);
                    });

                    config.columns.push([pivotSeries.name].concat(formattedCategories[pivotSeries.name]));
                    return config;
                }
            }
        };
    }]);
});