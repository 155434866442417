define([
    "./filterModule"
], function (filterModule) {
    "use strict";

    filterModule.provider("filterDomain", [function(){
        var basePathByDomain = {};
        var defaultDomain;

        this.$get = [function (){
            return {
                getBasePath: function(domain){
                    return basePathByDomain[domain];
                },
                getDefaultDomain: function(){
                    return defaultDomain;
                }
            };
        }];

        this.registerDomain = function(domain, path){
            basePathByDomain[domain] = path;
        };

        this.setDefaultDomain = function(domain){
            defaultDomain = domain;
        };
    }]);
});