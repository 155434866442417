define([
    "angular",
    "./chartingModule",
    "text!./chartGroup.html",
    "c3",
    "../arrays/arrays",
    "lodash",
    "d3"
], function (angular, chartingModule, template, c3, arrays, _, d3) {
    "use strict";

    chartingModule.directive("chartGroup", ["$translate", function ($translate) {
        return {
            restrict: "E",
            scope: {
                context: "="
            },
            template: template,
            link: function (scope, element, attrs, chartGroupController) {
                if (!scope.context.groupings.rows) {
                    throw new Error("Rows must be informed for grouping.");
                }
                /**
                 * árvore bottom-up de apresentação
                 */
                scope.leaves = [];
                scope.maxValueBySeries = {};
                scope.groupedChartCellAlignment = {
                    "text-align": scope.context.chart.configs.properties.groupedChartCellAlignment || "center"
                };

                var columnSeries = arrays.filter(scope.context.chart.configs.properties.series, function (item) {
                    return item.role === "column";
                });

                scope.$on("$dashboardDataUpdate", function (event, newContextData) {
                    var newData = newContextData[scope.context.chart.configs.chartSourceId];
                    setColors(newData);
                    updateData(newData);
                    columnSeries.forEach(function (eachSeries) {
                        var maxValue = _.max(_.map(newData, function (datum) {
                            return _.parseInt(datum[eachSeries.name]);
                        }));
                        scope.maxValueBySeries[eachSeries.name] = maxValue;
                    });
                });

                scope.mustShow = function mustShow(grouper, node) {
                    if (node.grouper === grouper) {
                        return true;
                    }
                    var childNode = node;
                    var parentNode = node.parent;
                    while (parentNode.grouper !== grouper) {
                        childNode = childNode.parent;
                        parentNode = parentNode.parent;
                        // 3 ou mais agrupamentos
                        if (!mustShow(parentNode.grouper, parentNode)) {
                            return false;
                        }
                    }
                    return parentNode.values[0] === childNode;
                };

                scope.getRowSpan = function (grouper, node) {
                    return chartGroupController.getRowSpan(grouper, node);
                };

                scope.formatCell = function (cellKey, leaf) {
                    var properties = scope.context.chart.configs.properties;
                    if (!angular.isDefined(properties.format)) {
                        return cellKey;
                    }
                    if (properties.type === "range") {
                        if (!angular.isNumber(cellKey)) {
                            return cellKey;
                        }
                        var unitOfMeasure = scope.context.chart.configs.chartViewConfig.properties.unitOfMeasure;
                        var lowerBound = leaf.values[0].lowerBound;
                        var upperBound = leaf.values[0].upperBound;
                        if(!upperBound){
                            return $translate.instant("charting.from") + " " + lowerBound + unitOfMeasure;
                        }
                        return _.join([
                            $translate.instant("charting.of"),
                            lowerBound + unitOfMeasure,
                            $translate.instant("charting.until"),
                            upperBound + unitOfMeasure
                        ], " ");
                    }
                    return cellKey;
                };

                function setColors(datas) {
                    if (scope.context.chart.configs.properties.groupedColorForAlias && !scope.context.chart.configs.properties.color) {
                        scope.context.chart.configs.properties.color = "true";
                        var propertyToSetColors = scope.context.chart.configs.properties.groupedColorForAlias;
                        var colorFunction = d3.scale.category20();
                        datas.forEach(function (data) {
                            var key = "color-" + data[propertyToSetColors];
                            if (!scope.context.chart.configs.properties[key]) {
                                scope.context.chart.configs.properties[key] = colorFunction(data[propertyToSetColors]);
                            }
                        });
                    }
                }

                updateData(scope.context.chart.data);

                function updateData(newData) {
                    chartGroupController.updateChartTree(newData);
                }
            },
            controller: ["$scope", function chartGroupController($scope) {
                this.getParent = getParent;
                function getParent(grouper, node) {
                    if (node.parent && node.grouper !== grouper) {
                        return getParent(grouper, node.parent);
                    }
                    return node;
                }

                this.getRowSpan = function up(grouper, node) {
                    return (function down(node) {
                        if (node.isLeaf) {
                            return 1;
                        }
                        return node.values.reduce(function (old, current) {
                            return down(current) + old;
                        }, 0);
                    }(getParent(grouper, node)));
                };

                this.updateChartTree = function (data) {
                    var nester = d3.nest();
                    $scope.context.groupings.rows.forEach(function (grouper) {
                        nester.key(function (datum) {
                            return datum[grouper];
                        });
                    });
                    var nestedData = nester.entries(data);
                    var newLeaves = convertChartTree(nestedData);
                    arrays.clear($scope.leaves);
                    arrays.addAll($scope.leaves, newLeaves);
                };

                function convertChartTree(nestedData) {
                    var leaves = [];
                    var grouping = $scope.context.groupings.rows;
                    (function dfs(valuesHolder, parent, grouperIndex) {
                        valuesHolder.forEach(function (datum) {
                            if (angular.isUndefined(datum.values)) {
                                if (!arrays.contains(leaves, parent)) {
                                    parent.isLeaf = true;
                                    leaves.push(parent);
                                }
                                return;
                            }
                            datum.grouper = grouping[grouperIndex];
                            datum.parent = parent;
                            dfs(datum.values, datum, grouperIndex + 1);
                        });
                    }(nestedData, null, 0));
                    return leaves;
                }
            }]
        };
    }]);
});