define([
    "./genericParamsModule",
    "text!./genericParameterDefinitionGroupEdition.html",
], function (module, template) {
    "use strict";

    return module.directive("nlgGenericParamDefinitionGroupEdition", function () {
        return {
            restrict: "E",
            template: template,
            controller: "GenericParamDefinitionGroupEditionController"
        };
    });
});

