define([
    "angular",
    "./commonsOperationModule",
    "../arrays/arrays"
], function (angular, commonsOperationModule, arrays) {
    "use strict";

    return commonsOperationModule.service("organizeService", [
        "$http",
        "loadingService",
        "remoteExceptionHandler",
        "violationModal",
        "preValidationService",
        "$q",
        "OptimizationSource",
        "nodeIdentifiers",
        "pathService",
        "FlowType",
        function ($http,
                  loadingService,
                  remoteExceptionHandler,
                  violationModal,
                  preValidationService,
                  $q,
                  OptimizationSource,
                  nodeIdentifiers,
                  pathService,
                  FlowType) {
            var self = this;

            var organizeModalService;
            self.setModalService = function(modalService){
                if(!angular.isFunction(modalService)){
                    throw new Error("Organize modal service must be a function");
                }
                organizeModalService = modalService;
            };
            //
            self.organize = function (froms, to) {
                var toRoutingBasketDeliveryUnits = [];
                if (isRoutingBasketDeliveryUnit(to)) {
                    toRoutingBasketDeliveryUnits = [to];
                }
                var toBasketTrips = [];
                if (isTrip(to)) {
                    toBasketTrips = [getTrip(to)];
                }
                var trips = (froms.tripBasket.Trip || []).concat(toBasketTrips);
                var deliveryUnits = (froms.deliveryUnits.DeliveryUnit || [])
                    .concat(froms.deliveryUnits.CompositeDeliveryUnit || [])
                    .concat(froms.deliveryUnits.DeliveryUnitFromOtherOrder || [])
                    .concat(froms.tripBasket.DeliveryUnit || [])
                    .concat(froms.tripBasket.CompositeDeliveryUnit || [])
                    .concat(toRoutingBasketDeliveryUnits || []);
                var tripsNodeIdentifiers = nodeIdentifiers.toNodeIdentifiers(trips),
                    deliveryUnitNodeIdentifiers = nodeIdentifiers.toNodeIdentifiers(getDeliveryUnitsNodeIdentifiers(deliveryUnits));
                return preValidationService.getViolationsForManualOperation(OptimizationSource.MANUAL_OPERATION, tripsNodeIdentifiers, deliveryUnitNodeIdentifiers, false, FlowType.ANALYSIS)
                    .then(function (result) {
                        var allDeliveryUnits = (getDeliveryUnitsNodeIdentifiers(froms.deliveryUnits.DeliveryUnit) || []).concat(getDeliveryUnitsNodeIdentifiers(froms.deliveryUnits.CompositeDeliveryUnit) || [])
                            .concat(result.additionalDeliveryUnits || []);
                        var validDeliveryUnits = getValidDeliveryUnits(result, allDeliveryUnits);
                        return post(pathService.getPath("organize"), {
                            fromBasket: nodeIdentifiers.toHierarchyIdentifier(getValues(validDeliveryUnits)),
                            fromTrips: nodeIdentifiers.toHierarchyIdentifier(getValues(froms.tripBasket)),
                            to: nodeIdentifiers.toHierarchyIdentifier(to)
                        }).then(function (response) {
                            if (response.data.canChangeQuantities) {
                                return organizeModalService(response.data, result.routingConfig);
                            }
                            return {
                                operations: response.data,
                                routingConfig: result.routingConfig.routingConfig
                            };
                        });
                    }).then(function (input) {
                        return post(pathService.getPath("organize-execute"), input);
                    }).then(function (response) {
                        return violationModal(response.data);
                    }).then(function (manualOperationResult) {
                        return post(pathService.getPath("manualOperation-save"), manualOperationResult.token);
                    });
            };

            return self;

            function getDeliveryUnitsNodeIdentifiers(deliveryUnits) {
                if(arrays.isArray(deliveryUnits)) {
                    var duNodes = [];
                    deliveryUnits.forEach(function (element) {
                        if(element.id === undefined) {
                            element.forEach(function (node) {
                                duNodes.push(node);
                            });
                        } else {
                            duNodes.push(element);
                        }
                    });
                    return duNodes;
                }
                return deliveryUnits;
            }

            function post(url, data) {
                return loadingService($http.post(url, data)).catch(function (e) {
                    remoteExceptionHandler()(e);
                    return $q.reject();
                });
            }

            function isRoutingBasketDeliveryUnit(to) {
                return to.type.name === "DeliveryUnit" && isInBasket(to, "ROUTING_BASKET");
            }

            function isTrip(to) {
                return arrays.contains(["Load", "Trip", "Stop"], to.type.name) && isInBasket(to, "TRIP_BASKET");
            }

            function getValidDeliveryUnits(result, deliveryUnits){
                return arrays.filter(deliveryUnits, function(duNode){
                    return arrays.contains(result.entitiesToOperate.deliveryUnits, duNode.id);
                });
            }

            function isInBasket(du, basket) {
                var parent = du.$parent;
                while (parent) {
                    if (parent.type.name === basket) {
                        return true;
                    }
                    parent = parent.$parent;
                }
                return false;
            }

            function getTrip(to) {
                var node = to;
                while (node.type.name !== "Trip") {
                    node = node.$parent;
                }
                return node;
            }
        }]);

    function getValues(object) {
        return arrays.filter(arrays.map(object, function (value) {
            return value;
        }), function (node) {
            return [
                "Order",
                "OrderItem",
                "Schedule",
                "DeliveryUnitFromOtherOrder"
            ].indexOf(node.type.name) === -1;
        });
    }
});