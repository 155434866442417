define([
    "./nlgMultiSelectModule",
    "text!./nlgMultiSelect.html"
], function (nlgMultiSelectModule, template) {
    "use strict";

    return nlgMultiSelectModule.directive("nlgMultiSelect", function () {
        return {
            template: template,
            scope: {
                model: "=ngModel",
                options: "="
            },
            link: function ($scope) {
                $scope.model = $scope.model || [];
                $scope.options = $scope.options || [];
                $scope.formatTag = function (tag) {
                    if (!tag) {
                        return "";
                    }
                    return tag.label;
                };
            }
        };
    });
});