define([
    "./nlgTagInputModule"
], function (nlgTagInputModule) {
    "use strict";
    nlgTagInputModule.provider("nlgTagInputLinkService", [function () {

        var linkFunction = function () {
            return null;
        };

        this.$get = [function () {
            return {
                createLink: linkFunction
            };
        }];

        this.setLinkService = function (service) {
            linkFunction = service;
        };
    }]);
});