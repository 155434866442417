define([
    "../nlgHealthCheckerModule"
], function (nlgHealthCheckerModule) {
    "use strict";

    return nlgHealthCheckerModule.controller("HealthCheckerHistoryController", [
        "$scope",
        "nlgHealthCheckerService",
        "loadingService",
        "remoteExceptionHandler",
        function ($scope, nlgHealthCheckerService, loadingService, remoteExceptionHandler) {
            $scope.history = {};
            $scope.refresh = refresh;

            refresh();

            function refresh() {
                return loadingService(nlgHealthCheckerService.getHistoryByCheckerName($scope.model.instance, $scope.model.checkerName))
                    .catch(remoteExceptionHandler())
                    .then(function (history) {
                        return ($scope.history = history.reasonReadings);
                    });
            }
        }]);
});
