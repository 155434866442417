define(["./timeModule", "angular"], function (timeModule, angular) {
    "use strict";

    /**
     * @ngdoc filter
     * @name timeModule.preciseDuration
     * @description
     * Filtro para exibir uma dura��o at� seus milissegundos.
     * @param {number} duration Dura��o em milissegundos.
     * @returns {string} string com a dura��o.
     * @throws Joga erro quando a dura��o n�o � um n�mero.
     * */
    return timeModule.filter("preciseDuration", ["jsonFilter", function (jsonFilter) {
        var SUFFIX = {
            DAY: "d",
            HOUR: "h",
            MINUTE: "m",
            SECONDS: "s",
            MILISECONDS: "ms"
        };

        return function (duration) {
            if (angular.isUndefined(duration) || duration === null) {
                return "";
            }

            if (!angular.isNumber(duration)) {
                throw new Error("Duration should be the number of miliseconds. Got: " + jsonFilter(duration));
            }

            if (duration < 1) {
                return "0";
            }

            var seconds = parseInt(duration / 1000, 10);
            var minutes = parseInt(seconds / 60, 10);
            var hours = parseInt(minutes / 60, 10);
            var days = parseInt(hours / 24, 10);

            return (
                stringForTime(days, SUFFIX.DAY) +
                stringForTime(hours % 24, SUFFIX.HOUR) +
                stringForTime(minutes % 60, SUFFIX.MINUTE) +
                ((hours > 0) ? "" : stringForTime(seconds % 60, SUFFIX.SECONDS)) +
                ((hours > 0 || minutes > 0) ? "" : stringForTime(duration % 1000, SUFFIX.MILISECONDS))
            ).trim();
        };

        function stringForTime(time, suffix) {
            if (time > 0) {
                return time + suffix + " ";
            }
            return "";
        }
    }]);
});