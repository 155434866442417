define(["./mapsModule", "text!./carrierZonesPlot.html", "angular"], function (maps, carrierZonesPlotTemplate, angular) {
    "use strict";

    return maps.directive("carrierZonesPlot", ["carrierZoneGrouperService", function (carrierZoneGrouperService) {
        return {
            template: carrierZonesPlotTemplate,
            scope: {
                polygonDrawerApi: "<",
                refreshCallback: "&"
            },
            controller: ["$scope", function ($scope) {
                $scope.polygonCarrierZoneGroupers = [];
                $scope.selectedPolygonCarrierZoneGrouper = null;

                $scope.isCurrentPolygonGrouper = function (carrierZoneGrouper) {
                    return angular.equals($scope.selectedPolygonCarrierZoneGrouper, carrierZoneGrouper);
                };

                $scope.toggleSelectedGrouper = function (carrierZoneGrouper) {
                    if ($scope.isCurrentPolygonGrouper(carrierZoneGrouper)) {
                        $scope.selectedPolygonCarrierZoneGrouper = null;
                        return;
                    }
                    $scope.selectedPolygonCarrierZoneGrouper = carrierZoneGrouper;
                };

                carrierZoneGrouperService.getPolygonCarrierZoneGroupers().then(function (groupers) {
                    $scope.polygonCarrierZoneGroupers = groupers;
                });

                $scope.$watch("selectedPolygonCarrierZoneGrouper", function (currentGrouper) {
                    if (!currentGrouper) {
                        if ($scope.polygonDrawerApi && $scope.polygonDrawerApi.setPolygons) {
                            $scope.polygonDrawerApi.setPolygons([], true);
                            $scope.refreshCallback();
                        }
                        return;
                    }

                    // apaga polígonos anteriores
                    carrierZoneGrouperService.getPolygons(currentGrouper).then(function (polygons) {
                        // desenha polígonos  ( polygons)
                        $scope.currentGrouperPolygons = polygons;
                        if (!polygons) {
                            return;
                        }
                        $scope.polygonDrawerApi.setPolygons(polygons.polygonElements, true);
                    });
                });
            }]
        };
    }]);
});