define(["./arrangementModule", "../arrays/arrays", "angular"], function (arrangementModule, arrays, angular) {
    "use strict";

    return arrangementModule.directive("nlgSimulationArrangement", [function () {
        return {
            restrict: "E",
            scope: {
                arrangementDescription: "=",
                getColorRegistry: "=",
                getCustomColorsRegistry: "="
            },
            template: "<nlg-arrangement-content> </nlg-arrangement-content>",
            controller: ["$scope", "$controller", "arrangementViewType", function ($scope, $controller, arrangementViewType) {
                $controller("nlgArrangementController", {
                    $scope: $scope
                });
                $scope.viewTypes = arrays.filter(arrangementViewType, function (type) {
                    return angular.equals(type.name, "DELIVERY_UNIT") || angular.equals(type.name, "PRODUCT");
                });
                $scope.selectViewType = true;
                $scope.showObjectDescription = false;
                $scope.canViewDeliveryUnit = function () {
                    return false;
                };
                $scope.getColorRegistry.register($scope.getIdColor);

                $scope.getCustomColorsRegistry.register($scope.customColors);
            }]
        };
    }]);
});