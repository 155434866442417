define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    return templateFactoryModule.service("templateFactoryDateIntervalTemplate", [function () {
        return function (config) {
            var element = angular.element("<date-interval>");
            if (config.model) {
                element.attr("ng-model", config.model);
            }
            return element;
        };
    }]);
});