define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    return templateFactoryModule.service("templateFactoryRangeChartTemplate", [function () {
        return function (config) {
            var element = angular.element("<discrete-axis-chart>").attr("type", "category");
            if (config.context) {
                element.attr("context", config.context);
            }
            if (config.width) {
                element.attr("width", config.width);
            }
            if (config.height) {
                element.attr("height", config.height);
            }
            return element;
        };
    }]);
});