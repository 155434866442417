define(["./tollModule"], function (tollModule) {
    "use strict";

    return tollModule.service("tollService", ["$http", "loadingService", "messagesModal", "remoteExceptionHandler", "tollServiceUrl",
        function ($http, loadingService, messagesModal, remoteExceptionHandler, tollServiceUrl) {

            return {
                calculateTollFromNodeIdentifiers: calculateTollFromNodeIdentifiers
            };

            function calculateTollFromNodeIdentifiers(nodeIdentifiers, context) {
                return loadingService($http.post(tollServiceUrl.getCalculateTollUrlForNodeIdentifier(), nodeIdentifiers)
                    .success(function (response) {
                        if (response.success) {
                            return messagesModal("dialog.success", response.messages).then(function () {
                                return context.refilter();
                            });
                        }
                    }));
            }
        }]);
});