define([
    "angular",
    "./genericParamsModule",
    "text!./genericParamDataEditionModal.html"
], function (angular, programmingModule, template) {
    "use strict";

    return programmingModule.service("genericParamDataEditionModal", [
        "$modal",
        "autocompleteFactory",
        "GenericParamWithEntityFilterService",
        "genericParamsUrls",
        function ($modal, autocompleteFactory, GenericParamWithEntityFilterService, genericParamsUrls) {
            var definitionsServiceUrl = genericParamsUrls.definitionsServiceUrl;

            function showModal(options) {
                return $modal.open({
                    size: "xl",
                    template: template,
                    controller: ["$scope", function ($scope) {

                        $scope.selected = {"definition": null};
                        if (options.definitionGroup) {
                            $scope.getGenericParamDefinitions = autocompleteFactory.lazyLoader(definitionsServiceUrl, [], {
                                "definitionGroupSourceId": options.definitionGroup.sourceId,
                                "entityType": options.entityType
                            });
                            $scope.definitionGroup = options.definitionGroup;
                        } else {
                            // Fluxo de sobrescrita de parâmetros genéricos na otimização
                            $scope.getGenericParamDefinitions = function () {
                                return options.definitions;
                            };
                            $scope.definitionGroup = "genericParamDataDialog.genericHeader";
                        }

                        var getDatasToSaveFromCurrentDefinition = null;
                        $scope.registerApi = function (api) {
                            getDatasToSaveFromCurrentDefinition = api;
                        };

                        var datasToSave = {};
                        $scope.updateDatasToSave = function () {
                            if ($scope.selected.definition) {
                                datasToSave[$scope.selected.definition.sourceId] = getDatasToSaveFromCurrentDefinition();
                            }
                        };

                        if (options.entityBeingEdited) {
                            $scope.filterService = new GenericParamWithEntityFilterService(options.entityBeingEdited);
                        }
                    }]
                }).result;
            }

            return {
                "fromDefinitionGroup": function (definitionGroup, entityDTOBeingEdited) {
                    return showModal({
                        "definitionGroup": definitionGroup,
                        "entityBeingEdited": entityDTOBeingEdited,
                        "entityType": entityDTOBeingEdited.entityClass
                    });
                },
                "fromDefinitions": function (definitions) {
                    return showModal({
                        "definitions": definitions,
                    });
                }
            };
        }]);
});
