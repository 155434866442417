define([
    "../nlgHealthCheckerModule",
    "c3",
], function (nlgHealthCheckerModule, c3) {
    "use strict";

    return nlgHealthCheckerModule.directive("nlgHealthCheckerHistoryGraph", [function () {
        return {
            restrict: "E",
            template: "<div class='nlgHealthCheckerHistoryGraph'></div>",
            scope: {
                reason: "<"
            },
            controller: ["$scope", "$element", "dateFilter", "translateFilter", "healthCheckerHistoryGraphFactory", function ($scope, $element, dateFilter, translateFilter, healthCheckerHistoryGraphFactory) {
                var configurationFactory = healthCheckerHistoryGraphFactory.createFor($scope.reason);
                if (!configurationFactory) {
                    return;
                }

                var chartData = configurationFactory.configureData({
                    json: [],
                    keys: {
                        x: "timestamp",
                        value: ["value"]
                    }
                }, $scope.reason.datas);
                var chartConfiguration = configurationFactory.configureChart({
                    bindto: $element.find(".nlgHealthCheckerHistoryGraph")[0],
                    data: chartData,
                    axis: {
                        x: {
                            type: "timeseries",
                            tick: {
                                format: function (x) {
                                    return dateFilter(x, "short");
                                }
                            }
                        }
                    },
                    legend: {
                        show: false
                    },
                    tooltip: {
                        format: {
                            name: function (name) {
                                return translateFilter(name);
                            }
                        }
                    }
                });
                var chart = c3.generate(chartConfiguration);

                $scope.$watch("reason.datas", function (datas) {
                    chart.load(configurationFactory.configureData(chartData, datas));
                });
            }]
        };
    }]);
});
