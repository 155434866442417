define(["./violationModule", "text!./violationModalSimpleMessage.html"], function (violationModule, violationModalSimpleMessageTemplate) {
    "use strict";

    /**
     * @ngdoc directive
     * @name violationModule.directive:violationModalSimpleMessage
     * @restrict E
     * @description
     * Diretiva que exibe uma SimpleMessage.
     *
     * @param {SimpleMessage} message SimpleMessage à ser exibida.
     * */
    return violationModule.directive("violationModalSimpleMessage", [function () {
        return {
            restrict: "E",
            template: violationModalSimpleMessageTemplate,
            scope: {
                message: "<"
            }
        };
    }]);
});