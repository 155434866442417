define([
    "./nlgLGPDModule",
    "../arrays/arrays",
    "angular",
    "text!./nlgLGPDSearch.html",
    "lodash",
    "json!./tdn/tdnByEntity.json",
], function (nlgLGPDModule, arrays, angular, template, _, tdnByEntity) {
    "use strict";

    return nlgLGPDModule.directive("nlgLGPD", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {},
            controller: ["$scope",
                "autocompleteFactory",
                "messagesModal",
                "remoteExceptionHandler",
                "$q",
                "xlsService",
                "nlgLGPDService",
                "loadingService",
                "Set",
                "$translate",
                "tdnHelp",
                function ($scope, autocompleteFactory, messagesModal, remoteExceptionHandler, $q, xlsService, nlgLGPDService, loadingService, Set, $translate, tdnHelp) {
                    var template;
                    var paginationApi;
                    $scope.tdn = undefined;
                    if (tdnByEntity && tdnByEntity[tdnHelp.getContext()] && tdnByEntity[tdnHelp.getContext()]["entity"]) {
                        $scope.tdn = tdnByEntity[tdnHelp.getContext()]["entity"];
                    }
                    $scope.context = tdnHelp.getContext();

                    $scope.filter = nlgLGPDService.filterServiceName();

                    $scope.search = function (modifiedTemplate) {
                        template = modifiedTemplate;
                        paginationApi.seekPage(1);
                    };

                    $scope.onChangeServiceName = function (model) {
                        if (!model.owner || !model.owner.serviceName) {
                            paginationApi.clearResults();
                            return;
                        }
                        $scope.serviceName = model.owner.serviceName;
                    };

                    $scope.model = {
                        owner: null,
                        entity: null,
                        originalEntity: null,
                        personal: null,
                        sensitive: null,
                        anonymizable: null,
                        justification: null
                    };

                    $scope.definitions = getDefinitions();

                    function getDefinitions() {
                        return $q.all(nlgLGPDService.getDefinitions())
                            .then(function (allItems) {
                                var resultNames = new Set();
                                allItems.forEach(function (entry) {
                                    entry.value.forEach(function (name) {
                                        var entityData = {
                                            entityName: name.entityName,
                                            presentationName: name.presentationName,
                                            tableName: name.tableName,
                                            artifactName: entry.key.artifactName,
                                        };
                                        resultNames.add(entityData);
                                    });
                                });
                                return resultNames.toArray();
                            });
                    }

                    $scope.getEntityName = function (tag) {
                        if (!tag) return "";
                        return "(" + _.capitalize($translate.instant(tag.artifactName)) + ") - " + tag.presentationName;
                    };

                    $scope.findEntities = function () {
                        return paginationApi.seekPage(1);
                    };

                    $scope.tableOptions = {
                        records: [],
                        pagination: {
                            onPaginate: function (firstResult, maxResults) {
                                return nlgLGPDService.filterByArtifact(template, $scope.model.owner.artifactName, firstResult, maxResults, $scope.model.owner.entityName)
                                    .then(function (response) {
                                        var result = response.data;
                                        var ownerArtifactName = $scope.model.owner.artifactName;
                                        if (ownerArtifactName === "cpl" || ownerArtifactName === "r2") {
                                            result.result[0].lgpdDefinitions.forEach(function (definition) {
                                                definition.artifactName = ownerArtifactName;
                                            });
                                            result.result = result.result[0].lgpdDefinitions;
                                        } else {
                                            response.data.result.forEach(function (definition) {
                                                definition.artifactName = $scope.model.owner.artifactName;
                                            });
                                        }
                                        return result;
                                    })
                                    .catch(remoteExceptionHandler());
                            }
                        },
                        onRegisterPagination: function (api) {
                            paginationApi = api;
                        }
                    };

                    $scope.delete = function (entity) {
                        return messagesModal.cancellable("dialog.warning", ["edition.lgpd.delete.entity"])
                            .then(function () {
                                return nlgLGPDService.delete(entity).then(function () {
                                    return messagesModal("dialog.success", {
                                        keyBundle: "lgpd.edition.delete.success"
                                    }).then(function () {
                                        paginationApi.seekPage(1);
                                    });
                                }).catch(remoteExceptionHandler());
                            });
                    };
                }]
        };
    }]);
});

