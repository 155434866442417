define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    return templateFactoryModule.service("templateFactoryStackedBarTemplate", [function () {
        return function (config) {
            var element = angular.element("<div>");
            if (config.context) {
                element.attr("stacked-bar-chart", config.context);
            }
            if (config.width) {
                element.attr("size", config.width);
            }
            if (config.height) {
                element.attr("size", config.height);
            }
            if (config.absolute) {
                element.attr("absolute", config.absolute);
            }
            return element;
        };
    }]);
});