define([
    "./simulationModule",
    "two",
    "../arrays/arrays",
    "angular"
], function (programmingModule, TwoModule, arrays, angular) {
    "use strict";
    var Two = TwoModule.default;
    /**
     * @ngdoc service
     * @name simulationModule.simulationDrawerService
     * @description
     * Serviço para desenhar os lastros dos pallets da tela de simulação.
     * */
    return programmingModule.service("simulationDrawerService", function () {
        return {
            /**
             * @ngdoc service
             * @name simulationDrawerService.createTwo
             * @methodOf programmingModule.simulationDrawerService
             * @description
             * Cria um novo objeto Two.
             * */
            "createTwo": function(){
                return new Two({
                    width: "640",
                    height: "480",
                    type: Two.Types.canvas
                });
            },
            /**
             * @ngdoc service
             * @name simulationDrawerService.drawOnTwo
             * @methodOf programmingModule.simulationDrawerService
             * @description
             * Desenha os retangulos especificados dentro do two passado como parâmetro.
             * Este método altera o conteúdo do two especificado, sendo que seu conteúdo anterior é apagado e novas imagens são criadas.
             * */
            "drawOnTwo": function(two, rectangles){
                two.clear();
                var group = two.makeGroup();
                // FIXME: obter maxX e maxY independente da primeira posição
                var container = rectangles[0];
                var maxX = container.width;
                var maxY = container.height;
                arrays.each(rectangles, function (rectangle) {
                    var rectangleProperties = angular.extend({
                        x: 0,
                        y: 0,
                        width:0,
                        height:0,
                        color: "rgb(0, 200, 255)",
                        opacity: 0.75,
                        stroke: "#000",
                        linewidth: 5
                    }, rectangle);
                    var rect = two.makeRectangle(
                        rectangleProperties.x,
                        rectangleProperties.y,
                        rectangleProperties.width,
                        rectangleProperties.height);
                    rect.fill = rectangleProperties.color;
                    rect.opacity = rectangleProperties.opacity;
                    rect.stroke = rectangleProperties.stroke;
                    rect.linewidth = rectangleProperties.linewidth;
                    group.add(rect);
                });

                // Escala para que a maior dimensão ocupe 85% do canvas
                var scale = Math.min(two.width/maxX, two.height/maxY) * 0.85;
                group.scale = scale;
                // Centraliza grupo
                group.translation.set((two.width - maxX*scale)/2, (two.height - maxY*scale)/2);
                two.add(group);
                two.update();
            },
            /**
             * @ngdoc service
             * @name simulationDrawerService.getImageUrl
             * @methodOf programmingModule.simulationDrawerService
             * @description
             * Dado os retangulos especificados, cria uma imagem 2D e retorna a URL da mesma.
             * @returns {String} URL da imagem 2D gerada.
             * */
            "getImageUrl": function(rectangles){
                var two = this.createTwo();
                this.drawOnTwo(two, rectangles);
                return two.renderer.domElement.toDataURL();
            }
        };
    });
});