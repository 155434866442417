define([], function () {
    "use strict";

    return {
        getStateIfCountryNotChange: getCityIfStateNotChange,
        getCityIfStateNotChange: getCityIfStateNotChange
    };

    function getCityIfStateNotChange() {
        var oldState = null;
        return function (state, city) {
            var result = city;
            if (!oldState || !equals(oldState, state)) {
                result = null;
            }
            oldState = state;
            return result;
        };
    }

    function equals(first, second) {
        first = first || {};
        second = second || {};
        return first.id === second.id;
    }
});
