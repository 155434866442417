define([
    "angular",
    "../entityDTO/entityDTO",
    "../violation/violation",
    "../remote/remote"
], function (angular) {
    "use strict";

    return angular.module("addressModule", [
        "entityDTOModule",
        "violationModule",
        "remoteModule"
    ]);
});