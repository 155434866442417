define(["./addressModule", "angular"], function (addressModule, angular) {
    "use strict";

    return addressModule.controller("AddressSelectionController", ["$scope", "$modalInstance", "validAddresses", function ($scope, $modalInstance, validAddresses) {
        $scope.validAddresses = validAddresses;
        $scope.selection = {
            value: null
        };
        $scope.apply = function () {
            $modalInstance.close($scope.selection.value);
        };

        $scope.map = {
            center: {latitude: 0, longitude: 0},
            zoom: 14,
            key: "key",
            selected: {latitude: 0, longitude: 0}
        };

        $scope.showOnMap = function (geo) {
            $scope.map.selected = geo;
            $scope.map.center = angular.copy(geo);
        };
    }]);
});