define(["./nodeCommandsModule"], function (nodeCommandsModule) {
    "use strict";

    return nodeCommandsModule.service("nodeCommandOptimize", [
        "optimizationServiceInvoker",
        "messagesModal",
        "$q",
        function (optimizationServiceInvoker, messagesModal, $q) {
            return function (trips, orders, config) {
                if (trips && trips.length > 0 || orders && orders.length > 0) {
                    return optimizationServiceInvoker.optimize(trips, orders, config);
                }
                return messagesModal("dialog.error", "optimization.error.empty.froms")
                    .then(function(){
                        return $q.reject();
                    });
            };
        }
    ]);
});