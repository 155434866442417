define([
    "./arrangementModule",
    "./arrangementDescriptionService",
    "./arrangementUrls",
    "./arrangementDrawerService",
    "./arrangementViewerModal",
    "./ArrangementColorGrouper",
    "./nlgArrangementDrawer",
    "./nlgArrangementObjectDescription",
    "./nlgArrangementContent",
    "./nlgArrangement",
    "./nlgDeliveryUnitArrangement",
    "./nlgArrangementController",
    "./arrangementViewType",
    "./Object3DInfo",
    "./arrangementLayerReport",
    "./nlgSimulationArrangement"
], function () {
});