define([
    "./monitorModule",
    "lodash"
], function (module, _) {
    "use strict";

    return module.service("monitorMappingParserV1", [function () {
        return function createMappings(springBootMappingFormat) {
            return _.chain(springBootMappingFormat)
                .map(function (value, key) {
                    return {
                        route: key,
                        bean: value.bean,
                        method: value.method
                    };
                })
                .map(splitMappings)
                .flatten()
                .value();
        };

        function splitMappings(mapping) {
            if (!_.startsWith(mapping.route, "{")) {
                mapping.extraInfo = {};
                return [mapping];
            }
            var mappingObject = parseMappingObject(mapping.route);
            return mappingObject.urls.map(function (route) {
                return {route: route, bean: mapping.bean, method: mapping.method, extraInfo: mappingObject.extraInfo};
            });
        }

        function parseMappingObject(mapping) {
            var context = {string: mapping};
            readToken("{", context);
            var result = readUrlMappings(context);
            readToken("}", context);
            return result;
        }

        function readUrlMappings(context) {
            var result = {urls: readArray(context), extraInfo: {}};
            while (readToken(",", context) !== "") {
                var propertyName = readToken("\\w+", context);
                readToken("=", context);
                result.extraInfo[propertyName] = readArray(context);
            }
            return result;
        }

        function readArray(context) {
            readToken("\\[", context);
            var results = [];
            do {
                results.push(readToken("[-/{}\\w\\d:.?&+*]+", context));
            } while (readToken("\\|\\|", context) !== "");
            readToken("]", context);
            return results;
        }

        function readToken(tokenPattern, context) {
            var result = context.string.match(new RegExp("^\\s*" + tokenPattern + "\\s*"));
            if (result === null) {
                return "";
            }
            context.string = context.string.substring(result[0].length);
            return result[0].trim();
        }
    }]);
});