define(["./threeModule", "../colors/colors"], function (threeModule, colors) {
    "use strict";

    return threeModule.service("colorOfObject3D", ["Object3DInfo", function (Object3DInfo) {
        return function (object) {
            return colors.getColor(getColorGroupOfObject(object));
        };

        function getColorGroupOfObject(object) {
            return object.extraInfo[Object3DInfo.COLOR_GROUP];
        }
    }]);
});