define(["angular",
    "./chartingModule"
], function (angular, chartingModule) {
    "use strict";
    chartingModule.service("chartLocale", ["$filter", function ($filter) {
        return {
            formatCurrency: formatCurrency,
            formatDecimal: formatDecimal,
            formatNumberData: formatNumberData,
            createFormatter: createFormatter,
        };

        function createFormatter(targetType) {
            if (isOfType("bytes")) {
                return $filter("bytes");
            }
            if (isOfType("percentage")) {
                return formatPercentage;
            }
            if (isOfType("real")) {
                return $filter("real");
            }
            if (isOfType("decimal")) {
                return formatDecimal;
            }
            if (isOfType("currency")) {
                return formatCurrency;
            }
            if (isOfType("weight")) {
                return $filter("weight");
            }
            if (isOfType("volume")) {
                return $filter("volume");
            }
            if (isOfType("duration")) {
                return $filter("preciseDuration");
            }
            return function(value){
                return value;
            };

            function isOfType(type) {
                return targetType === type;
            }
        }

        function formatCurrency(value){
            return $filter("currency")(value);
        }

        function formatDecimal(value){
            return $filter("decimal")(value, 2);
        }

        function formatNumberData(valueType, value){
            return createFormatter(valueType)(value);
        }

        function formatPercentage(value){
            return $filter("percentage")(value, 1);
        }
    }]);
});
