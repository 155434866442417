define([
    "angular",
    "text!./DELIVERY_UNIT.html",
    "text!./DELIVERY_UNIT_HEADER.html",
    "text!./HORIZONTAL_LAYER.html",
    "text!./HORIZONTAL_LAYER_HEADER.html",
    "text!./LOADING_STOP.html",
    "text!./LOADING_STOP_HEADER.html",
    "text!./LOADING_STOP_ORDER_PRODUCT.html",
    "text!./LOADING_STOP_ORDER_PRODUCT_HEADER.html",
    "text!./LOADING_SUBSTOP.html",
    "text!./LOADING_SUBSTOP_HEADER.html",
    "text!./ORDER.html",
    "text!./ORDER_HEADER.html",
    "text!./ORDER_ITEM.html",
    "text!./ORDER_ITEM_HEADER.html",
    "text!./ORDER_PRODUCT.html",
    "text!./ORDER_PRODUCT_HEADER.html",
    "text!./PRODUCT.html",
    "text!./PRODUCT_CATEGORY.html",
    "text!./PRODUCT_CATEGORY_HEADER.html",
    "text!./PRODUCT_HEADER.html",
    "text!./SHIPMENT_UNIT_WRAPPER.html",
    "text!./SHIPMENT_UNIT_WRAPPER_CATEGORY.html",
    "text!./SHIPMENT_UNIT_WRAPPER_CATEGORY_HEADER.html",
    "text!./SHIPMENT_UNIT_WRAPPER_HEADER.html",
    "text!./UNLOADING_STOP.html",
    "text!./UNLOADING_STOP_HEADER.html",
    "text!./UNLOADING_STOP_ORDER_PRODUCT.html",
    "text!./UNLOADING_STOP_ORDER_PRODUCT_HEADER.html",
    "text!./UNLOADING_SUBSTOP.html",
    "text!./UNLOADING_SUBSTOP_HEADER.html",
    "text!./VERTICAL_LAYER.html",
    "text!./VERTICAL_LAYER_HEADER.html"
], function (angular,
             DELIVERY_UNIT,
             DELIVERY_UNIT_HEADER,
             HORIZONTAL_LAYER,
             HORIZONTAL_LAYER_HEADER,
             LOADING_STOP,
             LOADING_STOP_HEADER,
             LOADING_STOP_ORDER_PRODUCT,
             LOADING_STOP_ORDER_PRODUCT_HEADER,
             LOADING_SUBSTOP,
             LOADING_SUBSTOP_HEADER,
             ORDER,
             ORDER_HEADER,
             ORDER_ITEM,
             ORDER_ITEM_HEADER,
             ORDER_PRODUCT,
             ORDER_PRODUCT_HEADER,
             PRODUCT,
             PRODUCT_CATEGORY,
             PRODUCT_CATEGORY_HEADER,
             PRODUCT_HEADER,
             SHIPMENT_UNIT_WRAPPER,
             SHIPMENT_UNIT_WRAPPER_CATEGORY,
             SHIPMENT_UNIT_WRAPPER_CATEGORY_HEADER,
             SHIPMENT_UNIT_WRAPPER_HEADER,
             UNLOADING_STOP,
             UNLOADING_STOP_HEADER,
             UNLOADING_STOP_ORDER_PRODUCT,
             UNLOADING_STOP_ORDER_PRODUCT_HEADER,
             UNLOADING_SUBSTOP,
             UNLOADING_SUBSTOP_HEADER,
             VERTICAL_LAYER,
             VERTICAL_LAYER_HEADER) {
    "use strict";

    return angular.module("viewTypeTableOverloadTemplates", [])
        .run(["$templateCache", function ($templateCache) {
            $templateCache.put("arrangement/viewTypeTableOverload/DELIVERY_UNIT.html", DELIVERY_UNIT);
            $templateCache.put("arrangement/viewTypeTableOverload/DELIVERY_UNIT_HEADER.html", DELIVERY_UNIT_HEADER);
            $templateCache.put("arrangement/viewTypeTableOverload/HORIZONTAL_LAYER.html", HORIZONTAL_LAYER);
            $templateCache.put("arrangement/viewTypeTableOverload/HORIZONTAL_LAYER_HEADER.html", HORIZONTAL_LAYER_HEADER);
            $templateCache.put("arrangement/viewTypeTableOverload/LOADING_STOP.html", LOADING_STOP);
            $templateCache.put("arrangement/viewTypeTableOverload/LOADING_STOP_HEADER.html", LOADING_STOP_HEADER);
            $templateCache.put("arrangement/viewTypeTableOverload/LOADING_STOP_ORDER_PRODUCT.html", LOADING_STOP_ORDER_PRODUCT);
            $templateCache.put("arrangement/viewTypeTableOverload/LOADING_STOP_ORDER_PRODUCT_HEADER.html", LOADING_STOP_ORDER_PRODUCT_HEADER);
            $templateCache.put("arrangement/viewTypeTableOverload/LOADING_SUBSTOP.html", LOADING_SUBSTOP);
            $templateCache.put("arrangement/viewTypeTableOverload/LOADING_SUBSTOP_HEADER.html", LOADING_SUBSTOP_HEADER);
            $templateCache.put("arrangement/viewTypeTableOverload/ORDER.html", ORDER);
            $templateCache.put("arrangement/viewTypeTableOverload/ORDER_HEADER.html", ORDER_HEADER);
            $templateCache.put("arrangement/viewTypeTableOverload/ORDER_ITEM.html", ORDER_ITEM);
            $templateCache.put("arrangement/viewTypeTableOverload/ORDER_ITEM_HEADER.html", ORDER_ITEM_HEADER);
            $templateCache.put("arrangement/viewTypeTableOverload/ORDER_PRODUCT.html", ORDER_PRODUCT);
            $templateCache.put("arrangement/viewTypeTableOverload/ORDER_PRODUCT_HEADER.html", ORDER_PRODUCT_HEADER);
            $templateCache.put("arrangement/viewTypeTableOverload/PRODUCT.html", PRODUCT);
            $templateCache.put("arrangement/viewTypeTableOverload/PRODUCT_CATEGORY.html", PRODUCT_CATEGORY);
            $templateCache.put("arrangement/viewTypeTableOverload/PRODUCT_CATEGORY_HEADER.html", PRODUCT_CATEGORY_HEADER);
            $templateCache.put("arrangement/viewTypeTableOverload/PRODUCT_HEADER.html", PRODUCT_HEADER);
            $templateCache.put("arrangement/viewTypeTableOverload/SHIPMENT_UNIT_WRAPPER.html", SHIPMENT_UNIT_WRAPPER);
            $templateCache.put("arrangement/viewTypeTableOverload/SHIPMENT_UNIT_WRAPPER_CATEGORY.html", SHIPMENT_UNIT_WRAPPER_CATEGORY);
            $templateCache.put("arrangement/viewTypeTableOverload/SHIPMENT_UNIT_WRAPPER_CATEGORY_HEADER.html", SHIPMENT_UNIT_WRAPPER_CATEGORY_HEADER);
            $templateCache.put("arrangement/viewTypeTableOverload/SHIPMENT_UNIT_WRAPPER_HEADER.html", SHIPMENT_UNIT_WRAPPER_HEADER);
            $templateCache.put("arrangement/viewTypeTableOverload/UNLOADING_STOP.html", UNLOADING_STOP);
            $templateCache.put("arrangement/viewTypeTableOverload/UNLOADING_STOP_HEADER.html", UNLOADING_STOP_HEADER);
            $templateCache.put("arrangement/viewTypeTableOverload/UNLOADING_STOP_ORDER_PRODUCT.html", UNLOADING_STOP_ORDER_PRODUCT);
            $templateCache.put("arrangement/viewTypeTableOverload/UNLOADING_STOP_ORDER_PRODUCT_HEADER.html", UNLOADING_STOP_ORDER_PRODUCT_HEADER);
            $templateCache.put("arrangement/viewTypeTableOverload/UNLOADING_SUBSTOP.html", UNLOADING_SUBSTOP);
            $templateCache.put("arrangement/viewTypeTableOverload/UNLOADING_SUBSTOP_HEADER.html", UNLOADING_SUBSTOP_HEADER);
            $templateCache.put("arrangement/viewTypeTableOverload/VERTICAL_LAYER.html", VERTICAL_LAYER);
            $templateCache.put("arrangement/viewTypeTableOverload/VERTICAL_LAYER_HEADER.html", VERTICAL_LAYER_HEADER);
        }]);
});