define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    return templateFactoryModule.service("templateFactoryChartGroupTemplate", [function () {
        return function (config) {
            var element = angular.element("<chart-group>");
            if (config.context) {
                element.attr("context", config.context);
            }
            return element;
        };
    }]);
});