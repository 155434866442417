define(["./timeModule"], function (timeModule) {
    "use strict";

    return timeModule.service("timeService", [function () {
        var self = this;

        self.now = function now() {
            var dateNow = new Date();
            return self.toMillis(dateNow.getHours(), dateNow.getMinutes());
        };

        self.toMillis = function toMillis(hours, minutes) {
            return (hours * 60 + minutes) * 60000;
        };

        self.toTime = function toTime(millis) {
            var totalMinutes = Math.floor(millis / 60000);
            return {
                hours: Math.floor(totalMinutes / 60) % 24,
                minutes: totalMinutes % 60
            };
        };

        return self;
    }]);
});