define([
    "../nlgHealthCheckerModule",
    "text!./nlgHealthCheckerTableReason.html",
    "lodash"
], function (module, template) {
    "use strict";

    return module.directive("nlgHealthCheckerTableReason", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                reason: "<"
            }
        };
    }]);
});
