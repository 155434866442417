define(["./backgroundTaskJobModule"], function (module) {
    "use strict";

    return module.service("backgroundTaskJobService", ["$http", "downloadBinary", "backgroundTaskJobUrls", function ($http, downloadBinary, backgroundTaskJobUrls) {

        function violations(id) {
            return $http.get(backgroundTaskJobUrls.violations + id);
        }

        function canDownloadTask(id) {
            return $http.get(backgroundTaskJobUrls.canDownloadTask + id);
        }

        function downloadTask(id, exportType) {
            downloadBinary.get(backgroundTaskJobUrls.downloadTask + id, id + exportType);
        }

        function getExportType(id) {
            return $http.get(backgroundTaskJobUrls.getExportType + id);
        }

        return {
            violations: violations,
            canDownloadTask: canDownloadTask,
            downloadTask: downloadTask,
            getExportType: getExportType
        };
    }]);
});