define(["./nodeCommandsModule"], function (nodeCommandsModule) {
    "use strict";

    return nodeCommandsModule.service("nodeCommandFinalizeTrip", [
        "messagesModal",
        "$http",
        "loadingService",
        "remoteExceptionHandler",
        "pathService",
        function (messagesModal, $http, loadingService, remoteExceptionHandler, pathService) {
            return function (selection) {
                return messagesModal.cancellable("trip.finalize.modal.title", "trip.finalize.modal.body")
                    .then(function () {
                        return loadingService($http.post(pathService.getPath("finalize-trip"), selection))
                            .catch(remoteExceptionHandler());
                    }).then(function () {
                        return messagesModal("dialog.success", "trip.finalize.success");
                    });
            };
        }]);
});