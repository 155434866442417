define(["./violationModule", "text!./violationModal.html"], function (violationModule, violationModalTemplate) {
    "use strict";

    /**
     * @ngdoc service
     * @name violationModule.violationModal
     * @description
     * Serviço que exibe para o usuário as violações de uma operação manual e permite
     * que o usuário continue com o fluxo caso possível.
     *
     * @param {manualResult} manualResult Resultado de uma operação manual.
     * @return {Promise} promessa que é resolvida com o próprio `manualResult` fornecido.
     * */
    return violationModule.provider("violationModal", function () {
        var shouldHideRoutingContext = false;

        this.$get = ["$modal", function ($modal) {
            return function (manualResult, okActionName) {
                return $modal.open({
                    size: "lg",
                    template: violationModalTemplate,
                    controller: ["$scope", function ($scope) {
                        $scope.manualResult = manualResult;
                        $scope.okActionName = okActionName || "filtersView.save";
                        $scope.hideRoutingContext = shouldHideRoutingContext;
                    }]
                }).result;
            };
        }];

        this.hideRoutingContext = function () {
            shouldHideRoutingContext = true;
        };
    });
});