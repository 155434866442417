define([
    "../nlgLGPDModule",
], function (nlgLGPDModule) {
    "use strict";

    return nlgLGPDModule.controller("nlgLGPDSQLSearchController", [
        "$scope",
        "$q",
        "Set",
        "$state",
        "nlgLGPDSQLService",
        "remoteExceptionHandler",
        "messagesModal",
        function ($scope, $q, Set, $state, sqlService, remoteExceptionHandler, messagesModal) {

            $scope.model = null;
            $scope.filterPath = sqlService.filterServiceName();
            $scope.definitions = getDefinitions();

            function getDefinitions() {
                return $q.all(sqlService.getDefinitions())
                    .then(function (allItems) {
                        var resultNames = new Set();
                        allItems.forEach(function (entry) {
                            entry.value.forEach(function (entity) {
                                var entityData = {
                                    entityName: entity.entityName,
                                    artifactName: entry.key.artifactName,
                                };
                                resultNames.add(entityData);
                            });
                        });
                        return resultNames.toArray();
                    });
            }

            $scope.getEntityName = function (tag) {
                if (!tag) return "";
                return tag.entityName;

            };

            $scope.onChangeServiceName = function (model) {
                if (!model || !model.artifactName) {
                    paginationApi.clearResults();
                    return;
                }
                $scope.entityName = model.entityName;
                $scope.artifactName = model.artifactName;
            };

            var template, paginationApi, paginationApiDefer = $q.defer();
            $scope.search = function (patch) {
                template = patch;
                paginationApi.seekPage(1);
            };

            $scope.tableOptions = {
                records: [],
                pagination: {
                    onPaginate: function (firstResult, maxResults) {
                        return sqlService.filterRecords(template, firstResult, maxResults, $scope.artifactName, $scope.entityName)
                            .then(function (response) {
                                var result = response.data;
                                if ($scope.artifactName === "cpl" ||$scope.artifactName === "r2") {
                                    result.result[0].lgpdDefinitions.forEach(function (definition) {
                                        definition.artifactName = $scope.artifactName;
                                    });
                                    result.result = result.result[0].lgpdDefinitions;
                                }
                                else {
                                    response.data.result.forEach(function (definition) {
                                        definition.artifactName = "monitoring";
                                    });
                                }
                                return result;
                            })
                            .catch(remoteExceptionHandler());
                    }
                },
                onRegisterPagination: function (api) {
                    paginationApi = api;
                    paginationApiDefer.resolve();
                }
            };

            $scope.delete = function (id) {
                return messagesModal.cancellable("dialog.warning", ["edition.lgpd.delete.entity"])
                    .then(function () {
                        return sqlService.deleteEntity(id, $scope.artifactName).then(function () {
                            return messagesModal("dialog.success", {
                                keyBundle: "lgpd.edition.delete.success"
                            }).then(function () {
                                paginationApi.seekPage(1);
                            });
                        }).catch(remoteExceptionHandler());
                    });
            };

        }]);

});
