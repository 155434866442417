define(["./backgroundTaskJobModule", "text!./BackgroundTaskJobView.html"], function (module, template) {
    "use strict";

    return module.directive("nlgBackgroundTaskJobSearch", [function () {
        return {
            scope: true,
            template: template,
            controller: "BackgroundTaskJobController"
        };
    }]);
});