define(["./filterModule", "lodash"], function (filterModule, _) {
    "use strict";

    return filterModule.controller("nlgFilterSave", ["$scope", "$modalInstance", "filterSnapshots", "selectedFilterSnapshotName", "defaultFilter", "messagesModal", function ($scope, $modalInstance, filterSnapshots, selectedFilterSnapshotName, defaultFilter, messagesModal) {

        $scope.model = {
            filterName: selectedFilterSnapshotName || "",
            defaultFilter: defaultFilter
        };

        $scope.cancel = function () {
            $modalInstance.dismiss();
        };

        $scope.ok = function () {
            var isNameInUse = _.find(filterSnapshots, ["name", $scope.model.filterName]);
            if(isNameInUse) {
                return messagesModal.cancellable("dialog.warning", ["entity.validator.FilterNameAlreadyExists"]).then(function () {
                    $modalInstance.close($scope.model);
                });
            }else{
                $modalInstance.close($scope.model);
            }
        };
    }]);
});