define([
    "angular",
    "text!./template/customGanttTemplate.html",
    "moment",
    "moment-range",
    "angular-bootstrap",
    "angular-moment",
    "angular-ui-tree",
    "angular-gantt",
    "angular-gantt/assets/angular-gantt-tree-plugin",
    "angular-gantt/assets/angular-gantt-tooltips-plugin",
    "angular-gantt/assets/angular-gantt-movable-plugin",
    "angular-gantt/assets/angular-gantt-overlap-plugin"
], function (angular, customGanttTemplate) {
    "use strict";

    return angular.module("ganttChartModule", [
        "ui.bootstrap",
        "gantt",
        "gantt.tree",
        "gantt.tooltips",
        "gantt.movable",
        "gantt.overlap"])
        .run(["$templateCache", function($templateCache){
            $templateCache.put("nlgGanttChartCustomTemplate", customGanttTemplate);
        }]);
});

