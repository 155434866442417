define(["./arrangementModule", "text!./nlgArrangement.html"], function (arrangementModule, nlgArrangementTemplate) {
    "use strict";

    /**
     * @ngdoc directive
     * @name nlgArrangement
     * @restrict E
     *
     * @description
     *
     * @param {object} arrangementDescription
     * @param {Function=} onRemoveOverload
     * @param {boolean=} hideHeader Se presente esconde o cabeçalho
     * @param {boolean=} hideActions Se presente esconde as ações
     * @param {boolean=} autoRotate Se presente ativa rotação do arranjo automaticamente
     * @param {onRegisterNlgArrangementApiCallback=} onRegisterNlgArrangementApi
     *
     * @example
     <example>
     <file name="nlg-arrangement.html">
     <div>
     <nlg-arrangement arrangement-description="object" hide-header="true" hide-actions="true"></nlg-arrangement>
     </div>
     </file>
     </example>
     */
    return arrangementModule.directive("nlgArrangement", [function () {
        return {
            restrict: "E",
            scope: {
                arrangementDescription: "=?",
                onRemoveOverload: "&?",
                hideHeader: "@?",
                hideActions: "@?",
                autoRotate: "@?",
                onRegisterNlgArrangementApi: "&?",
            },
            template: nlgArrangementTemplate,
            controller: "nlgArrangementController"
        };
    }]);

    /**
     * @callback onRegisterNlgArrangementApiCallback
     * @param {Object} arrangementControlApi
     * @param {Function} arrangementControlApi.setSelectedContainerArrangement
     */

});