define([
    "../nlgHealthCheckerModule",
    "text!./nlgHealthCheckCompositeHealthInfoReason.html",
], function (module, template) {
    "use strict";

    return module.directive("nlgHealthCheckCompositeHealthInfoReason", [function () {
        return {
            template: template,
            scope: {
                reason: "<"
            }
        };
    }]);
});
