define(["./commonsOptimizationModule"], function (commonsOptimizationModule) {
    "use strict";

    return commonsOptimizationModule.service("optimizationServiceInvoker", [function () {
        var optimizationService;
        return {
            optimize: function (trips, orders, config) {
                if (!optimizationService) {
                    throw new Error("Service for optimization was not injected.");
                }
                return optimizationService(trips, orders, config);
            },
            setService: function (service) {
                optimizationService = service;
            }
        };
    }]);
});