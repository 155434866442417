define([
    "./chartingModule",
    "text!./seriesChart.html",
    "angular",
], function (chartingModule, template, angular) {
    "use strict";

    /**
     * @ngdoc directive
     * @name chartingModule.directive:seriesChart
     * @description
     * Diretiva que exibe um gráfico em série. É necessário ter uma legend chamada identifier
     *
     * @example
     * <example module="FrontEndWeb">
     *     <file name="index.html">
     *         <div ng-controller="example">
     *            <div table-chart="seriesChartInput"></div>
     *         </div>
     *     </file>
     *     <file name="index.js">
     *         angular.module("FrontEndWeb").controller("example", function ($scope) {
     *              $scope.seriesChartInput = (function () {
     *                  return {
     *                      configs: {
     *                          chartTitle: "The Chart Title",
     *                          type: "series",
     *                          chartIcon:"money.png",
     *                          borderStyle:"2px solid #e5e5e5",
     *                          legendTitles:{
     *                              identifier:"followup.deliveryCost.identifier",
     *                              tripQuantity:"followup.deliveryCost.invoiceQuantity",
     *                              "followup.choosable.weightVolume":"followup.deliveryCost.choosable.value"
     *                          },
     *                          dataFormats: {
     *                              "chave": "PERCENTAGE"
     *                          },
     *                          properties:{
     *                              group:"4",
     *                              rank:"8",
     *                              totalizer:"tripQuantity",
     *                              presentationFormat:"(tripQuantity) followup.choosable.weightVolume"
     *                          }
     *                      },
     *                      data: [
     *                      {
     *                          elements:[
     *                          {
     *                              value: 58.12,
     *                              alias: "label1",
     *                              javaType: "java.lang.Integer"
     *                          },
     *                          {
     *                              value: "end",
     *                              alias: "label2",
     *                              javaType: "java.lang.Double"
     *                          }]
     *                      }]
     *                  }
     *              }());
     *         });
     *     </file>
     * </example>
     */
    chartingModule.directive("seriesChart", ["$translate", "chartingService", function ($translate, chartingService) {
        return {
            restrict: "A",
            scope: {
                context: "=seriesChart"
            },
            template: template,
            controller: ["$scope", "$window", "$element", "$filter", "entityFormatFilter", function ($scope, $window, $element, $filter) {
                $scope.chartIcon = iconPath($scope.context.configs.chartIcon);
                $scope.chartTitle =$translate.instant($scope.context.configs.chartTitle);
                var properties = $scope.context.configs.properties;

                function iconPath(chartIcon) {
                    if (!chartIcon || chartIcon === "NONE") {
                        return null;
                    }
                    return "images/charts/" + chartIcon;
                }

                $scope.gridClass = function () {
                    var col = Math.floor(12 / properties.group);
                    if (col < 1) {
                        col = 1;
                    }
                    if (col > 12) {
                        col = 12;
                    }
                    return "col-md-" + col;
                };

                $scope.$watch(function () {
                    return $scope.context;
                }, function (newContext) {
                    setSeriesData(newContext);
                }, true);


                function setSeriesData(newContext) {
                    var totalizerKey = properties.totalizer;
                    $scope.totalizerKey = "charting.nameLabel.seriesChart." + totalizerKey;
                    $scope.choosableLabel = $translate.instant(getChoosableLabel(newContext.data));
                    var rank = Number(properties.rank);
                    $scope.total = 0;
                    var groups = [];
                    var rawData = chartingService.rankingMultivaluedData(newContext.data, rank);
                    rawData.forEach(function (data) {
                        var newData = angular.copy(data);
                        if (totalizerKey) {
                            totalizate(newData, totalizerKey);
                        }
                        if ($scope.context.configs.dataFormats) {
                            formatData(newData);
                        }
                        setData(newData, groups);
                    });
                    fillGroupWithEmptyObjects(rank, groups);
                    $scope.groups = groups;
                }

                function setData(data, groups) {
                    data.presentationFormat = convertPresentationFormat(data);
                    groups.push(data);
                }

                function convertPresentationFormat(data) {
                    var newPresentation = properties.presentationFormat;
                    Object.keys(data).forEach(function (key) {
                        var value = data[key];
                        newPresentation = newPresentation.replaceAll(key, value);
                    });
                    return newPresentation;
                }

                function totalizate(data, totalizerKey) {
                    if (data[totalizerKey]) {
                        $scope.total += data[totalizerKey];
                    }
                }

                function fillGroupWithEmptyObjects(rank, groups) {
                    for (var i = 0; i <= rank; i++) {
                        if (!groups[i]) {
                            groups[i] = {};
                        }
                    }
                }

                function getChoosableLabel(data) {
                    if(!properties.displayChoosableLabel){
                        return "";
                    }
                    var choosables = data.choosables;
                    var choosableInfo = data.choosableInfo;
                    if (!choosables && !choosableInfo) {
                        return;
                    }
                    if (!choosables) {
                        return getLabelByChoosable(choosableInfo[0].defaultOptionAlias);
                    }
                    return getLabelByChoosable(Object.values(choosables)[0]);
                }

                function getLabelByChoosable(choosable) {
                    switch (choosable) {
                        case "weight":
                            return "charting.nameLabel.seriesChart.choosableValue.weight";
                        case "volume":
                            return "charting.nameLabel.seriesChart.choosableValue.volume";
                        default:
                            return "";
                    }
                }

                function formatData(data) {
                    Object.keys($scope.context.configs.dataFormats).forEach(function (formatKey) {
                        if (data[formatKey]) {
                            data[formatKey] = applyFormat($scope.context.configs.dataFormats[formatKey], data[formatKey]);
                        }
                    });
                    return data;
                }

                function applyFormat(targetType, element) {
                    if (isOfType("PERCENTAGE")) {
                        return $filter("percentage")(element, 2);
                    }
                    if (isOfType("NUMBER")) {
                        return $filter("decimal")(element, 2);
                    }
                    if (isOfType("REAL")) {
                        return $filter("currency")(element);
                    }
                    return element;

                    function isOfType(type) {
                        return targetType === type;
                    }
                }
            }]
        };
    }]);
});
