define(["./nlgPortletModule"
], function (nlgPortletModule) {
    "use strict";

    return nlgPortletModule.directive("nlgPortletGroup", function () {
        return {
            scope: true,
            controller: [function PortletGroupController() {
                var children = [];
                this.register = function (child) {
                    children.push(child);
                };

                this.focusPortlet = function (portlet) {
                    children.forEach(function (child) {
                        child.setCollapsed(portlet !== child);
                    });
                };
            }]
        };
    });
});



