define(["./filterModule"], function (filterModule) {
    "use strict";

    var state = {},
        exists = state.hasOwnProperty;

    function FilterState() {
        this.name = "";
        this.defaultFilter = false;
        this.patch = [];
        this.filtered = false;
    }

    FilterState.prototype.hasValues = function () {
        return this.patch.length > 0;
    };

    return filterModule.service("filterState", [function () {
        return {
            for: function (serviceName) {
                if (!exists.call(state, serviceName)) {
                    state[serviceName] = new FilterState();
                }
                return state[serviceName];
            },
            update: function(serviceName, savedState){
                state[serviceName] = savedState;
             },
            reset: function(){
                state = {};
            }
        };
    }]);
});