define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    return templateFactoryModule.service("templateFactoryDateTimeIntervalTemplate", [function () {
        return function (config) {
            var element = angular.element("<date-time-interval>");
            config.model && element.attr("ng-model", config.model);
            config.required && element.attr("required", config.required);
            config.defaultLowerBound && element.attr("default-lower-bound", config.defaultLowerBound);
            config.defaultUpperBound && element.attr("default-upper-bound", config.defaultUpperBound);
            return element;
        };
    }]);
});