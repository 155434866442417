define([
    "../nlgHealthCheckerModule"
], function (nlgHealthCheckerModule) {
    "use strict";

    return nlgHealthCheckerModule.service("rangeCheckerHistoryGraph", [
        "numberFilter",
        function (numberFilter) {
            return {
                canHandle: function (reasonReading) {
                    return reasonReading.type === "NumberRangeReason"
                        || reasonReading.type === "FreePercentageRangeReason"
                        || reasonReading.type === "UsedPercentageRangeReason";
                },
                create: function (reasonReading) {
                    var _isPercentage = isPercentage(reasonReading);
                    return {
                        configureData: function (defaultConfiguration, datas) {
                            defaultConfiguration.json = datas.map(function (x) {
                                return {
                                    timestamp: x.timestamp,
                                    value: x.value.value,
                                    "healthcheck.reasonType.PercentageRangeReason.warningTooltip": x.value.warningValue,
                                    "healthcheck.reasonType.PercentageRangeReason.errorTooltip": x.value.errorValue,
                                };
                            });
                            defaultConfiguration.keys.value = [
                                "value",
                                "healthcheck.reasonType.PercentageRangeReason.warningTooltip",
                                "healthcheck.reasonType.PercentageRangeReason.errorTooltip"
                            ];
                            return defaultConfiguration;
                        },
                        configureChart: function (defaultConfiguration) {
                            defaultConfiguration.axis.y = {
                                padding: 0,
                                min: _isPercentage ? 0 : undefined,
                                max: _isPercentage ? 100 : undefined,
                                tick: {
                                    format: function (x) {
                                        return numberFilter(x, 2);
                                    }
                                },
                            };
                            defaultConfiguration.color = {
                                pattern: ["#363FBC", "#ff7f0e", "#B73540"]
                            };
                            return defaultConfiguration;
                        }
                    };
                }
            };

            function isPercentage(reasonReading) {
                return reasonReading.type.indexOf("Percentage") !== -1;
            }
        }]);
});
