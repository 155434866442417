define([
    "./nlgAnalysisTreeModule",
    "text!./nlgAnalysisTreeSelectedSummaryProperties.html",
    "../arrays/arrays",
    "angular"
], function (nlgAnalysisTreeModule, template) {
    "use strict";

    return nlgAnalysisTreeModule.directive("nlgAnalysisTreeSelectedSummaryProperties", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                node: "=",
                properties: "=",
            },
            controller: ["$scope", function ($scope) {
                $scope.style = "width:25%;";
            }]
        };
    }]);
});