define([
    "./nlgAnalysisTreeModule",
    "./nlgAnalysisTreeDirective",
    "./nlgAnalysisTreeProperties",
    "./nlgAnalysisTreeProperty",
    "./nlgAnalysisTreeSelectedSummaryProperties",
    "./nlgAnalysisTreeSelectedSummaryProperty",
    "./nlgAnalysisTreeAdditionalInfo",
    "./nodePropertyFormatter"
], function () {
});