define(["angular", "./entityDTOModule", "../arrays/arrays"], function (angular, entityDTOModule, arrays) {
    "use strict";

    /**
     * @ngdoc filter
     * @name filters.filter:sourceIdFilter
     * @function
     * @param {*} entity Entidade ou Array de Entidades à serem representadas por sourceId
     * @return {string} sourceId da entidade.
     * @description
     * Filtro baseado em entityFormat para retornar uma representação por sourceId, dado uma entidade ou um array de entidades.
     */
    return entityDTOModule.filter("sourceIdFilter", [function () {
        return function (entities) {
            if (angular.isArray(entities)) {
                return arrays.map(entities, formatSingleEntity).join(", ");
            }
            return formatSingleEntity(entities);
        };

        function formatSingleEntity(entity) {
            if (entity === null || angular.isUndefined(entity)) {
                return "";
            }
            var sourceId = entity.sourceId;
            if (!sourceId && isEntity(entity)) {
                return "";
            }
            return sourceId;
        }

        function isEntity(entity) {
            return angular.isDefined(entity.sourceId);
        }
    }]);
});