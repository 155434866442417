define(["./threeModule", "three"], function (threeModule, THREE) {
    "use strict";

    var DEGREES_90 = Math.PI / 2;

    return threeModule.service("threeTubeMeshFactory", ["colorOfObject3D", "threeDrumMeshFactory", function (colorOfObject3D, threeDrumMeshFactory) {
        var self = this;

        self.createMesh = function (object) {
            var dimensions = object.dimensions;
            var outerRadius = dimensions.width / 2;
            var innerRadius = dimensions.height / 2;
            var depth = dimensions.length;
            var geometry = getGeometryFromCache(outerRadius, innerRadius, depth);
            if(!geometry){
                return threeDrumMeshFactory.createMesh(object);
            }
            var material = new THREE.MeshPhongMaterial({
                color: colorOfObject3D(object),
                specular: 0x999999,
                shininess: 7,
                side: THREE.DoubleSide
            });

            return rotateMesh(new THREE.Mesh(geometry, material), object.rotation);
        };

        function rotateMesh(mesh, rotation) {
            // Tubo a partir de revolução na CLA está Standing, mas no CPL é Straight
            if(rotation.name === "LWH" || rotation.name === "LHW"){
                mesh.rotation.z += DEGREES_90;
            } else if(rotation.name === "WLH" || rotation.name === "HLW"){
                mesh.rotation.x += DEGREES_90;
            }
            return mesh;
        }

        var cache = {};
        function getGeometryFromCache(outerRadius, innerRadius, depth) {
            var cacheKey = outerRadius + "/" + innerRadius + "/" + depth;
            if (!cache[cacheKey]) {
                cache[cacheKey] = createTubeGeometry(outerRadius, innerRadius, depth);
            }
            return cache[cacheKey];
        }

        function createTubeGeometry(outerRadius, innerRadius, depth){
            if(innerRadius <= 0){
                return null;
            }
            var halfDepth = depth/2;
            // Cria retângulo para fazer revolução no eizo Z,
            // é importante que o retângulo seja construído tal que o "centro" do objeto 3D esteja de fato no centro do tubo
            var rectangle = [
                new THREE.Vector2(outerRadius, halfDepth),
                new THREE.Vector2(innerRadius, halfDepth),
                new THREE.Vector2(innerRadius, -halfDepth),
                new THREE.Vector2(outerRadius, -halfDepth)
            ];
            // fecha o retângulo, "replicando" o primeiro ponto
            rectangle.push(rectangle[0]);
            return new THREE.LatheGeometry(rectangle, 32);
        }

        return self;
    }]);
});