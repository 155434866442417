define([
    "./nlgTableauVizModule",
    "text!./nlgTableauVizDirective.html",
    "tableau"
], function (nlgTableauVizModule, template, tableau) {
    "use strict";

    nlgTableauVizModule.directive("nlgTableauVizDirective", ["tableauServiceUrl", "remoteExceptionHandler", "loadingService",
        function (tableauServiceUrl, remoteExceptionHandler, loadingService) {
            return {
                restrict: "A",
                template: template,
                replace: true,
                controller: ["$scope", "$element", "$interval", function ($scope, $element, $interval) {
                    /*
                     * Recarrega o dashboard a cada 3h para garantir que a sessão de trusted authentication não expire.
                     */
                    $interval(function () {
                        $scope.dashboardChanged();
                    }, 1000 * 60 * 60 * 3);

                    $scope.dashboards = [];
                    $scope.currentDashboard = null;
                    var viz = null;

                    $scope.dashboardChanged = function () {
                        if (!$scope.currentDashboard) {
                            return;
                        }
                        var options = {
                            hideTabs: false,
                            hideToolbar: false,
                            width: "100%",
                            height: "900px"
                        };
                        if (viz) {
                            viz.dispose();
                        }
                        tableauServiceUrl.getViewUrl($scope.currentDashboard).then(function (viewUrl) {
                            viz = new tableau.Viz($element[0], viewUrl, options);
                        });
                    };

                    loadingService(tableauServiceUrl.getDashboards()
                        .then(function (dashboards) {
                            $scope.dashboards = dashboards.data;
                        }, remoteExceptionHandler()));
                }]
            };
        }]);
});