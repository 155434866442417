define(["./mapsModule"], function (mapsModule) {
    "use strict";

    return mapsModule.directive("nlgMapStyle", ["uiGmapGoogleMapApi", "uiGmapIsReady", "$translate", function (uiGmapGoogleMapApi, uiGmapIsReady, $translate) {
        return {
            restrict: "A",
            require: "uiGmapGoogleMap",
            link: function () {
                uiGmapGoogleMapApi.then(function (mapApi) {
                    return mapApi;
                }).then(function (mapApi) {
                    uiGmapIsReady.promise(1).then(function (maps) {
                        maps.forEach(function (mapInstance) {
                            var map = mapInstance.map;
                            var styledMap = new mapApi.StyledMapType(getDefaultMapStyles(), {name: $translate.instant("neolog.map.simple.type")});
                            map.mapTypes.set("map_style", styledMap);
                            map.setMapTypeId("map_style");
                        });
                    });
                });

                function getDefaultMapStyles() {
                    return [
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                                {"color": "#ffffff"},
                                {"visibility": "simplified"}
                            ]
                        }, {
                            "featureType": "transit",
                            "stylers": [
                                {"visibility": "off"}
                            ]
                        }, {
                            "featureType": "road",
                            "elementType": "labels",
                            "stylers": [
                                {"visibility": "off"}
                            ]
                        }, {
                            "featureType": "landscape",
                            "stylers": [
                                {"saturation": -66}
                            ]
                        }, {
                            "featureType": "poi",
                            "elementType": "labels",
                            "stylers": [
                                {"visibility": "off"}
                            ]
                        }
                    ];
                }
            }
        };
    }]);
});