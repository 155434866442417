define([
    "angular",
    "angular-ui-router",
    "angular-translate"
], function (angular) {
    "use strict";

    return angular.module("nlgPageTitleModule", [
        "ui.router",
        "pascalprecht.translate"
    ]);
});