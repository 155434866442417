define(["./dashboardModule"], function (dashboardModule) {
    "use strict";

    return dashboardModule.service("spreadsheetServiceInvoker", [function () {
        var spreadsheetService;
        return {
            exportData: function (sheetName, dataToExport, spreadsheetStyle) {
                if (!spreadsheetService) {
                    throw new Error("Service for cancellation was not injected.");
                }
                return spreadsheetService.exportData(sheetName, dataToExport, spreadsheetStyle);
            },
            setService: function (service) {
                spreadsheetService = service;
            }
        };
    }]);
});

