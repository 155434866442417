define([
    "../nlgHealthCheckerModule",
    "text!./nlgHealthCheckerHistory.html",
], function (nlgHealthCheckerModule, template) {
    "use strict";

    return nlgHealthCheckerModule.directive("nlgHealthCheckerHistory", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                data: "<"
            }
        };
    }]);
});
