define([
    "./nlgAnalysisTreeDirective",
    "text!./nlgAnalysisTreeProperty.html"
], function (nlgAnalysisTree, template) {
    "use strict";

    return nlgAnalysisTree.directive("nlgAnalysisTreeProperty", [function () {
        return {
            restrict: "A",
            template: template,
            scope: {
                node: "=",
                property: "="
            },
            controller: ["$scope", "nodePropertyFormatter", function ($scope, nodePropertyFormatter) {
                var property = $scope.property;
                $scope.propertyValue = nodePropertyFormatter(property);
                $scope.propertyType = property.type;
            }]
        };
    }]);
});