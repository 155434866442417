define(["./functioningEditionModule",
    "../arrays/arrays",
    "text!./nlgWorkingHoursEdition.html",
    "angular"
], function (functioningEditionModule, arrays, template, angular) {
    "use strict";

    functioningEditionModule.directive("nlgWorkingHoursEdition", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                workingHours: "=",
                workingHoursAllowed: "=",
                ngDisabled: "=?",
                isFieldOptional: "@?",
                hasPreDefinedWorkingHours: "=?",
                preDefinedWorkingHours: "=?"
            },
            link: function ($scope) {

                var cachedWorkingHours = $scope.workingHours;

                $scope.model = {
                    selectedPreDefinedWorkingHours: null
                };

                $scope.onChangeWorkingHours = function () {
                    if (!$scope.model.selectedPreDefinedWorkingHours) {
                        $scope.workingHours = cachedWorkingHours;
                        return;
                    }
                    $scope.workingHours = angular.copy($scope.model.selectedPreDefinedWorkingHours.workingHours);
                };
            },
            controller: ["$scope", function ($scope) {
                $scope.$watch("workingHours", function (newWorkingHours) {
                    $scope.allowedTimesTableOptions.records = newWorkingHours;
                    $scope.model.selectedPreDefinedWorkingHours = null;
                });

                $scope.allowedTimesTableOptions = {
                    records: $scope.workingHours,
                    columns: [{
                        header: {
                            template: "<a name='add' class='btn btn-xs green' style='margin-left: 5px;' ng-click='addAllowedTime()'><span class='fa fa-plus'></span></a>"
                        },
                        cell: {
                            template: "<a name='remove' class='btn btn-xs red' style='margin-left: 5px; margin-top: 4px;' ng-click='removeAllowedTime(record)'><span class='fa fa-trash'></span></a>"
                        },
                        width: "2%",
                        cellName: "buttons"
                    }, {
                        header: "edition.functioning.dayOnWeek",
                        cell: {
                            type: "singleTagInput",
                            model: "record.weekDay",
                            getTags: "weekDays",
                            ngDisabled: "!workingHoursAllowed || ngDisabled",
                            comparator: "weekDayComparator(left, right)"
                        },
                        width: "33%",
                        cellName: "dayOnWeek"
                    }, {
                        header: "edition.functioning.startTime",
                        cell: {
                            type: "time",
                            model: "record.startTime"
                        },
                        width: "10%",
                        cellName: "startTime"
                    }, {
                        header: "edition.functioning.endTime",
                        cell: {
                            type: "time",
                            model: "record.endTime"
                        },
                        width: "10%",
                        cellName: "endTime"
                    }]
                };

                $scope.addAllowedTime = function () {
                    $scope.workingHours.push({});
                };
                $scope.removeAllowedTime = function (time) {
                    arrays.removeAt($scope.allowedTimesTableOptions.records, $scope.allowedTimesTableOptions.records.indexOf(time));
                };

                $scope.weekDays = [
                    "br.com.neolog.time.WeekDay.SUNDAY",
                    "br.com.neolog.time.WeekDay.MONDAY",
                    "br.com.neolog.time.WeekDay.TUESDAY",
                    "br.com.neolog.time.WeekDay.WEDNESDAY",
                    "br.com.neolog.time.WeekDay.THURSDAY",
                    "br.com.neolog.time.WeekDay.FRIDAY",
                    "br.com.neolog.time.WeekDay.SATURDAY"
                ];

                $scope.weekDayComparator = function (day1, day2) {
                    return $scope.weekDays.indexOf(day1) - $scope.weekDays.indexOf(day2);
                };
            }]
        };
    }]);
});
