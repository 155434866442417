define([
    "../nlgHealthCheckerModule",
    "./durationCheckerHistoryGraph",
    "./numberCheckerHistoryGraph",
    "./pieCheckerHistoryGraph",
    "./rangeCheckerHistoryGraph",
], function (nlgHealthCheckerModule) {
    "use strict";

    return nlgHealthCheckerModule.service("healthCheckerHistoryGraphFactory", [
        "durationCheckerHistoryGraph",
        "numberCheckerHistoryGraph",
        "pieCheckerHistoryGraph",
        "rangeCheckerHistoryGraph",
        function (
            durationCheckerHistoryGraph,
            numberCheckerHistoryGraph,
            pieCheckerHistoryGraph,
            rangeCheckerHistoryGraph) {
            var graphs = [
                durationCheckerHistoryGraph,
                numberCheckerHistoryGraph,
                pieCheckerHistoryGraph,
                rangeCheckerHistoryGraph
            ];
            return {
                createFor: function (reasonReading) {
                    var graph = graphs.find(function (graph) {
                        return graph.canHandle(reasonReading);
                    });
                    if (!graph) {
                        return null;
                    }
                    return graph.create(reasonReading);
                }
            };
        }]);
});
