define([
    "./dashboardModule"
], function (dashboardModule) {
    "use strict";
    /**
     * @ngdoc service
     * @name dashboardModule.dashboardService
     * @description
     * Serviço para tratar fluxos de informações e consultas acerca de Dashboards.
     **/
    dashboardModule.service("dashboardServiceFactory", ["$http", "dashboardDomain", function ($http, dashboardDomain) {

        return function (domain) {
            var basePath = dashboardDomain.getBasePath(domain);
            var server = dashboardDomain.getDashboardPaths(domain);

            /**
             * @ngdoc service
             * @name dashboardModule.dashboardService#info
             * @methodOf dashboardModule.dashboardService
             * @description
             * Obtém informações de gráficos, e propriedades acerca do dashboard com sourceId informado.
             * @returns {Promise<Object>} promessa que retorna objeto contendo as informações supracitadas para cada gráfico,
             * e informações de cada filtro do dashboard.
             **/
            function info(dashboardSourceId) {
                return $http.post(basePath + server.infoUrl, {
                    dashboardSourceId: dashboardSourceId
                }).then(getData);
            }

            /**
             * @ngdoc service
             * @name dashboardModule.dashboardService#query
             * @methodOf dashboardModule.dashboardService
             * @description
             * Invoca o serviço remoto de consulta ao dashboard com sourceId informado, aplicando os filtros passados.
             * @returns {Promise<Object>} promessa que retorna array contendo resultado de filtro para cada gráfico contido no dashboard.
             **/
            function query(dashboardSourceId, filterConfigs, choosables) {
                return $http.post(basePath + server.queryUrl, {
                    dashboardSourceId: dashboardSourceId,
                    filters: filterConfigs,
                    choosables: choosables
                }).then(getData);
            }

            function exportChart(dashboardSourceId, chartSourceId, filterConfigs, firstResult, maxResults ) {
                return $http.post(basePath + server.exportChartUrl,  {
                    dashboardSourceId: dashboardSourceId,
                    chartSourceId: chartSourceId,
                    filters: filterConfigs,
                    firstResult: firstResult,
                    maxResults: maxResults
                }).then(getData);
            }

            function countExport(dashboardSourceId, chartSourceId, filterConfigs, firstResult, maxResults) {
                return $http.post(basePath + server.countExportUrl,  {
                    dashboardSourceId: dashboardSourceId,
                    chartSourceId: chartSourceId,
                    filters: filterConfigs,
                    firstResult: firstResult,
                    maxResults: maxResults
                }).then(getData);
            }


            function getData(response) {
                return response.data;
            }

            return {
                info: info,
                query: query,
                exportChart : exportChart,
                countExport : countExport
            };
        };
    }]);
});