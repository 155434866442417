define(["angular", "./entityDTOModule", "../arrays/arrays"], function (angular, entityDTOModule, arrays) {
    "use strict";

    /**
     * @ngdoc filter
     * @name filters.filter:entityFilter
     * @function
     * @param {*} entity Entidade ou Array de Entidades à serem representadas como string.
     * @param {string} viewValue String que será feito o match com traduções de cada elemento de entity.
     * @return {string} representação da entidade.
     * @description
     * Filtro para retornar representação em string dado uma ou mais entidade.
     * Dado uma entidade é feita a tradução dessa entidade e em seguida é feita uma comparação com o parâmetro viewValue.
     * Esta comparação não leva em conta todas propriedades do objeto, apenas a representação em `string` desta entidade
     * gerada pelo filtro `entityFormat`.
     */
    //Filtro feito para solucionar problema de filtro em dualLists que usam Enuns.
    return entityDTOModule.filter("entityFilter", ["entityFormatFilter", function (entityFormatFilter) {
        return function (entities, viewValue) {
            if (angular.isUndefined(viewValue)) {
                return entities;
            }
            return arrays.filter(entities, function (entity) {
                var translated = entityFormatFilter(entity);
                return translated.toLowerCase().indexOf(viewValue.toLowerCase()) >= 0;
            });
        };
    }]);
});