define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    /**
     * @ngdoc object
     * @name templateFactoryDateTemplate.class:TemplateFactoryConfiguration
     * @property {*} model
     * Model que será afetado quando houverem alterações no `datepicker` associado
     **/

    /**
     * @ngdoc function
     * @name templateFactoryDateTemplate.templateFactoryDateTemplate
     * @description
     * Template utilizado para criar um `input` do tipo `date`.
     *
     * @param {TemplateFactoryConfiguration} config Configuração para preencher o template.
     * @returns {jquery} Template preenchido.
     **/
    return templateFactoryModule.service("templateFactoryDateTemplate", [function () {
        return function (config) {
            var element = angular.element("<date-picker>");
            if (config.model) {
                element.attr("ng-model", config.model);
            }
            return element;
        };
    }]);
});