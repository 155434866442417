define(["./collectionsModule", "../arrays/arrays"], function (collectionsModule, arrays) {
    "use strict";

    /**
     * @typedef {collectionsModule.Set|*[]} iterable
     * */

    /**
     * @ngdoc service
     * @name collectionsModule.Iterables
     * @description
     * Utilitários para iteráveis.
     * */
    return collectionsModule.service("Iterables", [function () {
        var self = {};

        /**
         * @ngdoc service
         * @name collectionsModule.Iterables#iterate
         * @methodOf collectionsModule.Iterables
         * @description
         * Itera sobre uma coleção, ou sobre um array.
         * */
        self.iterate = function iterate(iterable, callback) {
            if (iterable.each) {
                return iterable.each(callback);
            }
            return arrays.each(iterable, callback);
        };

        return self;
    }]);
});