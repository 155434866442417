define([
    "./monitorModule",
    "text!./nlgMonitorRequestEnv.html"
], function (module, template) {
    "use strict";

    return module.directive("nlgMonitorRequestEnv", [function () {
        return {
            scope: {
                instance: "<"
            },
            template: template,
            controller: [
                "$scope",
                "monitorService",
                "loadingService",
                "remoteExceptionHandler",
                "nlgMonitorRequestEnvV1Service",
                "nlgMonitorRequestEnvV2Service",
                function ($scope, monitorService, loadingService, remoteExceptionHandler, NlgMonitorRequestEnvV1Service, NlgMonitorRequestEnvV2Service) {
                    var envService = null;

                    $scope.env = {};
                    $scope.newProperty = {name: null, value: null};

                    loadEndpoint();

                    function loadEndpoint() {
                        return monitorService.findEndpoint($scope.instance, "env")
                            .catch(remoteExceptionHandler())
                            .then(function (data) {
                                if (!data.propertySources) {
                                    envService = new NlgMonitorRequestEnvV1Service($scope.instance);
                                } else {
                                    envService = new NlgMonitorRequestEnvV2Service($scope.instance);
                                }
                                $scope.env = envService.createEnv(data);
                            });
                    }

                    $scope.computeValue = function (property) {
                        return envService.computeValue(property);
                    };

                    $scope.updateValue = function (property, newValue) {
                        return envService.updateValue(property, newValue);
                    };

                    $scope.createNewProperty = function () {
                        return envService.createNewProperty($scope.newProperty.name, $scope.newProperty.value)
                            .then(function () {
                                return loadingService(loadEndpoint());
                            })
                            .then(function () {
                                $scope.newProperty.name = null;
                                $scope.newProperty.value = null;
                            });
                    };
                }]
        };
    }]);
});