define(["./functioningEditionModule",
    "../arrays/arrays",
    "text!./functioningEdition.html"
], function (functioningEditionModule, arrays, template) {
    "use strict";

    functioningEditionModule.directive("functioningEdition", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                functioningDataHolder: "=",
                ngDisabled: "=?",
                isFieldOptional: "@?",
                hasPreDefinedWorkingHours: "=?",
                preDefinedWorkingHours: "=?"
            }
        };
    }]);
});

