define([
    "angular",
    "./tripPickingListModule"
], function (angular, tripPickingListModule) {
    "use strict";
    tripPickingListModule.provider("tripPickingListServiceUrl", [function () {

        var tripPickingListUrlForNodeIdentifier;

        this.$get = [function () {
            return {
                getTripPickingListUrlForNodeIdentifier: getTripPickingListUrlForNodeIdentifier
            };
        }];

        function getTripPickingListUrlForNodeIdentifier() {
            return tripPickingListUrlForNodeIdentifier;
        }

        this.configure = function (server) {
            tripPickingListUrlForNodeIdentifier = server.tripPickingListUrlForNodeIdentifier;
        };
    }]);
});

