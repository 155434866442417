define([
    "angular",
    "./viewTypeTable/viewTypeTable",
    "./viewTypeTableOverload/viewTypeTableOverload",
    "../form/form",
    "../collections/collections",
    "../address/address",
    "../operation/operation",
    "../remote/remote",
    "../colorpicker/colorpicker",
    "../entityDTO/entityDTO",
    "../three/three"
], function (angular) {
    "use strict";

    /**
     * @ngdoc overview
     * @name arrangementModule
     * @description
     * Módulo com funcionalidades relativas ao arranjo de cargas.
     * */
    return angular.module("arrangementModule", [
        "viewTypeTableTemplates",
        "viewTypeTableOverloadTemplates",
        "threeModule",
        "formModule",
        "collectionsModule",
        "addressModule",
        "commonsOperationModule",
        "remoteModule",
        "colorpickerModule",
        "entityDTOModule"
    ]);
});