define([
    "angular",
    "../remote/remote",
    "../sorting/sorting",
    "../nlgTagInput/nlgTagInput",
    "../nlgNullableCheckbox/nlgNullableCheckbox",
    "angular-input-masks"]

    , function (angular) {
        "use strict";

    return angular.module("filterModule", [
        "remoteModule",
        "sortingModule",
        "entityDTOModule",
        "nlgTagInputModule",
        "nlgNullableCheckboxModule",
        "ui.utils.masks"
    ]);
});