define([
    "./nlgHealthCheckerModule",
    "text!./nlgHealthCheckerQuickFixActions.html",
], function (module, template) {
    "use strict";

    return module.directive("nlgHealthCheckerQuickFixActions", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                instance: "<",
                checker: "<",
                onFixSuccess: "&?"
            },
            controller: ["$scope", "nlgHealthCheckerService", "loadingService", "remoteExceptionHandler", "messagesModal", function ($scope, nlgHealthCheckerService, loadingService, remoteExceptionHandler, messagesModal) {
                $scope.actions = [];

                init();

                function init() {
                    return loadingService(nlgHealthCheckerService.getFixActions($scope.instance, [$scope.checker]))
                        .catch(remoteExceptionHandler())
                        .then(function (actions) {
                            $scope.actions = actions;
                        });
                }

                $scope.executeAction = function (action) {
                    return messagesModal.cancellable("dialog.warning", "healthcheck.quickfix.confirm")
                        .then(function () {
                            return loadingService(nlgHealthCheckerService.fix($scope.instance, [$scope.checker], action))
                                .catch(remoteExceptionHandler());
                        })
                        .then(function () {
                            return messagesModal("dialog.success", "healthcheck.quickfix.success");
                        })
                        .then($scope.onFixSuccess);
                };
            }]
        };
    }]);
});
