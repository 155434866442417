define([
    "./classifiableModule",
    "text!./classifiableEdition.html",
    "../arrays/arrays"
], function (classifiableModule, template, arrays) {
    "use strict";

    classifiableModule.directive("classifiableEdition", ["classifiableService", function (classifiableService) {
        return {
            restrict: "E",
            template: template,
            scope: {
                classificationByType: "=",
                ngDisabled: "=?",
                allowAutoSelect : "=?"
            },
            controller: ["$scope", function ($scope) {
                $scope.classifiableTableOptions = {
                    records: $scope.classificationByType,
                    columns: [{
                        header: "edition.classification.type",
                        cell: "{{ record.key | entityFormat }}",
                        width: "30%",
                        cellName: "type"
                    }, {
                        header: "edition.classification.classification",
                        cell: {
                            type: "singleTagInput",
                            model: "record.value",
                            getTags: "classifiableGetTags(record.key, viewValue)",
                            disabled: "ngDisabled || record.autoSelect"
                        },
                        width: "70%",
                        cellName: "classification"
                    }]
                };

                //adiciona coluna para auto-select
                if($scope.allowAutoSelect===true){
                    arrays.each($scope.classifiableTableOptions.records, function(record){
                        record.autoSelect = false;
                    });
                    $scope.classifiableTableOptions.columns.push({
                        header: "edition.classification.autoSelect",
                        cell: {
                            type: "checkbox",
                            model: "record.autoSelect",
                            disabled: "ngDisabled",
                            onChange: "onCheckboxClicked(record)"
                        },
                        cellName: "autoSelect",
                        width: "20px"
                    });
                }

                $scope.onCheckboxClicked = function(record){
                    if(record.autoSelect===true){
                        record.value = null;
                    }
                };

                $scope.classifiableGetTags = function (classificationType, viewValue) {
                    return classifiableService.getClassifications(classificationType, viewValue);
                };
            }]
        };
    }]);
});