define(["./arrangementModule", "../arrays/arrays"], function (arrangementModule, arrays) {
    "use strict";

    return arrangementModule.service("arrangementViewType", [function () {
        return {
            DELIVERY_UNIT: instantiate("DELIVERY_UNIT", "deliveryUnit"),
            ORDER_ITEM: instantiate("ORDER_ITEM", "orderItems"),
            ORDER: instantiate("ORDER", "orders"),
            ORDER_PRODUCT: instantiate("ORDER_PRODUCT", ["orders", "products"]),
            PRODUCT: instantiate("PRODUCT", "products"),
            PRODUCT_CATEGORY: instantiate("PRODUCT_CATEGORY", "productCategories"),
            SHIPMENT_UNIT_WRAPPER: instantiate("SHIPMENT_UNIT_WRAPPER", "shipmentUnitWrapper"),
            SHIPMENT_UNIT_WRAPPER_CATEGORY: instantiate("SHIPMENT_UNIT_WRAPPER_CATEGORY", "shipmentUnitWrapperCategory"),
            LOADING_STOP: instantiate("LOADING_STOP", "loadingStop"),
            UNLOADING_STOP: instantiate("UNLOADING_STOP", "unloadingStop"),
            LOADING_STOP_ORDER_PRODUCT: instantiate("LOADING_STOP_ORDER_PRODUCT", ["loadingStop", "orders", "products"]),
            UNLOADING_STOP_ORDER_PRODUCT: instantiate("UNLOADING_STOP_ORDER_PRODUCT", ["unloadingStop", "orders", "products"]),
            LOADING_SUBSTOP: instantiate("LOADING_SUBSTOP", "loadingSubStop"),
            UNLOADING_SUBSTOP: instantiate("UNLOADING_SUBSTOP", "unloadingSubStop"),
            VERTICAL_LAYER: instantiate("VERTICAL_LAYER", "verticalLayerIndex"),
            HORIZONTAL_LAYER: instantiate("HORIZONTAL_LAYER", "horizontalLayerIndex")
        };

        function instantiate(name, values) {
            return {
                name: name,
                getValue: function (object) {
                    if (arrays.isArray(values)) {
                        return arrays.map(values, function (value) {
                            return [getValue(object, value)];
                        });
                    }
                    return getValue(object, values);
                }
            };

            function getValue(object, value) {
                return object[value];
            }
        }
    }]);
});