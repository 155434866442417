define([
    "./filterModule"
], function (filterModule) {
    filterModule.factory("filterServiceFactory", ["$http", "filterDomain", function ($http, filterDomain) {

        return function (domain) {
            var basePath = filterDomain.getBasePath(domain) + "/";

            return {
                getFilter: getFilter,
                doFilter: doFilter,
                getEmptyPresentationTree: getEmptyPresentationTree,
                getFilterSnapshots: getFilterSnapshots,
                saveFilterSnapshot: saveFilterSnapshot,
                deleteFilterSnapshot: deleteFilterSnapshot,
                getDefaultFilterSnapshot: getDefaultFilterSnapshot,
                getFilterOperationDescriptions: getFilterOperationDescriptions,
                getPossibleValues: getPossibleValues
            };

            /**
             * @ngdoc method
             * @name filterModule.filterService#getFilter
             * @methodOf filterModule.filterService
             * @description
             * Retorna os atributos de filtro disponíveis para a entidade especificada.
             * @param {String} service path da entidade
             * @returns {HttpPromise<Filter>} Promessa que retorna o filtro.
             */
            function getFilter(service) {
                return $http.get(basePath + sanitize(service) + "/filter");
            }

            /**
             * @ngdoc method
             * @name filterModule.filterService#doFilter
             * @methodOf filterModule.filterService
             * @description
             * Retorna as entidades de um serviço que respeitam o filtro fornecido.
             * O filtro que será passado para essa entidade é um patch com as mudanças realizadas sobre o
             * filtro retornado por {@link filterModule.filterService#getFilter getFilter}.
             *
             * @param {string} service Serviço à ser buscado.
             * @param {jsonpatch} patch Patch do filtro retornado por {@link filterModule.filterService#getFilter getFilter}.
             * @param {object} [params] Objeto com parâmetros adicionais para busca.
             * @returns {HttpPromise<object[]>} Array com as entidades válidas.
             */
            function doFilter(service, patch, params) {
                return $http({
                    url: basePath + sanitize(service) + "/filter",
                    method: "POST",
                    data: patch,
                    params: params || {}
                });
            }

            function getEmptyPresentationTree(service) {
                return $http({
                    url: basePath + sanitize(service) + "/presentation/tree",
                    method: "GET"
                }).then(function (response) {
                    return response.data;
                });
            }

            /**
             * @ngdoc method
             * @name filterModule.filterService#getFilterSnapshots
             * @methodOf filterModule.filterService
             * @description
             * Retorna uma promessa cujo resultado são as configurações de filtros salvas.
             * @param {String} service Serviço para buscar os snapshots.
             * @returns {HttpPromise<FilterSnapshot[]>} Snapshot.
             */
            function getFilterSnapshots(service) {
                return $http({
                    url: basePath + sanitize(service) + "/filter/snapshot",
                    method: "GET"
                });
            }

            /**
             * @ngdoc method
             * @name filterModule.filterService#saveFilterSnapshot
             * @methodOf filterModule.filterService
             * @description
             * Persiste uma configuração de filtro.
             *
             * @param {String} service Serviço para salvar o snapshot.
             * @param {String} snapshotName nome da configuração
             * @param {String|Array} patch lista de patches ou sua representação em texto
             * @returns {HttpPromise} promessa que persiste uma configuração de filtro.
             */
            function saveFilterSnapshot(service, snapshotName, defaultFilter, patch, filterSort) {
                if (typeof snapshotName !== "string" || !(snapshotName || "")) {
                    throw new Error("Filter snapshot name cannot be empty");
                }
                return $http({
                    url: basePath + sanitize(service) + "/filter/saveSnapshot",
                    method: "POST",
                    data: {
                        snapshotName: snapshotName,
                        patch: patch,
                        defaultFilter: defaultFilter,
                        sortingActivated: filterSort.sortingActivated,
                        sortableFields: filterSort.sortableFields
                    }
                });
            }

            /**
             * @ngdoc method
             * @name filterModule.filterService#deleteFilterSnapshot
             * @methodOf filterModule.filterService
             * @description
             * Deleta uma configuração de filtro identificada pelo nome.
             * @param {String} service Serviço para remover o snapshot.
             * @param {String} snapshotName nome da configuração
             * @returns {HttpPromise} promessa de deleção da configuração.
             */
            function deleteFilterSnapshot(service, snapshotId) {
                return $http({
                    url: basePath + sanitize(service) + "/filter/" + snapshotId,
                    method: "DELETE"
                });
            }

            /**
             * @ngdoc method
             * @name filterModule.filterService#getDefaultFilterSnapshot
             * @methodOf filterModule.filterService
             * @description
             * Busca uma configuração default de filtro para o serviço dado
             * @param {String} service Serviço para remover o snapshot.
             * @returns {HttpPromise<FilterSnapshot} Snapshot.
             */
            function getDefaultFilterSnapshot(service) {
                return $http({
                    url: basePath + sanitize(service) + "/filter/defaultSnapshot",
                    method: "GET"
                });
            }

            /**
             * @ngdoc method
             * @name filterModule.filterService#getFilterOperationDescriptions
             * @methodOf filterModule.filterService
             * @description
             * Retorna uma promessa cujo conteúdo descreve as operações de filtro para fins de apresentação.
             * @returns {HttpPromise<FilterOperation[]>} Promesa que retorna as operações de filtro.
             */
            function getFilterOperationDescriptions() {
                return $http.get(basePath + "static/filter/operations");
            }

            /**
             * @ngdoc method
             * @name filterModule.filterService#getPossibleValues
             * @methodOf filterModule.filterService
             * @description
             * Retorna uma promessa de busca de entidades por sourceId, description ou name.
             *
             * @param {String} path link para base de serviços da entidade
             * @param {Object} filterContext object que pode conter <additionalInfo> e <dependencies>
             * @param {String} viewValue valor da busca, não deve ser null nem vazio
             * @returns {Promise<Entity[]>} Promessa que retorna as entidades.
             */
            function getPossibleValues(path, filterContext, viewValue) {
                return $http.post(basePath + sanitize(path) + "/find", filterContext, {params: {value: viewValue}});
            }

            /**
             * Remove "/" no começo e no final do path
             * */
            function sanitize(path) {
                return path
                    .replace(/^\/+/, "")
                    .replace(/\/+$/, "");
            }
        };
    }]);
});