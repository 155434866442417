define([
    "./arrangementModule",
    "../colors/colors",
    "../arrays/arrays",
    "angular"
], function (arrangementModule, colors, arrays, angular) {
    "use strict";

    /**
     * @ngdoc service
     * @name arrangementModule.ArrangementColorGrouper
     * @description
     * Tipo usado para disponibilizar/manter cores de objetos em um arranjo por `arrangementViewType`.
     * */
    return arrangementModule.service("ArrangementColorGrouper", ["Object3DInfo", function (Object3DInfo) {
        function ArrangementColorGrouper() {
            if (!(this instanceof ArrangementColorGrouper)) {
                return new ArrangementColorGrouper();
            }
            this.colorScale = colors.scale20();
        }

        var defaultViewType = {
            name: "DEFAULT_VIEW_TYPE",
            getValue: function (object) {
                return object["defaultColorIdentifier"];
            }
        };

        ArrangementColorGrouper.prototype.getColor = function (object, viewType) {
            viewType = viewType || defaultViewType;
            var color = this.getIdColor(viewType.getValue(getExtraInfo(object)));
            var packIdentifier = doGetExtraInfo(Object3DInfo.PACK_IDENTIFIER, object);
            if (angular.isDefined(packIdentifier)) {
                return colors.getVariation(color, packIdentifier);
            }
            return color;
        };

        ArrangementColorGrouper.prototype.getIdColor = function (id) {
            return this.colorScale(getId(id));
        };

        function getCustomColor(customColors, key) {
            var id = ArrangementColorGrouper.getId(key);
            var customColorToId = arrays.filter(customColors, function (customColor) {
                return angular.equals(customColor.id, id);
            });
            return customColorToId[0] ? customColorToId[0].color : null;
        }

        function getId(obj) {
            if (angular.isUndefined(obj) || obj === null) {
                return "-";
            }
            if (angular.isArray(obj)) {
                return arrays.map(obj, getId);
            }
            return obj.id || obj;
        }

        function getExtraInfo(meshAndObject) {
            var result = doGetExtraInfo(Object3DInfo.DELIVERY_UNIT_DATA, meshAndObject) || {};
            result.verticalLayerIndex = doGetExtraInfo(Object3DInfo.VERTICAL_LAYER_INDEX, meshAndObject);
            result.horizontalLayerIndex = doGetExtraInfo(Object3DInfo.HORIZONTAL_LAYER_INDEX, meshAndObject);
            result.description = doGetExtraInfo(Object3DInfo.DESCRIPTION, meshAndObject);
            result.opacity = doGetExtraInfo(Object3DInfo.OPACITY, meshAndObject);
            return result;
        }

        ArrangementColorGrouper.getExtraInfo = getExtraInfo;

        ArrangementColorGrouper.getId = getId;

        ArrangementColorGrouper.getCustomColor = getCustomColor;


        function doGetExtraInfo(object3dInfo, meshAndObject) {
            return meshAndObject.object.extraInfo[object3dInfo];
        }

        return ArrangementColorGrouper;
    }]);
});