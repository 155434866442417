define([
    "./nlgPageTitleModule",
    "lodash"
], function (module, _) {
    "use strict";

    return module.directive("nlgPageTitle", ["$rootScope", "$interpolate", "$state", function ($rootScope, $interpolate, $state) {
        return {
            restrict: "A",
            scope: false,
            link: function (scope, element, attrs) {
                $rootScope.$on("$stateChangeSuccess", listener);
                $rootScope.$on("$translateChangeEnd", listener);

                function listener() {
                    var currentState = $state.$current;
                    var title = attrs.nlgPageTitle || "Untitled page";
                    var titleElement = attrs.titleElement || "pageTitle";

                    if (_.has(currentState, "data") && _.has(currentState.data, titleElement) && _.isEmpty(currentState.data[titleElement]) === false) {
                        title = currentState.data[titleElement];
                    }

                    if (_.has(currentState, "locals") && _.has(currentState.locals, "globals")) {
                        currentState = currentState.locals.globals;
                    }

                    title = $interpolate(title)(currentState);
                    element.text(title);

                }
            },
        };
    }]);
});