define([
    "./monitorModule",
    "text!./nlgMonitorLogSearchResult.html"
], function (monitorModule, template) {
    "use strict";

    return monitorModule.directive("nlgMonitorLogSearchResult", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                result: "<"
            },
            controllerAs: "ctrl",
            controller: ["$scope", function ($scope) {
                var ctrl = this;
                ctrl.leftPart = function () {
                    return $scope.result.line.substring(0, $scope.result.start);
                };

                ctrl.foundPart = function () {
                    return $scope.result.line.substring($scope.result.start, $scope.result.end);
                };

                ctrl.rightPart = function () {
                    return $scope.result.line.substring($scope.result.end);
                };
            }]
        };
    }]);
});