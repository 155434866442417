define([
    "./regionModule",
    "text!./regionImportModal.html",
    "lodash"
], function (regionModule, template, _) {
    "use strict";

    return regionModule.service("regionImportModal", [
        "$http",
        "$modal",
        "autocompleteFactory",
        "regionServiceUrls",
        "loadingService",
        "$q",
        function ($http, $modal, autocompleteFactory, regionServiceUrls, loadingService, $q) {
            return function () {
                var defer = $q.defer();
                $modal.open({
                    template: template,
                    size: "lg",
                    controller: ["$scope", function ($scope) {
                        $scope.model = {
                            regionGroups: null,
                            regionsAvailable: null,
                            regionsConverted: null,
                            regionsToImport: []
                        };

                        $scope.regionGroups = autocompleteFactory.lazyLoader(regionServiceUrls.regionGroupsUrl);

                        $scope.$watch("model.regionGroups", function (regionGroup) {
                            if (!regionGroup) {
                                $scope.model.regionsToImport = [];
                                return;
                            }
                            loadingService($http.get(regionServiceUrls.regionsByRegionGroupUrlSupplier(regionGroup.id)))
                                .then(function (response) {
                                    $scope.model.regionsToImport = [];
                                    $scope.model.regionsAvailable = response.data;
                                    $scope.model.regionsConverted = $scope.model.regionsAvailable.map(function (region) {
                                        return {
                                            id: region.id,
                                            name: region.name
                                        };
                                    });
                                });
                        });

                        $scope.hasRegionsSelected = function () {
                            return $scope.model.regionsToImport.length !== 0;
                        };

                        $scope.hasRegionGroupSelected = function () {
                            return $scope.model.regionGroups !== null;
                        };

                        $scope.import = function () {
                            var result = [];

                            $scope.model.regionsToImport.forEach(function (regionToImport) {
                                var region = _.find($scope.model.regionsAvailable, function (regionToCompare) {
                                    return regionToCompare.id === regionToImport.id;
                                });
                                if (region) {
                                    result.push(region);
                                }
                            });

                            $scope.$close();
                            defer.resolve(result);
                        };
                    }]
                });
                return defer.promise;
            };
        }]
    );
});