define([
    "./chartingModule",
    "text!./horizontalStackedBarChart.html",
    "d3",
    "c3",
    "angular",
    "../arrays/arrays",
    "lodash",
], function (chartingModule, template, d3, c3, angular, arrays, _) {
    "use strict";

    /**
     * @ngdoc directive
     * @name chartingModule.directive:horizontalStackedBarChart
     * @description
     * Diretiva que exibe um gráfico de barras empilhadas
     *
     * @param {object[]=} horizontalStackedBarChart dados a serem representados no gráfico
     * @param {number} [height] a altura do gráfico.
     * @param {number} [width] a largura do gráfico.
     * @param {boolean} [rotate] se o gráfico deve ser rotacionado. Valor padrão é false.
     * @param {boolean} [proportional] flag para definir o modo de exibição. Se true, as barras do gráfico vão ter o mesmo tamanho. Valor padrão é false.
     * @param {boolean} [absolute] flag para exibição dos valores. Se true mostrará os valores absolutos para determinado identifier, ou seja sem formatação. Valor padrão é false.
     * @param {string} [translate] define a estratégia de tradução de labels. Valores válidos são:
     *     <ul>
     *     <li><i>bundle</i>: utiliza os arquivos de bundle (formato do bundle: 'charting.nameLabel.horizontalStackedBar.<valor>');</li>
     *     <li><i>legend</i>: utiliza as propriedades 'legend' para tradução (formato da legenda: 'label.legend.<valor>');</li>
     *     </ul>
     *
     * @example
     * <example module="FrontEndWeb">
     *     <file name="index.html">
     *         <div ng-controller="example">
     *            <div horizontal-stacked-bar-chart="horizontalStackedBarChartInput"></div>
     *
     *            <div horizontal-stacked-bar-chart="horizontalStackedBarChartInput" absolute="true"></div>
     *         </div>
     *     </file>
     *     <file name="index.js">
     angular.module("FrontEndWeb").controller("example", function ($scope) {
                   $scope.horizontalStackedBarChartInput = (function () {
                       return {
                           configs: {
                               chartTitle: "The Chart Title",
                               type: "bar"
                           },
                           data: [
                               {
                                   identifier:"Identifier1",
                                   label:"label1",
                                   value:1234
                               },
                               {
                                   identifier:"Identifier1",
                                   label:"label2",
                                   value:858
                               },
                               {
                                   identifier:"Identifier2",
                                   label:"label1",
                                   value:854
                               },
                               {
                                   identifier:"Identifier2",
                                   label:"label2",
                                   value:291
                               },
                               {
                                   identifier:"Identifier3",
                                   label:"label1",
                                   value:573
                               },
                               {
                                   identifier:"Identifier3",
                                   label:"label2",
                                   value:793
                               }
                           ]
                       }
                   }());
              });
     </file>
     * </example>
     * */
    chartingModule.directive("horizontalStackedBarChart", ["$translate", "chartingService", "chartLocale", function ($translate, chartingService, chartLocale) {
        return {
            restrict: "A",
            scope: {
                context: "=horizontalStackedBarChart",
                absolute: "=?"
            },
            template: template,
            link: function ($scope, $element, attrs) {
                $scope.absolute = $scope.absolute ? $scope.absolute : false;

                var properties = $scope.context.configs.properties || $scope.context.configs.chartViewConfig.properties;
                var translateArguments = $scope.context.configs.chartViewConfig.translateArguments;
                var translatedTitle = $translate.instant($scope.context.configs.chartTitle, getTranslateArguments($scope.context.configs.chartTitle));
                var series = $scope.context.configs.chartViewConfig.series;
                $scope.chartIcon = iconPath($scope.context.configs.chartViewConfig.chartIcon);

                function iconPath(chartIcon) {
                    if (!chartIcon || chartIcon === "NONE") {
                        return null;
                    }
                    return "images/charts/" + chartIcon;
                }

                function getTranslateArguments(label) {
                    return translateArguments[label];
                }

                var translationBehaviour = {
                    bundle: function (label) {
                        return $translate.instant("charting.nameLabel.horizontalStackedBar." + label, getTranslateArguments(label));
                    },
                    legend: function (label) {
                        return $scope.context.configs.chartViewConfig.legendTitles["label.legend." + label];
                    }
                };

                var translateLabelFunction = function (label) {
                    var result = chartingService.formatChoosableLabel(label, $scope.choosables, $scope.choosableInfo);
                    if (!properties.translate) {
                        return result;
                    }
                    return translationBehaviour[properties.translate](result);
                };

                $scope.typeIdentifier = properties.typeIdentifier ? properties.typeIdentifier : undefined;

                $scope.formatValueTypeFunction = function (value) {
                    if (properties.valueType === "decimal") {
                        return chartLocale.formatDecimal(value);
                    }
                    if (properties.valueType === "currency") {
                        return chartLocale.formatCurrency(value);
                    }
                    if (properties.valueType === "percentage") {
                        return d3.format("0.2%")(value);
                    }
                    return value;
                };

                $scope.labelDataFunction = function (v, id, i) {
                    if (!v) {
                        return;
                    }
                    if (id && $scope.rawData && properties.showRawData === "true") {
                        var value = $scope.formatValueTypeFunction($scope.rawData[id][i + 1]).replace(",00", "");
                        return value + " (" + (properties.usePercentage === "true" ? d3.format("%")(v) : v) + ")";
                    }
                    return $scope.formatValueTypeFunction(v);
                };

                if (!angular.isDefined(attrs.hideTitle)) {
                    $element.find("h3.chart-title").text(translatedTitle);
                }

                var identifierBehavior = {
                    timeseries: {
                        getIdentifierFormat: function () {
                            return function (date) {
                                if (date.getMonth() + 1 < 10) {
                                    return "0" + (date.getMonth() + 1) + "/" + date.getFullYear();
                                } else {
                                    return (date.getMonth() + 1) + "/" + date.getFullYear();
                                }
                            };
                        },
                        formatIdenfitierValue: function (data) {
                            var date;
                            // Fix para não exibir NaN no IE
                            if (isNaN(data.identifier)) {
                                var datePattern = /^\s*(\d{4})\/(\d+)/;
                                var month;
                                var parts = datePattern.exec(data.identifier);
                                date = new Date(NaN);

                                if (parts) {
                                    month = +parts[2];
                                    date.setFullYear(parts[1], month - 1);
                                    if (month !== date.getMonth() + 1) {
                                        date.setTime(NaN);
                                    }
                                }
                                return new Date(date.getFullYear(), date.getMonth());
                            } else {
                                date = new Date(data.identifier);
                                return new Date(date.getFullYear(), date.getMonth());
                            }
                        }
                    },
                    range: {
                        getIdentifierFormat: function () {
                            return function (value) {
                                return value;
                            };
                        },
                        formatIdenfitierValue: function (data) {
                            var unitOfMeasure = $scope.context.configs.properties.unitOfMeasure;

                            if (!data.upperBound) {
                                return $translate.instant("charting.from") + " " + data.lowerBound + unitOfMeasure;
                            }
                            return _.join([
                                $translate.instant("charting.of"),
                                data.lowerBound + unitOfMeasure,
                                $translate.instant("charting.until"),
                                data.upperBound + unitOfMeasure
                            ], " ");
                        }
                    },
                    translate: {
                        getIdentifierFormat: function () {
                            return function (value) {
                                return value;
                            };
                        },
                        formatIdenfitierValue: function (data) {
                            return $translate.instant("charting.identifier.horizontalStackedBar." + data.identifier);
                        }
                    }
                };

                // labels do chart
                var currentIdentifiers = [];

                $scope.scale = null;
                var chartConfig = {
                    bindto: $element.find(".horizontal-stacked-bar-chart-content")[0],
                    // Utilizado para alinhar as labels dentro das barras.
                    onrendered: function () {
                        var bars = [];
                        d3.selectAll(".horizontal-stacked-bar-chart-content .c3-chart-bars .c3-target .c3-shapes .c3-shape")[0].forEach(function (el) {
                            var bbox = el.getBBox();
                            bars.push(bbox);
                        });
                        var i = 0;
                        d3.selectAll(".horizontal-stacked-bar-chart-content .c3-chart-text.c3-target .c3-texts .c3-text")[0].forEach(function (el) {
                            var bar = bars[i++];
                            hideLabelGreaterThanBar(el, bar);
                            if (bar.width === 0) {
                                return;
                            }
                            if (!bar.x) {
                                el.setAttribute("transform", "translate(-" + bar.width + ",0)");
                                return;
                            }
                            el.setAttribute("transform", "translate(-" + Math.abs(bars[i - 1].width) + ",0)");
                        });

                        function hideLabelGreaterThanBar(el, bar) {
                            var labelWidth = el.getBBox().width;
                            if (!labelWidth || labelWidth > bar.width) {
                                el.setAttribute("opacity", "0");
                            } else {
                                el.setAttribute("opacity", "1");
                            }
                        }
                    },

                    size: {
                        height: Number(properties.height) || Number(150),
                        width: Number(properties.width) || undefined
                    },
                    padding: {
                        top: 40,
                        left: 100
                    },
                    data: {
                        x: "x",
                        columns: [],
                        type: "bar",
                        labels: {
                            format: function (v, id, i, j) {
                                return $scope.labelDataFunction(v, id, i, j);
                            }
                        },
                        empty: {
                            label: {
                                text: $translate.instant("charting.notApplicable")
                            }
                        },
                        order: null
                    },
                    axis: {
                        rotated: properties.rotate === "true",
                        x: {
                            type: "category",
                            tick: {
                                rotate: getRotateByTypeIdentifier($scope.typeIdentifier),
                                multiline: false
                            },
                            height: 130
                        },
                        y: {
                            show: properties.hideYAxis !== "true",
                            label: {
                                text: $translate.instant(properties.yAxisLabel || ""),
                                position: "outer-middle"
                            },
                            tick: {
                                format: function (x) {
                                    if (angular.isDefined(properties.showOnlyIntegerOnY) && properties.showOnlyIntegerOnY === "true") {
                                        if (x !== Math.floor(x)) {
                                            d3.selectAll(".c3-axis-y g.tick").filter(function () {
                                                var text = d3.select(this).select("text").text();
                                                return +text === x;
                                            }).style("opacity", 0);
                                            return "";
                                        }
                                    }
                                    return x;
                                }
                            }
                        }
                    },
                    tooltip: {
                        format: {
                            value: function (value) {
                                if (!$scope.absolute || properties.usePercentageOnTooltip) {
                                    var format = d3.format("0.2%");
                                    return format($scope.scale(value));
                                }
                                return value;
                            }
                        },
                        contents: function (data) {
                            var totalForColumn = 0;
                            data.forEach(function (datum) {
                                totalForColumn += datum.value;
                            });
                            var scaleFunction = d3.scale.linear();
                            scaleFunction.domain([0, totalForColumn]).range([0, 1]);
                            $scope.scale = scaleFunction;
                            return c3.chart.internal.fn.getTooltipContent.apply(this, arguments);
                        }
                    },
                    legend: {
                        position: "inset",
                        inset: {
                            anchor: "top-left",
                            x: 20,
                            y: -40,
                            step: 1
                        }
                    },
                };

                if ($scope.context.configs.properties.color === "true") {
                    var colors = {};
                    angular.forEach($scope.context.configs.properties, function (value, prop) {
                        if (prop.substring(0, 6) === "color-") {
                            colors[translateLabelFunction(prop.substring(6))] = value;
                        }
                    });
                    chartConfig.data.colors = colors;
                } else {
                    var colorScale = d3.scale.category20();
                    var pattern = [];
                    for (var i = 0; i < 20; i++) {
                        pattern.push(colorScale(i));
                    }
                    chartConfig.color = {
                        pattern: pattern
                    };
                }

                function getRotateByTypeIdentifier(typeIdentifier) {
                    if (typeIdentifier === "range" || typeIdentifier === "timeseries") {
                        return 45;
                    }
                    return 0;
                }

                function getFormat(typeIdentifier) {
                    if (typeIdentifier !== undefined) {
                        return identifierBehavior[properties.typeIdentifier].getIdentifierFormat();
                    } else {
                        return undefined;
                    }
                }

                var chart = c3.generate(chartConfig);

                $scope.$on("$filterRefresh", function () {
                    chart.show();
                });

                $scope.$watch(function () {
                    return $scope.context.data;
                }, function (newContextData) {
                    $scope.choosables = newContextData.choosables;
                    $scope.choosableInfo = newContextData.choosableInfo;


                    var dataToProcess = angular.copy(newContextData);
                    var convertedData = convertDataToFormat(dataToProcess);
                    $scope.rawData = processRawData(convertedData);
                    var processedData = processDataToUpdateChart(convertedData);
                    chart.load(processedData.data);
                    chart.groups([processedData.group]);
                }, true);

                function convertDataToFormat(rawData) {
                    rawData.forEach(function (data) {
                        //Formatação para o identifier
                        if ($scope.typeIdentifier === "range" && Number(data.identifier) === Number.NaN) {
                            throw new Error("Type range for identifier only aceppt number: ");
                        }
                        if ($scope.typeIdentifier) {
                            var format = getFormat($scope.typeIdentifier);
                            var newData = identifierBehavior[$scope.typeIdentifier].formatIdenfitierValue(data);
                            data.identifier = (format(newData));
                        }

                        //Formatação para a label
                        data.label = (translateLabelFunction(data.label));
                    });
                    return rawData;
                }

                function normalize(val, min, max) {
                    var delta = max - min;
                    if (delta === 0) {
                        return 1;
                    } else {
                        return (val - min) / delta;
                    }
                }

                var totalizer = [];

                function processRawData(newContextData) {

                    totalizer = chartingService.sumOf(newContextData, series);

                    var mappingValues = {};
                    newContextData.forEach(function (data) {


                        var stringLabel = angular.isString(data.label) ? data.label : JSON.stringify(data.label);

                        if (!mappingValues[stringLabel]) {
                            mappingValues[stringLabel] = [stringLabel];
                        }
                        if (series && series.length) {
                            series.forEach(function (serie) {
                                var alias = serie.axis.alias;
                                mappingValues[stringLabel].push(data[alias]);
                            });
                        }
                    });
                    return mappingValues;
                }


                function processDataToUpdateChart(newContextData) {
                    var processedData = {
                        data: {
                            x: "x",
                            columns: [],
                            type: "bar",
                            labels: {
                                format: function (value) {
                                    return (value);
                                },
                                position: "left"
                            },
                            unload: []
                        },
                        group: []
                    };

                    var mappingValues = {};
                    var x = ["x"];

                    if (series && series.length) {
                        series.forEach(function (serie) {
                            var totalization = totalizer[serie.label];
                            if (totalization) {
                                x.push(translateLabelFunction(serie.label) + ": " + totalization);
                            } else {
                                x.push(translateLabelFunction(serie.label));
                            }
                        });
                    }

                    var newLabels = [];
                    var topBoundaries = [];
                    var lowerBoundaries = [];
                    var totalBoundaries = [];

                    if (series && series.length) {
                        newContextData.forEach(function (data) {
                            series.forEach(function (serie) {
                                var alias = serie.axis.alias;
                                topBoundaries[alias] = Math.max(topBoundaries[alias] || data[alias], data[alias]);
                                lowerBoundaries[alias] = Math.min(topBoundaries[alias] || data[alias], data[alias]);
                                totalBoundaries[alias] = (totalBoundaries[alias] || 0) + data[alias];
                            });
                        });
                    }

                    newContextData.forEach(function (data) {
                        var stringIdentifier = angular.isString(data.identifier) ? data.identifier : JSON.stringify(data.identifier);
                        if (!(series && series.length)) {
                            x.push(stringIdentifier);
                        }

                        var stringLabel = angular.isString(data.label) ? data.label : JSON.stringify(data.label);
                        if (!arrays.contains(newLabels, stringLabel)) {
                            newLabels.push(stringLabel);
                        }
                        if (!mappingValues[stringLabel]) {
                            mappingValues[stringLabel] = [stringLabel];
                        }

                        if (series && series.length) {
                            series.forEach(function (serie) {
                                var alias = serie.axis.alias;
                                if (properties.normalize) {
                                    mappingValues[stringLabel].push(normalize(data[alias], lowerBoundaries[alias], topBoundaries[alias]));
                                } else if (properties.usePercentage === "true") {
                                    mappingValues[stringLabel].push(data[alias] / totalBoundaries[alias]);
                                } else {
                                    mappingValues[stringLabel].push(data[alias]);
                                }
                            });
                        }
                    });

                    processedData.data.columns.push(x);
                    angular.forEach(mappingValues, function (value, key) {
                        processedData.data.columns.push(value);
                        processedData.group.push(key);
                    });

                    currentIdentifiers.forEach(function (label) {
                        if (!arrays.contains(newLabels, label)) {
                            processedData.data.unload.push(label);
                        }
                    });
                    currentIdentifiers = newLabels.sort();
                    return processedData;
                }
            }
        };
    }]);
});
