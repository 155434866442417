define([
    "./nlgHealthCheckerModule",
    "text!./nlgHealthCheckerDetailsModal.html"
], function (nlgHealthCheckerModule, template) {
    "use strict";

    return nlgHealthCheckerModule.service("nlgHealthCheckerDetailsModal", [
        "$modal",
        "nlgHealthCheckerService",
        function ($modal, nlgHealthCheckerService) {
            return function (instance, checkerName) {
                return nlgHealthCheckerService.getHealthInfos(instance, checkerName)
                    .then(function (healthInfos) {
                        return $modal.open({
                            template: template,
                            size: "lg",
                            controller: ["$scope", function ($scope) {
                                $scope.instance = instance;
                                $scope.checkerName = checkerName;
                                $scope.healthInfos = healthInfos;
                            }]
                        }).result;
                    });
            };
        }
    ]);
});
