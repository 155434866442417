define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    /**
     * @ngdoc object
     * @name templateFactoryColorTemplate.class:TemplateFactoryConfiguration
     * @property {*} model
     * Model que será afetado quando houverem alterações no `colorpicker` associado
     **/

    /**
     * @ngdoc function
     * @name templateFactoryColorTemplate.templateFactoryColorTemplate
     * @description
     * Template utilizado para criar um `input` do tipo `colorpicker`.
     *
     * @param {TemplateFactoryConfiguration} config Configuração para preencher o template.
     * @returns {jquery} Template preenchido.
     **/
    return templateFactoryModule.service("templateFactoryColorTemplate", [function () {
        return function (config) {
            var element = angular.element("<input>")
                .attr("type", "text")
                .attr("colorpicker", "");
            if (config.model) {
                element.attr("ng-model", config.model);
            }
            return element;
        };
    }]);
});