define(["./timeModule", "angular", "text!./nlgDurationInput.html"], function (timeModule, angular, template) {
    "use strict";


    /**
     * @ngdoc directive
     * @name directives.directive:nlgDurationInput
     * @description
     * Directiva que mostra a Duration no formato hh:mm.
     * @param {object[]=} ngModel Duração.
     * @example
     *<example module="FrontEndWeb">
     *     <file name="index.html">
     *         <div ng-controller="example">
     *          <nlg-duration-input
     *              ng-model="model">
     *          </nlg-duration-input>
     *          <span>model value: {{model}}</span>
     *     </file>
     *     <file name="index.js">
     *         angular.module("FrontEndWeb").controller("example", function ($scope) {
     *              $scope.model = 36000;
     *         });
     *     </file>
     * </example>
     *
     **/
    return timeModule.directive("nlgDurationInput", [function () {
        return {
            restrict: "EA",
            template: template,
            scope: {
                ngModel: "=",
                ngDisabled: "<",
                hideDays: "@?",
                hideHours: "@?",
                hideSeconds: "@?",
                hideMilliseconds: "@?",
                hideMinutes: "@?"
            },
            require: "ngModel",
            controller: ["$scope", "$translate", "$element", function ($scope, $translate, $element) {

                $scope.getPlaceholderDays = function () {
                    return getPlaceholderpart("duration.dayFormat");
                };

                $scope.getPlaceholderHours = function () {
                    return getPlaceholderpart("duration.hourFormat");
                };

                $scope.getPlaceholderMinutes = function () {
                    return getPlaceholderpart("duration.minuteFormat");
                };

                $scope.getPlaceholderSeconds = function () {
                    return getPlaceholderpart("duration.secondFormat");
                };

                $scope.getPlaceholderMilliseconds = function () {
                    return getPlaceholderpart("duration.millisecondFormat");
                };

                $scope.showDay = function () {
                    return $scope.hideDays !== "true";
                };

                $scope.showHour = function () {
                    return $scope.hideHours !== "true";
                };

                $scope.showSeconds = function () {
                    return $scope.hideSeconds !== "true";
                };

                $scope.showMilliseconds = function () {
                    return $scope.hideMilliseconds !== "true";
                };

                $scope.showMinutes = function () {
                    return $scope.hideMinutes !== "true";
                };

                function getPlaceholderpart(formatBundle) {
                    if (!$scope.nlgPlaceholder) {
                        return $translate.instant(formatBundle);
                    }
                    return "";
                }

                $scope.nullable = function () {
                    var value = $element.attr("nullable");
                    return angular.isDefined(value) && value !== "false";
                };
            }],
            link: function ($scope, $element, $attr, ngModelController) {
                if (!$scope.nullable()) {
                    $scope.model = {
                        millisecond: 0,
                        minute: 0,
                        hour: 0,
                        day: 0,
                        second: 0
                    };
                }

                ngModelController.$formatters.push(function (modelValue) {
                    modelValue = modelValue || 0;
                    var totalSeconds = Math.floor(modelValue / 1000);
                    return {
                        millisecond: Math.floor(modelValue) % 1000,
                        minute: Math.floor((totalSeconds % 3600) / 60),
                        hour: Math.floor((totalSeconds / 3600) % 24),
                        day: Math.floor(totalSeconds / 86400),
                        second: totalSeconds % 60
                    };
                });

                ngModelController.$render = function () {
                    $scope.model = angular.copy(ngModelController.$viewValue);
                };

                $scope.onChanged = function () {
                    if (!angular.equals($scope.model, ngModelController.$viewValue)) {
                        ngModelController.$setViewValue(angular.copy($scope.model));
                        ngModelController.$render();
                    }
                };

                ngModelController.$parsers.push(function (viewValue) {
                    if ($scope.nullable() && !isFilled(viewValue)) {
                        return null;
                    }
                    viewValue.millisecond = viewValue.millisecond || 0;
                    viewValue.second = viewValue.second || 0;
                    viewValue.minute = viewValue.minute || 0;
                    viewValue.hour = viewValue.hour || 0;
                    viewValue.day = viewValue.day || 0;
                    return viewValue.millisecond
                        + (viewValue.second * 1000)
                        + (viewValue.minute * 60000)
                        + (viewValue.hour * 3600000)
                        + (viewValue.day * 86400000);
                });

                ngModelController.$validators.allFieldsFilled = function (modelValue, viewValue) {
                    if ($scope.nullable()) {
                        return isFilled(viewValue) || isEmpty(viewValue);
                    }
                    return isFilled(viewValue);
                };

                function isFilled(viewValue) {
                    return (!$scope.showMilliseconds() || viewValue.millisecond !== null)
                        && viewValue.second !== null
                        && viewValue.minute !== null
                        && viewValue.hour !== null
                        && (!$scope.showDay() || viewValue.day !== null);
                }

                function isEmpty(viewValue) {
                    return (!$scope.showMilliseconds() || viewValue.millisecond === null)
                        && viewValue.second === null
                        && viewValue.minute === null
                        && viewValue.hour === null
                        && (!$scope.showDay() || viewValue.day === null);
                }
            }
        };
    }]);
});