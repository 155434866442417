define([
    "./arrangementModule",
    "text!./arrangementViewerModal.html"
], function (arrangementModule, arrangementViewerModalTemplate) {
    "use strict";

    /**
     * @ngdoc service
     * @name arrangementModule.arrangementViewerModal
     * @description
     * Serviço para exibir os arranjos de containers de uma carga.
     * @para {tripId} id da viagem que possui a carga.
     * @para {loadId} id da carga à ser exibida.
     * @returns {Promise} promessa que resolve quando o modal é fechado.
     * */
    return arrangementModule.service("arrangementViewerModal", ["arrangementDescriptionService", "routingSelectionDialog", "OptimizationSource", "FlowType", "$modal", "$q", "loadingService", "remoteExceptionHandler", function (arrangementDescriptionService, routingSelectionDialog, OptimizationSource, FlowType, $modal, $q, loadingService, remoteExceptionHandler) {
        function showModal(entityId, tripIds, loads, dus, findDescriptionFor, context) {

            return getRoutingConfig(context, tripIds, loads, dus)
                .then(function (routingConfigAndConverterScript) {
                    var routingConfig = routingConfigAndConverterScript.routingConfigId,
                        converterDecorator = routingConfigAndConverterScript.converterDecoratorId;
                    return loadingService(findDescriptionFor(entityId, routingConfig, converterDecorator))
                        .catch(remoteExceptionHandler());
                }).then(function (response) {
                    return openArrangementForDescription(response.data, loads.length > 0 || tripIds.length > 0, dus.length > 0);
                });
        }

        function openArrangementForDescription(description, isLoad, isDeliveryUnit) {
            var object = description;
            return $modal.open({
                template: arrangementViewerModalTemplate,
                size: "xl",
                controller: ["$scope", function ($scope) {
                    $scope.object = object;
                    $scope.isLoad = isLoad;
                    $scope.isDeliveryUnit = isDeliveryUnit;
                }]
            }).result.finally($q.resolve);
        }

        function getRoutingConfig(context, tripIds, loads, dus) {
            if (context) {
                return $q.when(context);
            }
            return routingSelectionDialog({
                source: OptimizationSource.VIEW_OPERATION,
                flow: FlowType.ANALYSIS,
                single: true,
                trips: tripIds,
                loads: loads,
                dus: dus
            }).then(function (routingConfigAndConverterScript) {
                var routingConfigId = routingConfigAndConverterScript.routingConfig.id,
                    converterDecoratorId = (routingConfigAndConverterScript.converterDecorator || {}).id;
                return getContext(routingConfigId, converterDecoratorId);
            });
        }

        function getContext(routingConfigId, converterDecoratorId){
            return {
                routingConfigId: routingConfigId,
                converterDecoratorId: converterDecoratorId
            };
        }

        function getContextIfExists(routingConfigId, converterDecoratorId) {
            if (!routingConfigId) {
                return null;
            }
            return getContext(routingConfigId, converterDecoratorId);
        }

        return {
            forTripWithAutomaticContext: function (tripId) {
                return showModal(tripId, [tripId], [], [], arrangementDescriptionService.forTrip, getContext(null, null));
            },
            forTrip: function (tripId, routingConfigId, converterDecoratorId) {
                return showModal(tripId, [tripId], [], [], arrangementDescriptionService.forTrip, getContextIfExists(routingConfigId, converterDecoratorId));
            },
            forLoad: function (loadId, routingConfigId, converterDecoratorId) {
                return showModal(loadId, [], [loadId], [], arrangementDescriptionService.forLoad, getContextIfExists(routingConfigId, converterDecoratorId));
            },
            forDeliveryUnit: function (deliveryUnitId, routingConfigId, converterDecoratorId) {
                return showModal(deliveryUnitId, [], [], [deliveryUnitId], arrangementDescriptionService.forDeliveryUnit, getContextIfExists(routingConfigId, converterDecoratorId));
            },
            forDescription: openArrangementForDescription
        };
    }]);
});