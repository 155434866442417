define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    /**
     * @ngdoc object
     * @name templateFactoryNumberTemplate.class:TemplateFactoryConfiguration
     * @property {*} model
     * Model que será afetado quando houverem alterações no input.
     *
     * @property {number} min
     * Número mínimo que o model pode assumir.
     *
     * @property {number} max
     * Número máximo que o model pode assumir.
     *
     * @property {number} decimals
     * Número de digitos que devem ser exibidos.
     * */

    /**
     * @ngdoc function
     * @name templateFactoryNumberTemplate.templateFactoryNumberTemplate
     * @description
     * Template utilizado para exibir números, utilizando a diretiva
     * {@link directives.directive:nlgNumberInput nlgNumberInput}.
     *
     * @param {TemplateFactoryConfiguration} config Configuração para preencher o template.
     * @returns {jquery} Template preenchido.
     * */
    return templateFactoryModule.service("templateFactoryNumberTemplate", [function () {
        return function (config) {
            var element = angular.element("<nlg-number-input>");
            element.attr("ng-model", config.model);

            var minDefined = angular.isDefined(config.min);
            attrIf(minDefined, "min", config.min);
            var nullableDefined = angular.isDefined(config.nullable);
            attrIf(nullableDefined, "nullable", "");
            attrIf(minDefined && config.min < 0, "nlg-negative", true);
            var maxDefined = angular.isDefined(config.max);
            attrIf(maxDefined, "max", config.max);
            attrIf(maxDefined && config.max < 0, "nlg-negative", true);
            attrIf(angular.isDefined(config.decimals), "nlg-decimals", config.decimals);
            element.attr("nlg-negative", config.negative || undefined);

            return element;

            function attrIf(condition, attr, value) {
                if (condition) {
                    element.attr(attr, value);
                }
                return element;
            }
        };
    }]);
});