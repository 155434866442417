define([
    "./nlgLGPDModule",
    "./nlgLGPDEditionController",
    "./nlgLGPDAnonymizeController",
    "./obfuscationProfile/nlgLGPDObfuscationController",
    "./sql/nlgLGPDSQLEditionController",
    "./logs/nlgLGPDLogsController",
    "./sql/nlgLGPDSQLSearchController",
    "./nlgLGPDRouter",
    "./nlgLGPDService",
    "./sql/nlgLGPDSQLService",
    "./nlgLGPDDirective",
    "./nlgLGPDProvider",
    "./nlgLGPDApiByArtifactProvider",
    "./attribute/LGPDAttributableControllerEdition",
    "./attribute/LGPDAttributableControllerSearch",
    "./attribute/LGPDAttributableService",
    "./classification/LGPDClassifiableControllerEdition",
    "./classification/LGPDClassifiableControllerSearch",
    "./classification/LGPDClassifiableService",
    "./genericParam/LGPDGenericParamControllerEdition",
    "./genericParam/LGPDGenericParamControllerSearch",
    "./genericParam/LGPDGenericParamService",
], function () {
});
