define(["./threeModule", "three"], function (threeModule, THREE) {
    "use strict";

    var FLOAT_TOLERANCE = 5e-6;

    return threeModule.service("threeEdgeFactory", [function () {

        return threeEdgeFactory;

        function threeEdgeFactory(object3d, object) {

            if (object3d.geometry instanceof THREE.LatheGeometry) {
                return null;
            }

            if (object3d.geometry instanceof THREE.CylinderGeometry) {
                return new THREE.EdgesHelper(object3d, 0);
            }

            var geometry = new THREE.Geometry();
            var edges = new THREE.Line(geometry, new THREE.LineBasicMaterial({
                color: 0x000000
            }));
            var allVertices = [];

            object3d.updateMatrix();
            object3d.updateMatrixWorld();
            object3d.geometry.vertices.forEach(function (vertex) {
                allVertices.push(object3d.localToWorld(newVector(vertex)));
            });

            allVertices.sort(function (v1, v2) {
                var xComparison = doubleCompare(v1.x, v2.x);
                if (xComparison !== 0) {
                    return xComparison;
                }
                var yComparison = doubleCompare(v1.y, v2.y);
                if (yComparison !== 0) {
                    return yComparison;
                }
                return doubleCompare(v1.z, v2.z);
            });

            var A = allVertices[0],
                B = allVertices[1],
                C = allVertices[5],
                D = allVertices[4],
                E = allVertices[2],
                F = allVertices[3],
                G = allVertices[7],
                H = allVertices[6];

            geometry.vertices.push(
                // Face 1
                A, B, B, F, F, E, E, A,
                // Face 2
                E, H, H, G, G, F,
                // Face 3
                H, D, D, C, C, G,
                // Face 4
                A, D, C, B);

            var orientationLine = null;
            if (!object.children) {
                // Objetos compostos não possuem linha de orientação, tampouco cilindros e tubos
                var orientationLineGeometry = new THREE.Geometry();
                var rotationName = object.rotation.name;
                if (rotationName === "LWH") {
                    orientationLineGeometry.vertices = createLineForFace(F, E, G, H).concat(createLineForFace(B, A, C, D));
                } else if (rotationName === "WLH") {
                    orientationLineGeometry.vertices = createLineForFace(E, H, F, G).concat(createLineForFace(A, D, B, C));
                } else if (rotationName === "LHW") {
                    orientationLineGeometry.vertices = createLineForFace(E, A, H, D).concat(createLineForFace(F, B, G, C));
                } else if (rotationName === "HWL") {
                    orientationLineGeometry.vertices = createLineForFace(A, B, E, F).concat(createLineForFace(D, C, H, G));
                } else if (rotationName === "WHL") {
                    orientationLineGeometry.vertices = createLineForFace(A, D, E, H).concat(createLineForFace(B, C, F, G));
                } else if (rotationName === "HLW") {
                    orientationLineGeometry.vertices = createLineForFace(E, A, F, B).concat(createLineForFace(H, D, G, C));
                }
                orientationLine = new THREE.LineSegments(orientationLineGeometry, new THREE.LineBasicMaterial());
            }

            function createLineForFace(bottomLeft, bottomRight, topLeft, topRight) {
                var bottomMiddle = bottomLeft.clone().sub(bottomRight).multiplyScalar(0.5).add(bottomRight);
                var topMiddle = topLeft.clone().sub(topRight).multiplyScalar(0.5).add(topRight);
                var bottomToTop = topMiddle.clone().sub(bottomMiddle);

                return [
                    bottomToTop.clone().multiplyScalar(0.25).add(bottomMiddle),
                    bottomToTop.clone().multiplyScalar(0.75).add(bottomMiddle)
                ];
            }

            return {
                edge: edges,
                orientationLine: orientationLine
            };
        }

        function newVector(vector3) {
            return new THREE.Vector3(vector3.x, vector3.y, vector3.z);
        }

        function doubleCompare(value1, value2) {
            var diff = value1 - value2;
            if (Math.abs(diff) < FLOAT_TOLERANCE) {
                return 0;
            }
            return diff;
        }
    }]);
});