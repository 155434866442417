define([
    "./classifiableModule"
], function (classifiableModule) {
    "use strict";

    classifiableModule.service("classifiableService", [function () {
        var classificationsCallback;

        this.getClassifications = function (viewValue, additionalArguments) {
            return classificationsCallback(viewValue, additionalArguments);
        };

        this.setClassificationsProviderCallback = function (callback) {
            classificationsCallback = callback;
        };
    }]);
});