define(["./mapsModule", "../arrays/arrays", "angular", "lodash", "text!./stopMoveSelectionModal.html"], function (mapsModule, arrays, angular, _, stopMoveSelectionModalTemplate) {
    "use strict";

    var mapConstants = {
        MIN_ZOOM_TO_PROGRAM: 4,
        MAX_ZOOM: 20
    };

    mapsModule.controller("nlgRouteController", ["$scope",
        "$rootScope",
        "$http",
        "$q",
        "mapsDirectionsService",
        "mapsRendererService",
        "mapsLatLngApi",
        "uiGmapGoogleMapApi",
        "uiGmapIsReady",
        "$translate",
        "$timeout",
        "$interval",
        "messagesModal",
        "MoveStopAction",
        "alterStopsService",
        "routeServiceUrl",
        "routeStopPopupService",
        "loadingService",
        "monitoringService",
        "carrierZoneGrouperService",
        "$mdSidenav",
        "markerImage",
        "nlgTagInputIconMap",
        "remoteExceptionHandler",
        "remoteMessageModal",
        "nlgRouteMapMarkerService",
        "nlgRouteStopMarkerService",
        function ($scope,
                  $rootScope,
                  $http,
                  $q,
                  mapsDirectionsService,
                  mapsRendererService,
                  mapsLatLngApi,
                  uiGmapGoogleMapApi,
                  uiGmapIsReady,
                  $translate,
                  $timeout,
                  $interval,
                  messagesModal,
                  MoveStopAction,
                  alterStopsService,
                  routeServiceUrl,
                  routeStopPopupService,
                  loadingService,
                  monitoringService,
                  carrierZoneGrouperService,
                  $mdSidenav,
                  markerImage,
                  nlgTagInputIconMap,
                  remoteExceptionHandler,
                  remoteMessageModal,
                  mapMarkersService,
                  stopMarkersService) {
            var stops = {};
            var alterStopsUrl = routeServiceUrl.getAlterStopsUrl();

            $scope.imagePath = require("!!url-loader?limit=1&name=[path][name].[ext]!markerclustererplus/images/m1.png")
                .replace("1.png", "");

            require("!!url-loader?limit=1&name=[path][name].[ext]!markerclustererplus/images/m2.png");
            require("!!url-loader?limit=1&name=[path][name].[ext]!markerclustererplus/images/m3.png");
            require("!!url-loader?limit=1&name=[path][name].[ext]!markerclustererplus/images/m4.png");
            require("!!url-loader?limit=1&name=[path][name].[ext]!markerclustererplus/images/m5.png");

            var mapApi;
            var mapPreferencesControl = {
                zoom: null,
                routeColors: [],
                hiddenRoutes: [],
                update: function () {
                    this.hiddenRoutes = [];
                    this.zoom = $scope.map.zoom;
                    $scope.routes.forEach(function (route) {
                        this.routeColors[route.presentationNode.id] = route.color;
                        if (!route.visible) {
                            this.hiddenRoutes.push(route.presentationNode.id);
                        }
                    }, this);
                },
                apply: function () {
                    if (this.zoom !== null) {
                        $scope.map.zoom = this.zoom;
                    } else {
                        $scope.centerOnRoutes();
                    }
                    if (this.routeColors.length) {
                        $scope.routes.forEach(function (route) {
                            var customColor = this.routeColors[route.presentationNode.id];
                            route.color = angular.isDefined(customColor) ? customColor : route.color;
                            $scope.changeRouteColor(route);
                            if (arrays.contains(this.hiddenRoutes, route.presentationNode.id)) {
                                route.visible = false;
                            }
                        }, this);
                    }
                }
            };
            $scope.zoomOnClickMarkersCluster = angular.isUndefined($scope.zoomOnClickMarkersCluster) ? false : $scope.isDrawingManagerReadOnly;
            $scope.isDrawingManagerReadOnly = angular.isUndefined($scope.isDrawingManagerReadOnly) ? true : $scope.isDrawingManagerReadOnly;
            $scope.hideTripTable = $scope.hideTripTable === "true";
            $scope.hideMonitorableTable = $scope.hideMonitorableTable === "true";
            $scope.hasFullscreenControl = $scope.hasFullscreenControl === "true";
            $scope.mapHeight = $scope.mapHeight || "auto";
            $scope.isDisplayingCarrierZonePolygons = false
            var mapsLatLng;

            mapsLatLngApi.then(function (mapsLatLngApi) {
                mapsLatLng = mapsLatLngApi;
            });

            // Centraliza no Brasil, caso não esteja informada posição inicial
            $scope.map = {
                zoom: 4,
                center: {
                    latitude: -15.508497493135424,
                    longitude: -49.75471769374996
                }
            };

            if (angular.isDefined($scope.brazil)) {
                // Força inicialização, necessário no fluxo de mudança de rota sem recarregar a página
                doCenterMap($scope.map.center);
            }

            $scope.routeAvailable = false;
            $scope.popup = {
                coords: {
                    latitude: 0,
                    longitude: 0
                },
                show: false,
                options: {
                    content: ""
                }
            };

            $scope.changeRouteColor = function (route) {
                var stopModels = $scope.getModel(route);
                arrays.each(stopModels, function (stopModel) {
                    if ($scope.fromSelectedStop && $scope.fromSelectedStop.id === stopModel.key) {
                        stopModel.icon = stopMarkersService.getHighlightedStopIcon(route, stopModel.stop, nlgTagInputIconMap, $scope.showLocalityIcon);
                    }
                    else {
                        stopModel.icon = stopMarkersService.getDefaultStopIcon(route, stopModel.stop, nlgTagInputIconMap, $scope.showLocalityIcon);
                    }
                });

                $timeout(function() {
                    $scope.setIconsFromLocality();
                });

                $scope.$emit("OnRouteColorChanged", getRouteColorData(route));
            };

            $rootScope.$on("onChangeStopsZIndex", function (event, route) {
                return changeStopsZIndex(route);
            });

            function changeStopsZIndex(route) {
                var stopModels = $scope.getModel(route);
                arrays.each(stopModels, function (stopModel) {
                    stopModel.options.zIndex = route.zIndex;
                });
            }

            $scope.centerOnRoute = function (route) {
                $scope.map.bounds = mapsLatLng.toBounds(arrays.map(route.stops, function (stop) {
                    return stop.address.geographicInfo;
                }));
            };

            function createStopContent(stop) {
                return routeStopPopupService.createStopPopup(getRouteOfStop(stop), stop);
            }

            function getRouteOfStop(stop){
                for(var i = 0; i < $scope.routes.length; i++){
                    var route = $scope.routes[i];
                    var stopIds = getStopIds(route.stops);
                    if(arrays.contains(stopIds, stop.id)) {
                        return route;
                    }
                }
            }

            function doCenterMap(coordinates) {
                $scope.map.center = {
                    latitude: coordinates.latitude + 0.000001,
                    longitude: coordinates.longitude + 0.000001
                };
                $scope.map.zoom = 20;
                // Necessário realizar mudança em ciclos distintos para gerar atualização do mapa.
                $timeout(function () {
                    $scope.mapControl.initialized.then(function () {
                        $scope.map.center = coordinates;
                        refresh();
                    });
                });
            }

            $scope.centerMapOnTruck = function (result) {
                doCenterMap(result.coords);
                $scope.map.zoom = 15;
            };

            $scope.$on("markersUpdate", function(event, markers){
                $scope.markers = markers;
            });

            $scope.centerOnTrucks = function () {
                mapsLatLngApi.then(function (mapsLatLngApi) {
                    mapsLatLng = mapsLatLngApi;
                    $scope.map.bounds = mapsLatLng.toBounds(arrays.map($scope.markers, function (marker) {
                        return marker.coords;
                    }));
                });
            };

            $scope.focusWithContent = function (latLng, stop) {
                $scope.map.zoom = 15;
                doCenterMap(mapsLatLng.fromLatLng(latLng));
                $scope.showPopUp(angular.copy($scope.map.center), createStopContent(stop));
            };

            $scope.showPopUp = function (coords, content) {
                $scope.$evalAsync(function () {
                    $scope.popup.coords = coords;
                    $scope.popup.show = true;
                    $scope.popup.template = content;
                    $scope.popup.templateParameter = undefined;
                });
            };

            function showPopUpTemplate(coords, template, templateParameter) {
                $scope.popup.coords = coords;
                $scope.popup.show = true;
                $scope.popup.template = template;
                $scope.popup.templateParameter = templateParameter;
                $scope.popup.options = {};
            }

            $scope.showRouteSteps = function (route) {
                var routeSteps = {
                    route: route,
                    legs: [],
                    copyrights: []
                };
                routeSteps.legs = _.times(route.stops.length, function (legIndex) {
                    var leg = {};
                    leg.startStop = route.stops[legIndex];
                    leg.startAddress = formatStopAddress(leg.startStop);
                    leg.startPosition = mapsLatLng.toLatLng(leg.startStop.address.geographicInfo);
                    leg.endStop = route.stops[legIndex + 1];
                    if (leg.endStop) {
                        leg.endAddress = formatStopAddress(leg.endStop);
                        leg.endPosition = mapsLatLng.toLatLng(leg.endStop.address.geographicInfo);
                    }
                    return leg;
                });

                arrays.each(route.results, function (routeSegment) {
                    var route = routeSegment.routes[0];
                    if (routeSteps.copyrights.indexOf(route.copyrights) === -1) {
                        routeSteps.copyrights.push(route.copyrights);
                    }
                });

                var allResultingLegs = _.flatMap(route.results, function (routeSegment) {
                    return _.flatMap(routeSegment.routes, function (route) {
                        return route.legs || [];
                    });
                });
                if (allResultingLegs.length === routeSteps.legs.length - 1) {
                    arrays.each(allResultingLegs, function (googleLeg, legIndex) {
                        routeSteps.legs[legIndex].steps = googleLeg.steps;
                    });
                }

                $scope.routeSteps = routeSteps;

                function formatStopAddress(stop) {
                    if (stop === null) {
                        return "";
                    }
                    return stop.address.street + ", " + (stop.address.number || "") + "<br>" +
                        stop.address.city.name + " - " + stop.address.state.name + ", " + (stop.address.country ? stop.address.country.name : "") + "<br>" +
                        (stop.address.zipCode ? stop.address.zipCode.value : "");
                }
            };

            $scope.saveLocality = false;

            $scope.saveGeolocality = function () {
                if(!$scope.saveLocality){
                    setDraggableRoutes(true);
                } else {
                    if(!$scope.stopMarker){
                        return messagesModal("dialog.error", ["route.locality.error.no.locality.changed"]);
                    }
                    var geocodeData = {
                        localityId: $scope.stopMarker.localityId,
                        latitude: $scope.stopMarkersEvents.coordsBeforeDrag.latitude,
                        longitude: $scope.stopMarkersEvents.coordsBeforeDrag.longitude
                    };


                    return messagesModal.cancellable("dialog.warning", ["maps.geolocation.update.confirm"])
                        .then(function () {
                            return monitoringService.calibrateGeocode(geocodeData)
                                .catch(remoteExceptionHandler())
                                .then(function (message) {
                                    setDraggableRoutes(false);
                                    return remoteMessageModal(message);
                                });
                        });
                }
            };

            function setDraggableRoutes(boolean){
                $scope.saveLocality = !!boolean;
                $scope.routes.forEach(function (route) {
                    var routeMarkerModels = $scope.getModel(route);
                    routeMarkerModels.forEach(function (model) {
                        model.options.draggable = $scope.saveLocality;
                    });
                });
                $scope.changeSaveGeolocalityTooltip();
                $scope.changeSaveGeolocalityIcon();
            }

            $scope.saveGeolocalityTooltip = "tooltip.change.geolocality";
            $scope.saveGeolocalityIcon = "fa fa-pen";

            $scope.changeSaveGeolocalityTooltip = function () {
                if($scope.saveLocality){
                    $scope.saveGeolocalityTooltip = "tooltip.save.newGeolocality";
                } else {
                    $scope.saveGeolocalityTooltip = "tooltip.change.geolocality";
                }
            };

            $scope.changeSaveGeolocalityIcon = function () {
                if($scope.saveLocality){
                    $scope.saveGeolocalityIcon = "fa fa-save";
                } else {
                    $scope.saveGeolocalityIcon = "fa fa-pen";
                }
            };

            var routeModels = {};
            $scope.getModel = function (route) {
                if (routeModels[route.presentationNode.id]) {
                    return routeModels[route.presentationNode.id];
                }

                var model = (routeModels[route.presentationNode.id] = arrays.map(route.stops, function (stop) {
                    return {
                        stop: stop,
                        coords: stop.address.geographicInfo,
                        icon: stopMarkersService.getDefaultStopIcon(route, stop, nlgTagInputIconMap, $scope.showLocalityIcon),
                        key: stop.id,
                        options: {
                            draggable: $scope.saveLocality || (canAlterStops(route) && !$scope.canProgramInMonitoring),
                            opacity: !route.opacity ? 1 : route.opacity,
                            zIndex: -1
                        },
                    };
                }));
                if (route.startingLeg) {
                    var origin = route.startingLeg.origin;
                    model.push({
                        stop: origin,
                        coords: origin.address.geographicInfo,
                        icon: stopMarkersService.getDefaultStopIcon(route, origin, nlgTagInputIconMap, $scope.showLocalityIcon),
                        key: origin.id
                    });
                }
                return model;
            };

            $scope.markersPopup = {
                coords: {
                    latitude: 0,
                    longitude: 0
                },
                show: false,
                templateUrl: "",
                templateParameter: {}
            };

            $scope.multipleMarkers = {
                coords: {
                    latitude: 0,
                    longitude: 0
                },
                show: false,
                templateUrl: "",
                templateParameter: {}
            };

            if ($scope.markerControl) {
                $scope.markerControl.hideMarkerPopup = function () {
                    $scope.markersPopup.show = false;
                };
            }
            $rootScope.canShowMarkersPopup = false;

            $scope.showMarkersAndPopUps = function () {
                if ($rootScope.canShowMarkersPopup) {
                    $scope.markersPopup.show = $rootScope.canShowMarkersPopup = false;
                }
                return $scope.markersPopup.show;
            };

            $scope.showMarkersPopUp = function (googleMarker, eventName, marker) {
                if (marker.popupConfig) {
                    $scope.markersPopup.coords = marker.coords;
                    $scope.markersPopup.show = true;
                    $scope.markersPopup.template = marker.popupConfig.template;
                    $scope.markersPopup.templateUrl = marker.popupConfig.templateUrl;
                    $scope.markersPopup.templateParameter = marker.popupConfig.templateParameter;
                    $scope.$digest();
                }
            };

            $scope.showClusterPupUp = function (clustererMarkerManager, markers) {
                if (markers[0].popupConfig) {
                    $scope.markersPopup.coords = markers[0].coords;
                    $scope.markersPopup.show = true;
                    $scope.markersPopup.template = "<div>" +
                        "<div ng-repeat='parameter in templateParameter'>" +
                        markers[0].popupConfig.template
                        + "<hr ng-hide='$last'>"
                        + "</div>"
                        + "</div>";
                    $scope.markersPopup.templateUrl = markers[0].popupConfig.templateUrl;
                    $scope.markersPopup.templateParameter = markers.map(function (marker) {
                        return marker.popupConfig.templateParameter;
                    });
                    $scope.$digest();
                    //throw new Error("stop propagation");
                }
            };

            $scope.getMarkers = function () {
                if (!$scope.markers) {
                    // $watch matters
                    $scope.markers = [];
                    return $scope.markers;
                }
                return $scope.markers;
            };

            $scope.getNonClusteredMarkers = function () {
                if (!$scope.nonClusteredMarkers) {
                    $scope.nonClusteredMarkers = [];
                }
                return $scope.nonClusteredMarkers;
            };

            $scope.drawCarrierZonePolygonInfo = function (grouperSourceId) {
                if($scope.isDisplayingCarrierZonePolygons){
                    $scope.polygonDrawerApi.clearPolygons();
                    $scope.isDisplayingCarrierZonePolygons = false;
                    return;
                }
                $scope.polygonDrawerApi.setSpecifyBundleConfirm($scope.carrierZoneGrouperToDrawSpecifyBundleConfirm);
                if(angular.isUndefined($scope.carrierZonePolygons)){
                    loadingService(carrierZoneGrouperService.getPolygonsByGrouperSourceId(grouperSourceId).then(function(result){
                            $scope.carrierZonePolygons = result.carrierZonePolygonDTOs;
                            $scope.polygonDrawerApi.drawCarrierZonePolygonDTOs($scope.carrierZonePolygons, true);
                    }));
                    $scope.isDisplayingCarrierZonePolygons = true;
                    return;
                }
                $scope.polygonDrawerApi.drawCarrierZonePolygonDTOs($scope.carrierZonePolygons, true);
                $scope.isDisplayingCarrierZonePolygons = true;
            };

            $scope.toggleRoute = function (route) {
                var tripMarkers = _.filter($scope.getMarkers(), function (marker) {
                    return marker.identifier === route.tripCode;
                });
                tripMarkers.forEach(function (marker) {
                    marker.options.visible = route.visible;
                });
            };

            $scope.setIconsFromLocality = function () {
                var id = $scope.routes[0].presentationNode.id;
                var models = routeModels[id];
                var promises = [];
                var iconMap = nlgTagInputIconMap.iconMap();
                models.forEach(function (model) {
                    var promise = getStopIconPromises(model.stop.stopIconText, iconMap[model.stop.stopIcon]);
                    promises.push(promise);
                });
                $q.all(promises).then(function (responses) {
                    models.forEach(function (model, i) {
                        model.icon = responses[i];
                    });
                });
            };

            function getStopIconPromises(text, iconPath) {
                var defer = $q.defer();
                return stopMarkersService.drawLocalityIcon(text, iconPath, defer).then(function (result) {
                    return result;
                });
            }

            var initializationDefer = $q.defer();
            $scope.mapControl = {
                initialized: initializationDefer.promise
            };

            $scope.loadingRoutes = false;

            function reDrawRoutes(routes) {
                mapsLatLngApi.then(function (mapsLatLngApi) {
                    mapsLatLng = mapsLatLngApi;
                    $scope.loadingRoutes = true;
                    return mapsDirectionsService(routes);
                }).then(function (directionsResult) {
                    $scope.loadingRoutes = false;
                    routes.forEach($scope.changeRouteColor);
                    refresh();
                    return removeInvalidRoutes(directionsResult);
                }).then(mapsRendererService.renderResult)
                    .then(function () {
                        $scope.routeAvailable = true;
                        mapPreferencesControl.apply();
                    }).then(function () {
                    //problemas de uso do Angular Google Maps com ng-show/hide
                    if (angular.isDefined($scope.openFirstRoute)) {
                        $scope.showRouteSteps($scope.routes[0]);
                    }

                    if ($scope.showLocalityIcon) {
                        $scope.setIconsFromLocality();
                    }
                    refresh();
                }).catch(function () {
                    $scope.loadingRoutes = false;
                });
            }

            var removeInvalidRoutes = function (directionsResult) {
                var invalidRouteCodes = [];
                var invalidStopCodes = [];

                var invalidRoutes = _.filter(directionsResult, function (route) {
                    var emptyRoutes = _.filter(route.results, function (result) {
                        return result.status === "ZERO_RESULTS";
                    });

                    if (emptyRoutes.length) {
                        invalidRouteCodes.push(route.tripCode);
                        _.each(_.map(route.stops, "stopName"), function (stop) {
                            if (!arrays.contains(invalidStopCodes, stop)) {
                                invalidStopCodes.push(stop);
                            }
                        });
                        return true;
                    }
                });

                if (invalidRouteCodes.length) {
                    $scope.routes.forEach(function (route) {
                        var routeMarkerModels = $scope.getModel(route);
                        routeMarkerModels.forEach(function (model) {
                            model.options.visible = !arrays.contains(invalidRoutes, route);
                        });
                    });

                    messagesModal("dialog.warning", [{
                        keyBundle: "trip.route.zero_results",
                        parameters: [invalidRouteCodes, invalidStopCodes]
                    }]);
                }
                return directionsResult;
            };

            var getRouteColorData = function (route) {
                return {
                    presentationId: route.tripCode,
                    color: route.color
                };
            };

            $scope.$watchCollection("routes", function (newRoutes, oldRoutes) {
                oldRoutes = oldRoutes || [];
                oldRoutes.forEach(function (oldRoute) {
                    var isRouteVisible = false;
                    arrays.each(newRoutes, function (newRoute) {
                        if (oldRoute.presentationNode.id === newRoute.presentationNode.id && angular.equals(oldRoute.stops, newRoute.stops)) {
                            isRouteVisible = true;
                            return arrays.each.BREAK;
                        }
                    });
                    oldRoute.visible = isRouteVisible;
                });
            });

            $scope.centerOnRoutes = function () {
                $scope.map.bounds = mapsLatLng.toBounds(arrays.map($scope.routes, function (route) {
                    return arrays.map(route.stops, function (stop) {
                        return stop.address.geographicInfo;
                    });
                }));
            };

            var refreshInterval = $interval(refresh, 20000);
            $scope.$on("$destroy", function () {
                $interval.cancel(refreshInterval);
            });

            function refresh() {
                if ($scope.mapControl.refresh && isMapVisible()) {
                    $scope.mapControl.refresh();
                }
            }

            function isMapVisible() {
                var gMap = $scope.mapControl.getGMap;
                if (!gMap) {
                    return false;
                }
                return gMap().getDiv().getBoundingClientRect().height !== 0;
            }

            $scope.doHideTripTable = function () {
                $scope.hideTripTable = !$scope.hideTripTable;
                $timeout(refresh);
            };

            $scope.doHideMonitorableTable = function () {
                $scope.hideMonitorableTable = !$scope.hideMonitorableTable;
                $timeout(refresh);
            };

            var routesApi = {};
            $scope.markerControls = {};
            routesApi.toggleMapMarkers = mapMarkersService.toggleMarkers;
            routesApi.cleanMapMarkersSelection = mapMarkersService.cleanSelection;
            routesApi.selectAllMapMarkers = function () {
                mapMarkersService.selectAllMapMarkers($scope.getMarkers());
            };
            routesApi.centerOnSelectedMapMarkers = function centerOnSelectedMapMarkers() {
                mapsLatLngApi.then(function (mapsLatLngApi) {
                    mapsLatLng = mapsLatLngApi;
                    $scope.map.bounds = mapsLatLng.toBounds(mapMarkersService.getCoordsOfSelectedMarkers());
                });
            };
            routesApi.setDrawingManagerReadOnly = function (activate) {
                $scope.isDrawingManagerReadOnly = activate;
            };
            routesApi.setZoomOnClickMarkersCluster = function (activate) {
                $scope.zoomOnClickMarkersCluster = activate;
            };
            routesApi.setOverlayTypeEditable = function (activate) {
                $scope.isOverlayTypeEditable = activate;
            };
            routesApi.setFreeHandLifeCycle = function (activate) {
                $scope.activateFreeHandLifeCycle = activate;
            };
            routesApi.setRectangleOverlayType = function (activate) {
                $scope.activateRectangleOverlayType = activate;
            };
            routesApi.setRectangleAutoDelete = function (activate) {
                $scope.activateRectangleAutoDelete = activate;
            };
            routesApi.centerMapOnCoords = doCenterMap;
            routesApi.centerOnTrucks = $scope.centerOnTrucks;
            routesApi.setRoutes = function (routes) {
                $scope.routes = routes;
                $scope.routes.forEach(function (route) {
                    if (!canAlterStops(route)) {
                        return;
                    }

                    $http.get(alterStopsUrl + route.presentationNode.id).then(function (stopsParam) {
                        stops[route.presentationNode.id] = stopsParam.data;
                    });

                    $scope.markerControls[route.presentationNode.id] = {};
                });

                // Seleciona paradas
                $scope.fromSelectedStop = null;
                $scope.selectedStops.markers = [];
                $scope.originalRoutes = angular.copy($scope.routes);

                var originalStopsSequence = {};
                $scope.originalRoutes.forEach(function (route) {
                    originalStopsSequence[route.presentationNode.id] = [];
                    route.stops.forEach(function (stop) {
                        originalStopsSequence[route.presentationNode.id].push(stop.id);
                    });
                });

                routeModels = {};
                mapsRendererService.resetHightlights();
                if (routes && routes.length) {
                    reDrawRoutes(routes);
                }
            };
            //Inicializa rotas
            $scope.$watch("routes", routesApi.setRoutes);
            if ($scope.registerRoutesApi) {
                $scope.registerRoutesApi({api: routesApi});
            }

            $interval(function () {
                if ($scope.autoRefreshCallback && $scope.isRefreshing) {
                    $scope.autoRefreshCallback({});
                }
            }, 30000);

            $scope.toggleAutoRefresh = function () {
                $scope.isRefreshing = !$scope.isRefreshing;
            };

            $scope.selectedStops = {
                markers: [],
                add: function(marker) {
                    if($scope.fromSelectedStop) {
                        return messagesModal("dialog.error", [{
                                keyBundle: "route.stopOperation.stopSelectedForSequenceChange"
                        }]);
                    }
                    this.markers.push(marker);
                    addSelectedStyleToStop(getTripIdFromStopId(marker.model.stop.id), marker.model.stop, "#00ff00");
                },
                remove: function(marker){
                    for( var i = 0; i < this.markers.length; i++){
                        if ( this.markers[i].model.stop.id === marker.model.stop.id) {
                            this.markers.splice(i, 1);
                            removeSelectedStyleToStop(getTripIdFromStopId(marker.model.stop.id), marker.model.stop);
                            return;
                        }
                    }
                },
                removeAll: function(){
                    this.markers.forEach(function(marker) {
                        removeSelectedStyleToStop(getTripIdFromStopId(marker.model.stop.id), marker.model.stop);
                    });
                    this.markers = [];
                },
                contains: function(marker){
                    for( var i = 0; i < this.markers.length; i++){
                        if ( this.markers[i].model.stop.id === marker.model.stop.id) {
                            return true;
                        }
                    }
                    return false;
                },
                containsStop: function(stopId){
                    for( var i = 0; i < this.markers.length; i++){
                        if ( this.markers[i].model.stop.id === stopId) {
                            return true;
                        }
                    }
                    return false;
                },
                isEmpty: function(){
                    return this.markers.length === 0;
                }
            };

            function toggleSelectedStop(event) {
                if(!$scope.selectedStops.isEmpty()) {
                    return messagesModal("dialog.error", [{
                        keyBundle: "route.stopOperation.stopsSelectedForChangeOnDistinctTrip"
                    }]);
                }
                var stop = event.model.stop;
                // Nenhuma parada selecionada, seleciona e aplica estilo à primeira
                if (!$scope.fromSelectedStop) {
                    $scope.fromSelectedStop = stop;
                    addSelectedStyleToStop(getTripIdFromStopId($scope.fromSelectedStop.id), $scope.fromSelectedStop);
                    // Uma parada já selecionada, selecionando a segunda, aplicando estilo e trocando a ordem
                } else if ($scope.fromSelectedStop && stop.id !== $scope.fromSelectedStop.id) {
                    var fromTripId = getTripIdFromStopId(event.model.key);
                    var toTripId = getTripIdFromStopId(stop.id);
                    changeStop(Array.of($scope.fromSelectedStop), fromTripId, stop, toTripId);
                    // Primeira parada selecionada, selecionando ela novamente, remove seleção e estilo (segunda parada não selecionada)
                } else if ($scope.fromSelectedStop && stop.id === $scope.fromSelectedStop.id) {
                    removeSelectedStyleToStop(getTripIdFromStopId($scope.fromSelectedStop.id), $scope.fromSelectedStop);
                    $scope.fromSelectedStop = null;
                }
            }

            function addSelectedStyleToStop(tripId, stop, strokeStyle) {
                var stopModels = routeModels[tripId];
                var selectedModel = arrays.filter(stopModels, function (model) {
                    return angular.equals(model.stop.id, stop.id);
                })[0];
                stopMarkersService.addSelectedStyleToCurrentIcon(selectedModel, getRouteFromTripId(tripId), strokeStyle);
            }

            function removeSelectedStyleToStop(tripId, stop) {
                var stopModels = routeModels[tripId];
                var selectedModel = arrays.filter(stopModels, function (model) {
                    return angular.equals(model.stop.id, stop.id);
                })[0];
                selectedModel.icon = stopMarkersService.getDefaultStopIcon(getRouteFromTripId(tripId), selectedModel.stop, nlgTagInputIconMap, $scope.showLocalityIcon);
                $scope.setIconsFromLocality();
            }

            function removeStopSelection() {
                removeSelectedStyleToStop(getTripIdFromStopId($scope.fromSelectedStop.id), $scope.fromSelectedStop);
                $scope.fromSelectedStop = null;
            }

            var routesWithStopsChanged = {};

            function changeStopsOnSameTrip(fromStops, toStop) {
                if(fromStops.length !== 1) {
                    return messagesModal("dialog.error", [{
                        keyBundle: "route.stopOperation.cannotChangeMoreThanOneStopOnSameTrip"
                    }]);
                }
                var fromStop = fromStops[0];
                var tripId = getTripIdFromStopId(fromStop.id);
                var route = getRouteFromTripId(tripId);
                var fromIndex = fromStop.sequenceOnTrip;
                var toIndex = toStop.sequenceOnTrip;

                if (canMove(fromIndex, toIndex, tripId)) {
                    var sequenceAux = fromStop.sequenceOnTrip;
                    route.stops[fromIndex].sequenceOnTrip = toStop.sequenceOnTrip;
                    route.stops[toIndex].sequenceOnTrip = sequenceAux;

                    arrays.swap(route.stops, fromIndex, toIndex);
                    arrays.swap(stops[tripId], fromIndex, toIndex);

                    routesWithStopsChanged[route.presentationNode.id] = route;
                    mapPreferencesControl.update();

                    // refresh all routes após o $digest
                    $scope.$evalAsync(function () {
                        clearCacheForRoute(route);
                        forceRoutesRefresh([route]);
                    });
                } else {
                    messagesModal("dialog.error", [{
                        keyBundle: "alterStops.error.loadUnloadDependency",
                        parameters: [(fromIndex + 1), (toIndex + 1)]
                    }]);
                }
                if ($scope.fromSelectedStop) {
                    removeStopSelection();
                }
            }

            function canMove(index1, index2, tripId) {
                var smallerIndex = Math.min(index1, index2);
                var greaterIndex = Math.max(index1, index2);
                return canMoveDown(smallerIndex, greaterIndex, tripId) && canMoveUp(smallerIndex, greaterIndex, tripId);
            }

            function canMoveDown(smallerIndex, greaterIndex, tripId) {
                var stopOrigin = stops[tripId][smallerIndex];
                //slice -> fim não inclusivo, por isso o +1
                var stopsToCheck = stops[tripId].slice(smallerIndex + 1, greaterIndex + 1);
                var possible = false;

                arrays.each(stopsToCheck, function (down) {
                    possible = arrays.disjoint(stopOrigin.loadedDeliveryUnits, down.unloadedDeliveryUnits);
                    if (!possible) {
                        return arrays.each.BREAK;
                    }
                });
                return possible;
            }

            function canMoveUp(smallerIndex, greaterIndex, tripId) {
                var stopOrigin = stops[tripId][greaterIndex];
                var stopsToCheck = stops[tripId].slice(smallerIndex, greaterIndex);
                stopsToCheck = stopsToCheck.reverse();
                var possible = false;

                arrays.each(stopsToCheck, function (up) {
                    possible = arrays.disjoint(stopOrigin.unloadedDeliveryUnits, up.loadedDeliveryUnits);
                    if (!possible) {
                        return arrays.each.BREAK;
                    }
                });
                return possible;
            }

            function extracTripId(stops){
                var id = -1;
                for(var i = 0; i < stops.length; i++){
                    var tripId = getTripIdFromStopId(stops[i].id);
                    if(id < 0 ){
                        id = tripId;
                        continue;
                    }
                    if(id !== tripId){
                        return -1;
                    }
                }
                return id;
            }

            function changeStopsOnDistinctTrips(fromStops, toStop) {
                if ($scope.hasStopSequenceChange()) {
                    messagesModal("dialog.error", [{
                        keyBundle: "route.stopOperation.shouldConfirmSequenceAlteration"
                    }]);
                    return;
                }
                var fromTripId = getTripIdFromStopId(fromStops[0].id);
                var toTripId = getTripIdFromStopId(toStop.id);
                var originRoute = getRouteFromTripId(fromTripId);
                var toRoute = getRouteFromTripId(toTripId);
                var action = new MoveStopAction(originRoute.presentationNode, toRoute.presentationNode, getStopIds(fromStops), toStop.id);
                action.perform().then($scope.$parent.resume);
            }

            function getRouteFromTripId(tripId) {
                var route = arrays.filter($scope.routes, function (route) {
                    return route.presentationNode.id.toString() === tripId.toString();
                });
                return route[0];
            }

            function clearCacheForRoute(route) {
                //Reseta os models da diretiva, workaround para bug na diretiva de marker, onde não é possível setar novos models
                $scope.markerControls[route.presentationNode.id].newModels([]);
                delete routeModels[route.presentationNode.id];
            }

            function getTripIdFromStopId(stopId) {
                var tripIdFromStop = null;
                Object.keys(routeModels).forEach(function (tripId) {
                    routeModels[tripId].forEach(function (stopModel) {
                        if (stopId === stopModel.stop.id) {
                            tripIdFromStop = tripId;
                            return arrays.each.BREAK;
                        }
                    });
                });
                return tripIdFromStop;
            }

            function forceRoutesRefresh(routes) {
                reDrawRoutes(routes || $scope.routes);
                $scope.markers = angular.copy($scope.markers, []);
            }

            $scope.hasStopSequenceChange = function () {
                var stopIds = [];
                var originalStopIds = [];

                $scope.routes.forEach(function (route) {
                    stopIds.push(getStopIds(route.stops));
                });

                $scope.originalRoutes.forEach(function (originalRoute) {
                    originalStopIds.push(getStopIds(originalRoute.stops));
                });

                return !angular.equals(stopIds, originalStopIds);
            };

            function getStopIds(stops) {
                var ids = [];
                stops.forEach(function (eachStop) {
                    ids.push(eachStop.id);
                });
                return ids;
            }

            $scope.alterStopCancel = function () {
                mapPreferencesControl.update();

                arrays.each($scope.routes, function (route) {
                    arrays.each($scope.originalRoutes, function (originalRoute) {
                        if (route.presentationNode.id === originalRoute.presentationNode.id) {
                            route.stops = angular.copy(originalRoute.stops);
                            return arrays.each.BREAK;
                        }
                    });
                });

                var routesToRefresh = [];
                $scope.routes.forEach(function (route) {
                    if (routesWithStopsChanged[route.presentationNode.id]) {
                        clearCacheForRoute(route);
                        routesToRefresh.push(route);
                    }
                });
                forceRoutesRefresh(routesToRefresh);
                routesWithStopsChanged = {};
            };

            $scope.confirmAlteration = function () {
                alterStopsService.confirmAlteration(angular.copy(stops, {}), getInitSequences(), getPresentationNodes()).then(function () {
                    $scope.$parent.resume();
                });
            };

            function getInitSequences() {
                var initSequence = {};
                $scope.originalRoutes.forEach(function (route) {
                    initSequence[route.presentationNode.id] = [];
                    route.stops.forEach(function (stop) {
                        initSequence[route.presentationNode.id].push(stop.sequenceOnTrip);
                    });
                });
                return initSequence;
            }

            function getPresentationNodes() {
                var presentationNodes = [];
                $scope.routes.forEach(function (route) {
                    presentationNodes.push(route.presentationNode);
                });

                return presentationNodes;
            }

            if ($scope.canProgramInMonitoring) {
                $scope.canProgram = true;
            }

            function chooseWhichActionsAreAllowed(googleMarker, args) {
                if(!$scope.selectedStops.isEmpty() && !$scope.selectedStops.contains(googleMarker) ) {
                    return messagesModal("dialog.error", [{
                        keyBundle: "route.stopOperation.thereIsStopsSelected",
                    }]);
                }
                var stops;
                if(!$scope.selectedStops.isEmpty()) {
                    stops = $scope.selectedStops.markers.map(function(marker){
                        return marker.model.stop;
                    });
                } else{
                   stops = Array.of(googleMarker.model.stop);
                }

                if ($scope.canProgramInMonitoring) {
                    programInMonitoring(googleMarker);
                    return;
                }
                program(googleMarker, args, angular.copy(stops));
            }

            function programInMonitoring(googleMarker) {
                $scope.stopMarkersEvents.coordsBeforeDrag.latitude = googleMarker.position.lat();
                $scope.stopMarkersEvents.coordsBeforeDrag.longitude = googleMarker.position.lng();

                setMarkerAndStopToLatLng(googleMarker, angular.copy(googleMarker.model.coords)).then(function () {
                    var stop = $scope.stopMarker = googleMarker.model.stop;
                    var tripId = getTripIdFromStopId(stop.id);
                    var route = getRouteFromTripId(tripId);

                    routesWithStopsChanged[route.presentationNode.id] = route;
                    mapPreferencesControl.update();

                    // refresh all routes após o $digest
                    $scope.$evalAsync(function () {
                        clearCacheForRoute(route);
                        forceRoutesRefresh([route]);
                    });
                });
            }

            function program(googleMarker, args, selectedStops) {
                var closerStops = getCloserStops(googleMarker.model);
                setMarkerAndStopToLatLng(googleMarker, angular.copy($scope.stopMarkersEvents.coordsBeforeDrag)).then(function () {
                    var fromStopTripId = extracTripId(selectedStops);
                    if(fromStopTripId < 0 ){
                        return messagesModal("dialog.error", [{
                            keyBundle: "route.stopOperation.movingStopsMustBeFromSameTrip"
                        }]);
                    }
                    if (!closerStops.length && $scope.canProgramInMonitoring !== true) {
                        return;
                    }
                    if (closerStops.length === 1) {
                        var uniqueStop = closerStops[0];
                        changeStop(selectedStops, fromStopTripId, uniqueStop.stop, uniqueStop.tripId);
                        return;
                    }
                    var mouseEvent = args[0];
                    var position = mapsLatLng.fromLatLng(mouseEvent.latLng);
                    showPopUpTemplate(position, stopMoveSelectionModalTemplate, {
                        stopModels: closerStops,
                        getStopIcon: function (stopModel) {
                            var route = getRouteFromTripId(stopModel.tripId);
                            return stopMarkersService.getDefaultStopIcon(route, stopModel.stop, nlgTagInputIconMap, $scope.showLocalityIcon);
                        },
                        selectTargetStop: function (selectedStop) {
                            $scope.popup.show = false;
                            changeStop(selectedStops, fromStopTripId, selectedStop, getTripIdFromStopId(selectedStop.id));
                        }
                    });
                });
            }

            if ($scope.canShowStopPopUp){
                $scope.stopMarkersEvents = {
                    click: function(googleMarker) {
                        $scope.showPopUp(googleMarker.model.coords, createStopContent( googleMarker.model.stop));
                    }
                };
            }

            if ($scope.canProgram) {
                $scope.stopMarkersEvents = {
                    rightclick: toggleSelectedStop,
                    coordsBeforeDrag: {
                        latitude: null,
                        longitude: null
                    },
                    dragstart: function (googleMarker) {
                        $scope.stopMarkersEvents.coordsBeforeDrag.latitude = googleMarker.position.lat();
                        $scope.stopMarkersEvents.coordsBeforeDrag.longitude = googleMarker.position.lng();
                    },
                    dragend: function (googleMarker, eventName, stop, args) {
                        chooseWhichActionsAreAllowed(googleMarker, args);
                        $scope.selectedStops.removeAll();
                    },
                    click: function(googleMarker, eventName, stop, args) {
                        var mouseEvent = args[0];
                        if(mouseEvent.domEvent.ctrlKey){
                            selectStop(googleMarker);
                            return;
                        }
                        $scope.showPopUp(googleMarker.model.coords, createStopContent( googleMarker.model.stop));
                    }
                };
            }

            function selectStop(marker) {
                var selectedStops = $scope.selectedStops;
                // Parada selecionada, remove seleção e estilo
                if(selectedStops.contains(marker)) {
                    selectedStops.remove(marker);
                    return ;
                }
                selectedStops.add(marker);
            }

            function changeStop(fromStops, fromStopTripId, toStop, toStopTripId) {
                if (fromStopTripId === toStopTripId) {
                    changeStopsOnSameTrip(fromStops, toStop);
                } else {
                    changeStopsOnDistinctTrips(fromStops, toStop);
                }
            }

            function setMarkerAndStopToLatLng(marker, coords) {
                return $timeout(function () {
                    marker.model.coords = coords;
                    marker.model.stop.address.geographicInfo = coords;
                    marker.setPosition(new mapApi.LatLng(coords.latitude, coords.longitude));
                });
            }

            function getCloserStops(model) {
                var stops = getAllVisibleStops();
                var closerStops = [];

                if ($scope.map.zoom < mapConstants.MIN_ZOOM_TO_PROGRAM) {
                    return angular.copy(closerStops);
                }
                var maxDistanceInMeters = getMaxDistanceByZoom();
                arrays.each(stops, function (stopModel) {
                    var currentStopDistance = calculateDistance(model.coords, stopModel.coords);
                    if (model.key !== stopModel.key && currentStopDistance <= maxDistanceInMeters) {
                        var tripId = getTripIdFromStopId(stopModel.stop.id);
                        var route = getRouteFromTripId(tripId);
                        var closerStop = {
                            stop: stopModel.stop,
                            tripId: tripId,
                            tripCode: route.tripCode
                        };
                        closerStops.push(closerStop);
                    }
                });
                return angular.copy(closerStops);
            }

            function getMaxDistanceByZoom() {
                return Math.pow(2, mapConstants.MAX_ZOOM - $scope.map.zoom + 2);
            }

            function getAllVisibleStops() {
                var stops = [];

                arrays.each(routeModels, function (routeModel, tripId) {
                    var route = getRouteFromTripId(tripId);
                    if (!route.visible) {
                        return;
                    }
                    arrays.each(routeModel, function (stop) {
                        stops.push(stop);
                    });
                });
                return stops;
            }

            function calculateDistance(coords1, coords2) {
                var latLng1 = new mapApi.LatLng(coords1.latitude, coords1.longitude);
                var latLng2 = new mapApi.LatLng(coords2.latitude, coords2.longitude);
                return mapApi.geometry.spherical.computeDistanceBetween(latLng1, latLng2);
            }

            /**
             * Objeto usado para compartilhar comportamento da diretiva `polygonDrawingManager` com este controller.
             */
            $scope.onRegisterApi = function (api) {
                $scope.polygonDrawerApi = api;
            };

            $scope.drawing = {};

            $scope.currentGrouperPolygons = [];

            var initialized = false;
            $scope.mapEvents = {
                "idle": function () {
                    if (!initialized) {
                        initializationDefer.resolve(true);
                    }
                }
            };

            $scope.mapOptions = {
                mapTypeControlOptions: {
                    mapTypeIds: ["map_style"]
                },
                fullscreenControl: $scope.hasFullscreenControl,
                fullscreenControlOptions: {}
            };

            uiGmapGoogleMapApi.then(function (_mapApi) {
                mapApi = _mapApi;
                $scope.mapOptions.mapTypeControlOptions.mapTypeIds.push(mapApi.MapTypeId.ROADMAP, mapApi.MapTypeId.HYBRID, mapApi.MapTypeId.SATELLITE, mapApi.MapTypeId.TERRAIN);
                $scope.mapOptions.fullscreenControlOptions.position = mapApi.ControlPosition.TOP_CENTER;
                return _mapApi;
            });

            var onNodeSelectedDeregistration = $rootScope.$on("OnNodeSelected", function (event, minimalNode) {
                if (minimalNode.type.name.toLowerCase() === "trip") {
                    var highlightedTrips = mapsRendererService.highlightTripRoute($scope.routes, minimalNode.id);
                    $scope.routes.forEach(function (route) {
                        var routeIndex = arrays.indexOf(highlightedTrips, route.presentationNode.id);
                        var routeMarkerModels = $scope.getModel(route);
                        var markerOpacity = highlightedTrips.length && routeIndex < 0 ? 0.3 : 1;
                        var isMarkerEnabled = markerOpacity === 1;
                        routeMarkerModels.forEach(function (model) {
                            model.options.zIndex = highlightedTrips.length ? routeIndex : model.options.zIndex;
                            model.options.opacity = markerOpacity;
                            model.options.draggable = isMarkerEnabled;
                            model.options.clickable = isMarkerEnabled;
                        });
                    });
                }
            });

            $scope.$on("$destroy", function () {
                $interval.cancel(refreshInterval);
                onNodeSelectedDeregistration();
            });

            function canAlterStops(route) {
                return $scope.canProgram && route.presentationNode.type.name.toLowerCase() === "trip";
            }

            $scope.toggleLeft = buildToggler("sidenav");
            $scope.labels = markerImage.labels();
            $scope.labelButton = "tracking.image.label";
            $scope.closeButton = "dialog.close";

            function buildToggler(componentId) {
                return function () {
                    $mdSidenav(componentId).toggle();
                };
            }

            $rootScope.selectedMapMarkers = $scope.selectedMapMarkers;

            $scope.mapMarkersEvents = {
                coordsBeforeDrag: {
                    latitude: null,
                    longitude: null
                },
                cleanCoordsBeforeDrag: function () {
                    $scope.mapMarkersEvents.coordsBeforeDrag.latitude = null;
                    $scope.mapMarkersEvents.coordsBeforeDrag.longitude = null;
                },
                dragstart: function (googleMarker) {
                    $scope.mapMarkersEvents.coordsBeforeDrag.latitude = googleMarker.position.lat();
                    $scope.mapMarkersEvents.coordsBeforeDrag.longitude = googleMarker.position.lng();
                },
                dragend: function (googleMarker, event, model) {
                    var closerStops = getCloserStops(googleMarker.model);
                    var coordsBeforeDrag = angular.copy($scope.mapMarkersEvents.coordsBeforeDrag);
                    googleMarker.model.coords = coordsBeforeDrag;
                    googleMarker.setPosition(new mapApi.LatLng(coordsBeforeDrag.latitude, coordsBeforeDrag.longitude));
                    if(closerStops.length === 0){
                        $scope.mapMarkersEvents.cleanCoordsBeforeDrag();
                        return;
                    }
                    var orderCodes = [];
                    if (mapMarkersService.isSelected(model)){
                        orderCodes = mapMarkersService.getSelectedMarkersCodes();
                    }else {
                        orderCodes = [ model.identifier ];
                    }
                    var tripCodes = _.map(closerStops, function (stop) {
                        return stop.tripCode;
                    });
                    var filteredTripCodes = mapMarkersService.filterDuplicatedTripCodes(tripCodes);
                    $scope.$emit("OnMapMarkerAction", {
                        tripCodes: filteredTripCodes,
                        orderCodes: orderCodes
                    });
                    $scope.mapMarkersEvents.cleanCoordsBeforeDrag();
                },
                click: function(googleMarker, eventName, model, args) {
                    var mouseEvent = args[0];
                    if(mouseEvent.domEvent.ctrlKey){
                        mapMarkersService.toggleMarkers( [ model ] );
                    }
                }
            };

            $scope.onDrawRectangle = function (rectangleModel) {
                var markersToSelect = [];
                $scope.markers.forEach( function(marker){
                    if (rectangleModel.rectangleBounds.contains(mapsLatLng.toLatLng( marker.coords ))){
                        if (mapMarkersService.isSelected( marker )){
                            return;
                        }
                        markersToSelect.push( marker );
                    }
                });
                mapMarkersService.toggleMarkers( markersToSelect );
            };

        }]);
});
