define(["./mapsModule",
    "angular",
    "text!./nlgMap.html"
], function (mapsModule, angular, template) {
    "use strict";

    return mapsModule.directive("nlgMap", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                geographicInfo: "<?",
                showDrawingControl: "<?",
                draggable: "<?",
                onRegisterPolygonDrawApi: "&?",
                polygonsElement: "=?",
                ngDisabled: "&",
                center: "=?",
                dragendCallback: "&?"
            },
            controller: ["$scope", function ($scope) {

                $scope._showDrawingControl = $scope.showDrawingControl ? !$scope.showDrawingControl : true;

                $scope._onRegisterPolygonDrawApi = function (_api) {
                    if ($scope.onRegisterPolygonDrawApi) {
                        $scope.onRegisterPolygonDrawApi({api: _api});
                    }
                };

                $scope.map = {
                    key: "key",
                    zoom: 15,
                    markerOptions: {
                        draggable: $scope.draggable
                    },
                    markerEvents: {
                        dragend: function () {
                            if ($scope.dragendCallback) {
                                $scope.dragendCallback();
                            }
                        }
                    },
                    control: {}
                };
                setCenterMap();

                $scope.$watch("geographicInfo", function () {
                    setCenterMap();
                });

                function setCenterMap() {
                    $scope.map.center = angular.extend({
                        latitude: 0,
                        longitude: 0
                    }, getMapCenter());
                }

                function getMapCenter() {
                    if ($scope.geographicInfo && $scope.geographicInfo.latitude && $scope.geographicInfo.longitude) {
                        return $scope.geographicInfo;
                    }
                    if ($scope.center) {
                        return $scope.center;
                    }
                    return null;
                }

                $scope.$watch(function () {
                    return $scope.ngDisabled();
                }, function (newValue) {
                    $scope.map.markerOptions.draggable = !newValue;
                });
            }
            ]
        };
    }]);
});
