define([
    "angular",
    "./mapsModule"
], function (angular, mapsModule) {
    "use strict";
    mapsModule.provider("mapsPathServiceUrl", [function () {

        var routePathUrl;

        this.$get = [function () {
            return {
                getRoutePathUrl: getRoutePathUrl
            };
        }];

        var getRoutePathUrl = function () {
            return routePathUrl;
        };

        this.configure = function (routing) {
            routePathUrl = routing.routePathUrl;
        };
    }]);
});

