define([
    "./monitorModule"
], function (module) {
    "use strict";

    return module.service("monitorService", ["monitorUrlService", "$http", "downloadBinary", function (monitorUrlService, $http, downloadBinary) {
        var self = this;

        self.ENDPOINTS = [
            "beans",
            "conditions",
            "env",
            "health",
            "httptrace",
            "info",
            "liquibase",
            "metrics",
            "mappings"
        ];

        self.findApps = function () {
            return $http.get(monitorUrlService.getUrl("eureka/apps"))
                .then(getData).then(function (dto) {
                    return dto.applications;
                });
        };

        self.findEndpoint = function (instance, endpoint) {
            return $http.get(monitorUrlService.getUrl(instance, endpoint))
                .then(getData);
        };

        self.request = function (instance, request) {
            return $http({
                url: monitorUrlService.getUrl(instance, request.url),
                method: request.method,
                headers: request.headers,
                data: request.body
            });
        };

        self.findLogFiles = function (instance, folder) {
            return $http.get(monitorUrlService.getUrl(instance, "log", "navigate"), {
                params: {
                    folder: folder
                }
            }).then(getData);
        };

        self.downloadLogFile = function (instance, file) {
            return downloadBinary.get(monitorUrlService.getUrl(instance, "log", "download"), file, {
                file: file
            });
        };

        self.searchLogFiles = function (instance, pattern, options) {
            options = options || {};
            return $http.get(monitorUrlService.getUrl(instance, "log", "search"), {
                params: {
                    pattern: pattern,
                    before: options.before,
                    after: options.after,
                    fileGlob: options.fileGlob
                }
            }).then(getData);
        };

        self.tailLogFile = function (instance, file, startByte, bytesToRead) {
            return $http.get(monitorUrlService.getUrl(instance, "log", "tail"), {
                params: {
                    file: file,
                    startByte: startByte,
                    bytesToRead: bytesToRead
                }
            }).then(getData);
        };

        self.updateEnv = function (instance, propertyName, propertyValue) {
            var params = {};
            params[propertyName] = propertyValue;
            return $http.post(monitorUrlService.getUrl(instance, "env"), null, {
                params: params
            })
                .then(function () {
                    return $http.post(monitorUrlService.getUrl(instance, "refresh"));
                })
                .then(getData);
        };

        return self;
    }]);

    function getData(response) {
        return response.data;
    }
});