define([
    "angular",
    "angular-bootstrap",
    "angular-translate",
    "../violation/violation",
    "../collections/collections"
], function (angular) {
    "use strict";

    /**
     * @ngdoc overview
     * @name remoteModule
     * @description
     * Módulo que contém classes utilitárias para trabalhar com invocações
     * remotas.
     * */
    return angular.module("remoteModule", [
        "ui.bootstrap",
        "pascalprecht.translate",
        "violationModule",
        "collectionsModule"
    ]);
});