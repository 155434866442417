define(["angular",
    "../nlgHealthCheckerModule"
], function (angular, nlgHealthCheckerModule) {
    "use strict";

    nlgHealthCheckerModule.service("typeFormatService", [
        "TypeFormat",
        "$filter",
        function (TypeFormat, $filter) {
            return {
                convertTypeFormatToChartLocateName: function (value) {
                    switch (value) {
                        case TypeFormat.BYTES:
                            return "bytes";
                        case TypeFormat.PERCENTAGE:
                            return "percentage";
                        case TypeFormat.WEIGHT:
                            return "weight";
                        case TypeFormat.VOLUME:
                            return "volume";
                        case TypeFormat.DURATION:
                            return "duration";
                        case TypeFormat.CURRENCY:
                            return "currency";
                        default:
                            return "number";
                    }
                },
                formatterByType: function (format) {
                    return $filter(this.convertTypeFormatToChartLocateName(format));
                }
            };
        }]);
});
