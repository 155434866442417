define([], function () {
    function MenuItem(parent) {
        this.getParent = function () {
            return parent;
        };
    }

    MenuItem.prototype.setBundle = function (bundle) {
        this.bundle = bundle;
        return this;
    };

    MenuItem.prototype.setState = function (state, stateParams) {
        this.state = state;
        this.stateParams = stateParams;
        return this;
    };

    MenuItem.prototype.setCssClass = function (cssClass) {
        this.cssClass = cssClass;
        return this;
    };

    MenuItem.prototype.setGroupName = function (groupName) {
        this.groupName = groupName;
        return this;
    };

    MenuItem.prototype.setSubMenus = function (subMenus) {
        this.subMenus = subMenus;
        return this;
    };

    MenuItem.prototype.subMenu = function () {
        if (!this.subMenus) {
            this.subMenus = [];
        }
        return new MenuItem(this);
    };

    MenuItem.prototype.done = function () {
        var parent = this.getParent();
        parent.subMenus.push(this);
        return parent;
    };

    return MenuItem;
});
