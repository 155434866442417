define(["./mapsModule"], function (mapsModule) {
    "use strict";

    mapsModule.provider("mapsConfigGetter", [function () {

        var mapsConfig = null;
        var defer = null;

        this.$get = ["$q", function ($q) {

            defer = $q.defer();

            if (mapsConfig !== null) {
                return $q.when(mapsConfig);
            }

            return defer.promise;
        }];

        this.registerMapsConfig = function (config) {
            mapsConfig = config;
            defer.resolve(mapsConfig);
        };
    }]);
});