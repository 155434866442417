define(["./arrangementModule"], function (arrangementModule) {
    "use strict";

    var prefix = "br.com.neolog.service.arrangement.viewer.model.Object3DInfo.";

    return arrangementModule.constant("Object3DInfo", {
        COLOR_GROUP: prefix + "COLOR_GROUP",
        REAR_SUPPORT_HEIGHT: prefix + "REAR_SUPPORT_HEIGHT",
        FRONT_SUPPORT_HEIGHT: prefix + "FRONT_SUPPORT_HEIGHT",
        SIDE_SUPPORT_HEIGHT: prefix + "SIDE_SUPPORT_HEIGHT",
        NECK_DIMENSIONS: prefix + "NECK_DIMENSIONS",
        AXLE_DISTANCES: prefix + "AXLE_DISTANCES",
        DELIVERY_UNIT_DATA: prefix + "DELIVERY_UNIT_DATA",
        VERTICAL_LAYER_INDEX: prefix + "VERTICAL_LAYER_INDEX",
        HORIZONTAL_LAYER_INDEX: prefix + "HORIZONTAL_LAYER_INDEX",
        SUPPORT_HEIGHT: prefix + "SUPPORT_HEIGHT",
        SUPPORT_LENGTH: prefix + "SUPPORT_LENGTH",
        SUPPORT_WIDTH: prefix + "SUPPORT_WIDTH",
        SUPPORT_SHAPE: prefix + "SUPPORT_SHAPE",
        PACK_IDENTIFIER: prefix + "PACK_IDENTIFIER",
        SUPPORT_AXIS: prefix + "SUPPORT_AXIS",
        DESCRIPTION: prefix + "DESCRIPTION",
        OPACITY: prefix + "OPACITY"
    });
});