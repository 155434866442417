define(["./violationModule"], function (violationModule) {
    "use strict";

    return violationModule.provider("dtoLinkService", [function () {
        var getRouteForDto = null;

        this.setRouteSupplier = function (routeSupplier) {
            getRouteForDto = routeSupplier;
        };

        this.$get = [function () {
            return {
                getRouteForDto: getRouteForDto
            };
        }];
    }]);
});