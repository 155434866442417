define([
    "./monitorModule",
    "angular",
    "text!./nlgMonitorLogNavigator.html"
], function (monitorModule, angular, template) {
    "use strict";

    return monitorModule.directive("nlgMonitorLogNavigator", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                instance: "=",
                onSelectFile: "&?",
                folder: "<?"
            },
            controller: [
                "$scope",
                function ($scope) {
                    $scope.selectedFile = null;

                    $scope.fileSelected = function (file) {
                        if (file) {
                            $scope.selectedFile = file;
                        }
                    };
                }]
        };
    }]);
});