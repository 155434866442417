define([
    "./dashboard",
    "./dashboard.less",
    "./dashboardModule",
    "./dashboardDirective",
    "./dashboardContent",
    "./dashboardFilters",
    "./dashboardPresentation",
    "./dashboardService",
    "./dashboardDomain",
    "./spreadsheetServiceInvoker",
    "./dashboardExport",
    "./nlgDashboardLayout"
], function () {
});
