define([
    "angular",
    "./nlgTableauVizModule",
    "lodash"
], function (angular, nlgTableauVizModule, _) {
    "use strict";

    nlgTableauVizModule.provider("tableauServiceUrl", [function () {

        var viewUrl;
        var dashboardsUrl;
        var authenticationTicketUrl;

        this.setUrl = function (url) {
            viewUrl = url.viewUrl;
            dashboardsUrl = url.dashboardsUrl;
            authenticationTicketUrl = url.authenticationTicketUrl;
        };

        function checkUrls() {
            checkNotNull("viewUrl", viewUrl);
            checkNotNull("dashboardsUrl", dashboardsUrl);
            checkNotNull("authenticationTicketUrl", authenticationTicketUrl);
        }

        this.$get = ["$http", "remoteExceptionHandler", function ($http, remoteExceptionHandler) {
            checkUrls();

            return {
                getViewUrl: getViewUrl,
                getDashboards: getDashboards
            };

            function getViewUrl(dashboard) {
                return $http.get(viewUrl)
                    .then(function (tableauServerHost) {
                        return generateAuthTicket().then(function (authTicket) {
                            return tableauServerHost.data + "trusted/" + authTicket.data + "/views/" + dashboard.workbookName + "/" + dashboard.sheetName;
                        }, remoteExceptionHandler());
                    }, remoteExceptionHandler());
            }

            function getDashboards() {
                return $http.get(dashboardsUrl);
            }

            function generateAuthTicket() {
                return $http.get(authenticationTicketUrl);
            }
        }];

        function checkNotNull(name, string) {
            if (_.isNil(string) || string.trim().length === 0) {
                throw new Error("tableauServiceUrl:", name, "string is null or empty");
            }
        }
    }]);
});