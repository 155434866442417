define([
    "../nlgHealthCheckerModule",
    "text!./nlgHealthCheckReason.html",
], function (module, template) {
    "use strict";

    return module.directive("nlgHealthCheckReason", [function () {
        return {
            template: template,
            scope: {
                reason: "<",
                displayMessage: "<?"
            },
            controller: ["$scope", function ($scope) {
                $scope.getColumnClass = function (data) {
                    if(data.length === 2){
                        return "col-12 col-md-6 col-lg-6";
                    }
                };
            }]
        };
    }]);
});
