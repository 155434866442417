define(["./filterModule",
    "text!./genericAttributeFilterComponent.html"
], function (filterModule, template) {
    "use strict";

    return filterModule.directive("genericAttributeFilterComponent", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                model: "=",
                attributableClass: "@"
            },
            controller: ["$scope", "filterConfiguration", function ($scope, filterConfiguration) {

                $scope.loadPossibleGenericAttributeTypes = function (viewValue) {
                    return filterConfiguration.filterGenericAttribute(viewValue, $scope.model.additionalInfo || []);
                };

                $scope.removeGenericAttributeField = function(index){
                    $scope.model.multipleValue.splice(index, 1);
                };

                $scope.addGenericAttributeField = function(){
                    $scope.model.multipleValue.push({
                        genericAttributeType: null,
                        value: []
                    });
                };

                $scope.$watch(function(){
                    return $scope.model.multipleValue;
                }, function(newValue){
                    if(! newValue || ! newValue.length){
                        $scope.addGenericAttributeField();
                    }
                });
            }]
        };
    }]);
});