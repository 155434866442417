define([
    "./commonScriptModule"
], function (controllers) {
    "use strict";

    controllers.controller("nlgScriptEntityEditionController", [
        "$scope", "scriptUrls", "scriptService", "loadingService", "messagesModal", "autocompleteFactory",
        function ($scope, scriptUrls, scriptService, loadingService, messagesModal, autocompleteFactory) {
            $scope.tabConstants = {active: true, inactive: false};

            $scope.scriptValidationResult = [];
            $scope.requiredGenericParams = [];

            scriptService.getRequiredGenericParams($scope.entity.id)
                .then(function (result) {
                    $scope.requiredGenericParams = result.data;
                });

            loadingService(scriptService.getScriptTypes())
                .then(function (response) {
                    $scope.scriptTypes = response.data;
                });

            $scope.getScriptTypes = function () {
                return $scope.scriptTypes || [];
            };

            $scope.getParams = function () {
                return $scope.requiredGenericParams || [];
            };

            $scope.availableCollaborators = autocompleteFactory.lazyLoader(scriptUrls.autocompleteUrl, [], {
                "scriptType": "SCRIPT_COLLABORATOR"
            });

            $scope.validateScript = function () {
                $scope.scriptValidationResult = [];
                loadingService(scriptService.validate($scope.entity)
                    .then(function (response) {
                        $scope.scriptValidationResult = response.data || [];
                    }).catch(function (error) {
                        $scope.scriptValidationResult = error.data || [];
                    })
                ).finally(function () {
                    if (!$scope.scriptValidationResult.length)
                        messagesModal("dialog.success", [{
                            "keyBundle": "script.success"
                        }]);
                });
            };
        }]);
});