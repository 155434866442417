define([
    "./chartingModule",
    "./chartTemplateFactories",
    "./geoChart",
    "./discreteAxisChart",
    "./chartGroup",
    "./pieChart",
    "./stackedBarChart",
    "./horizontalStackedBarChart",
    "./treemapChart",
    "./barChart",
    "./multiBarChart",
    "./temperatureChart",
    "./barChart",
    "./tableChart",
    "./seriesChart",
    "./summaryChart",
    "./chartingService",
    "./chartLocale"
], function(){}
);
