/* globals google */
define(["./mapsModule", "../arrays/arrays"], function (mapsModule, arrays) {
    "use strict";

    /**
     * Quando o mapa é exibido dentro de um $modal, ele aprece cortado da segunda vez.
     * Para corrigir este problema, quando o mapa terminar de ser renderizado é enviado
     * um evento de resize para o mapa.
     * */
    return mapsModule.config(["$provide", function ($provide) {
        $provide.decorator("uiGmapIsReady", ["$delegate", function ($delegate) {
            var original = $delegate.spawn;
            // método que cria a instancia do mapa e suas promessas
            $delegate.spawn = function () {
                var result = original.apply($delegate, arrays.copy(arguments));
                result.deferred.promise.then(function (instance) {
                    google.maps.event.trigger(instance.map, "resize");
                });
                return result;
            };
            return $delegate;
        }]);
    }]);
});