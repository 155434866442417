define(["angular", "angular-bootstrap-colorpicker"], function (angular) {
    "use strict";

    /**
     * @ngdoc overview
     * @name colorpickerModule
     * @description
     * Módulo que agrupa funcionalidades de seleção de cor.
     * */
    return angular.module("colorpickerModule", ["colorpicker.module"])
        .config(["$provide", function ($provide) {
            $provide.decorator("Helper", ["$delegate", function ($delegate) {
                // corrige o get offset, de modo que retorne um valor considerando overflow e scrollbars
                $delegate.getOffset = function (elm) {
                    var offset = angular.element(elm).offset();
                    return {
                        top: offset.top,
                        left: offset.left,
                        scrollX: 0,
                        scrollY: 0
                    };
                };
                return $delegate;
            }]);
        }]);
});