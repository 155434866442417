define([
    "fast-json-patch",
    "lodash"
], function jsonpatches(jsonpatch, _) {
    "use strict";

    return {
        compare: compare,
        apply: jsonpatch.apply,
        observe: jsonpatch.observe,
        unobserve: jsonpatch.unobserve,
        generate: jsonpatch.generate
    };

    function compare(originalObject, editedObject, fullReplaceProperties) {
        if (!fullReplaceProperties || !fullReplaceProperties.length) {
            return jsonpatch.compare(originalObject, editedObject);
        }
        var fullReplacePatches = [];
        _.each(fullReplaceProperties, function (property) {
            var formattedProperty = property.slice(1, property.length).replace(/[/]/g, ".");
            var originalProperty = _.get(originalObject, formattedProperty);
            var editedProperty = _.get(editedObject, formattedProperty);
            var propertyPatches = jsonpatch.compare(originalProperty, editedProperty);
            if (propertyPatches.length) {
                fullReplacePatches.push({
                    op: "replace",
                    path: property,
                    value: editedProperty
                });
            }
        });
        return _.filter(jsonpatch.compare(originalObject, editedObject), function (originalPatch) {
            var shouldFilter = true;
            _.each(fullReplacePatches, function (patch) {
                if (_.startsWith(originalPatch.path, patch.path)) {
                    shouldFilter = false;
                }
            });
            return shouldFilter;
        }).concat(fullReplacePatches);
    }
});
