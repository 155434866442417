define(["./mapsModule"], function (mapsModule) {
    "use strict";

    var _uiGmapGoogleMapApiProvider = null;

    mapsModule.config(["uiGmapGoogleMapApiProvider", function (uiGmapGoogleMapApiProvider) {
        _uiGmapGoogleMapApiProvider = uiGmapGoogleMapApiProvider;
        _uiGmapGoogleMapApiProvider.options.preventLoad = true;
    }]);

    mapsModule.run(["mapsConfigGetter", "$translate", "uiGmapGoogleMapApi" ,"uiGmapGoogleMapApiManualLoader", function (mapsConfigGetter, $translate, dependenciaIndiretaNaoRemover, uiGmapGoogleMapApiManualLoader) {

        mapsConfigGetter.then(function (mapsConfig) {
            var options = {
                //isGoogleMapsForWork: true,
                channel: mapsConfig.channel,
                v: mapsConfig.version,
                libraries: "drawing",
                language: $translate.use()
            };
            configureKey(options,mapsConfig);

            _uiGmapGoogleMapApiProvider.configure(options);
            uiGmapGoogleMapApiManualLoader.load();
        });

        function configureKey(options,mapsConfig) {
            if(mapsConfig.keyType === "br.com.neolog.model.parameters.properties.google.GoogleMapsKeyType.API_KEY"){
                options["key"] = mapsConfig.apiKeyText;
            } else {
                options["client"] = mapsConfig.clientId;
            }
        }
    }]);
});