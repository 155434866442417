define([
    "./intlPhoneNumberModule",
    "text!./nlgIntlPhoneNumber.html"
], function (intlPhoneNumberModule, template) {
    "use strict";

    return intlPhoneNumberModule.directive("nlgIntlPhoneNumber", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                countryData: "=",
                model: "=ngModel",
                ngDisabled: "<?",
                defaultCountry: "@"
            },
            require: "ngModel"
        };
    }]);
});