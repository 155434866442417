define(["./sortingModule", "../arrays/arrays", "text!./nlgSortingList.html"], function (sortingModule, arrays, template) {
    "use strict";

    /**
     * @ngdoc directive
     * @name sortingModule.directive:nlgSortingList
     * @description
     * Tabela ordenável com possibilidade de validação de movimentação de elementos da lista.
     *
     * @param {object[]=} ngModel Lista com os itens selecionado pelo usuário, exibidos.
     * @param {function()=} canMoveUp função que valida movimentação para cima do elemento
     * @param {function()=} canMoveDown função que valida movimentação para para baixo do elemento
     * @example
     * <example module="FrontEndWeb">
     *     <file name="index.html">
     *         <div ng-controller="example">
     *            <div ng-model="selection" can-move-up="canMoveUp(element)"
     *            can-move-down="canMoveDown(element)"></div>
     *            <ul>
     *                <li ng-repeat="item in selection">{{ item | entityFormat }}</li>
     *            </ul>
     *         </div>
     *     </file>
     *     <file name="index.js">
     *         angular.module("FrontEndWeb").controller("example", function ($scope) {
     *              $scope.selection = [];
     *         });
     *     </file>
     * </example>
     * */

    return sortingModule.directive("nlgSortingList", [function () {
        return {
            restrict: "A",
            template: template,
            scope: {
                ngModel: "=?ngModel",
                canMoveUp: "&?",
                canMoveDown: "&?",
                isActivated: "=?"
            },
            controller: ["$scope", function ($scope) {
                $scope.ngModel = $scope.ngModel || [];
                $scope.isActivated = $scope.isActivated === undefined  ?  true : $scope.isActivated ;

                $scope.canMoveUp = $scope.canMoveUp || function () {
                        return true;
                    };


                $scope.canMoveDown = $scope.canMoveDown || function () {
                        return true;
                    };

                $scope.availableItens = [];

                $scope.list = {
                    selection: []
                };

                $scope.moveSelectionToUp = function () {
                        move($scope.list.selection, move.UP);
                };

                $scope.moveSelectionToDown = function () {
                        move(arrays.copy($scope.list.selection).reverse(), move.DOWN);
                };

                $scope.isUpAble = function () {
                    var upIndex= $scope.ngModel.indexOf($scope.list.selection[0]) - 1;
                    if ( upIndex === null || upIndex <  0 || $scope.list.selection.length > 1) {
                        return false;
                    }
                    return $scope.canMoveUp({
                        selected: $scope.list.selection,
                        up: $scope.ngModel[upIndex]
                    });
                };

                $scope.isDownAble = function () {
                    var downIndex= $scope.ngModel.indexOf($scope.list.selection[0]) + 1;
                    if ( downIndex === null || downIndex >=  $scope.ngModel.length || $scope.list.selection.length > 1) {
                        return false;
                    }

                    return $scope.canMoveDown({
                        selected: $scope.list.selection,
                        down: $scope.ngModel[downIndex]
                    });
                };

                move.UP = -1;
                move.DOWN = 1;
                function move(selection, direction) {
                    var i;
                    for (i = 0; i < selection.length; i++) {
                        var oldIndex = $scope.ngModel.indexOf(selection[i]);
                        var upperIndex = oldIndex + direction;
                        if (upperIndex < 0 || upperIndex >= $scope.ngModel.length) {
                            return;
                        }
                        arrays.swap($scope.ngModel, upperIndex, oldIndex);
                    }
                }
            }]
        };
    }]);
});