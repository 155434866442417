define([
    "./GenericParamDataController",
    "./GenericParamDefinitionGroupEditionController",
    "./GenericParamWithEntityFilterService",
    "./TransferableGenericParamConfirmationController",
    "./genericParamAutocompleteService",
    "./genericParamDataDialog",
    "./genericParamDataEditionModal",
    "./genericParamDataRepeatedValue",
    "./genericParamDataService",
    "./genericParamMetadataService",
    "./genericParams",
    "./genericParamsModule",
    "./genericParamsUrls",
    "./nlgGenericParamData",
    "./nlgGenericParamDefinitionEdition",
    "./nlgGenericParamDefinitionGroupEdition",
    "./nlgGenericParamDefinitionInstructions/nlgGenericParamDefinitionInstructions",
    "./transferableGenericParam",
    "./transferableGenericParamService",
], function () {
});
