define(["./uiBootstrapModule"], function (uiBootstrapModule) {
    "use strict";

    return uiBootstrapModule.run(["$document", function ($document) {
        // O bootstrap padrão coloca um handler que simplesmente impede a propagação
        // em formulários dentro de dropdowns.
        // Este handler gerava problemas quando usado a diretiva 'dropdown' do ui-bootstrap
        // em conjunto com a 'dropdown-append-to-body', pois é adicionado a classe 'dropdown'
        // ao body da página. Assim, ao clicar dentro de um formulário na página, o dropdown não é
        // fechado.
        // A solução é remover o listener padrão e colocar um que apenas funciona para dentro do body.
        // pois ainda permite que hajam formulários dentro de dropdowns.
        $document.off("click", ".dropdown form");
        $document.on("click", "body .dropdown form", function (ev) {
            ev.stopPropagation();
        });
    }]);
});