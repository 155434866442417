define([
    "./nlgHealthCheckerModule",
    "text!./nlgHealthInfo.html",
], function (module, template) {
    "use strict";

    return module.directive("nlgHealthInfo", ["$translate", function ($translate) {
        return {
            template: template,
            scope: {
                instance: "<?",
                info: "<"
            },
            controller: ["$scope", "nlgHealthCheckerDetailsModal", "nlgHealthCheckerConfigurationModal", function ($scope, nlgHealthCheckerDetailsModal, nlgHealthCheckerConfigurationModal) {
                $scope.getStatusClass = function (status) {
                    if (status === "br.com.neolog.healthcheck.api.HealthCheckerStatus.OK") {
                        return "nlgHealthCheckerSuccess";
                    } else if (status === "br.com.neolog.healthcheck.api.HealthCheckerStatus.WARNING") {
                        return "nlgHealthCheckerWarning";
                    } else {
                        return "nlgHealthCheckerDanger";
                    }
                };

                $scope.getIcon = function(rawBundle){
                    var bundle = rawBundle + ".icon";
                    var result = $translate.instant(bundle);
                    if(result === bundle){
                        return "fa fa-tachometer-alt";
                    }
                    return result;
                };

                $scope.openModal = function(checker){
                    return nlgHealthCheckerDetailsModal($scope.instance, checker);
                };

                $scope.openConfigurationModal = function ($ev, checker) {
                    $ev.stopPropagation();
                    return nlgHealthCheckerConfigurationModal($scope.instance, checker);
                };
            }]
        };
    }]);
});
