define([
    "./threeModule"
], function (threeModule) {
    "use strict";

    return threeModule.service("OrthographicControl", ["$window", function ($window) {
        /**
         *
         * @param {THREE.Camera} cam
         * @param {Element} domElement
         * @constructor
         */
        function OrthographicControl(cam, domElement) {
            this.cam = cam;
            this.attachEvents(domElement.querySelector("canvas") || domElement);

            this.setCenter(0, 0, 0);
            this.setDimensions(1, 1, 1);
            this.setAspect(1);
            this.update();
        }

        OrthographicControl.prototype.setCenter = function (x, y, z) {
            this.center = {x: x, y: y, z: z};
        };

        OrthographicControl.prototype.setDimensions = function (width, height, length) {
            this.dimensions = {
                width: width,
                height: height,
                length: length
            };
        };

        OrthographicControl.prototype.setAspect = function (aspect) {
            this.aspect = aspect;
        };

        OrthographicControl.prototype.zoom = function (delta) {
            this.cam.zoom = Math.max(0, this.cam.zoom + delta);
        };

        OrthographicControl.prototype.update = function () {
            var max = this.dimensions.width / 2, aspect = this.aspect;
            this.cam.bottom = -max + this.center.y;
            this.cam.top = max + this.center.y;
            this.cam.left = -max * aspect + this.center.x;
            this.cam.right = max * aspect + this.center.x;
            this.cam.near = -this.dimensions.length - this.center.z;
            this.cam.far = this.dimensions.length + this.center.z;
            this.cam.updateProjectionMatrix();
        };

        OrthographicControl.prototype.attachEvents = function (domElement) {
            var self = this;
            domElement.addEventListener("mousewheel", function (ev) {
                if (ev.deltaY > 0) {
                    self.zoom(-0.1);
                } else {
                    self.zoom(+0.1);
                }
            });

            domElement.addEventListener("mousedown", function (ev) {
                var button = ev.which;
                $window.addEventListener("mousemove", drag);
                $window.addEventListener("mouseup", stopDrag);
                $window.addEventListener("mouseleave", stopDrag);

                function drag(ev) {
                    if (button === 1 || button === 3) {
                        self.center.x -= ev.movementX * 10 / self.cam.zoom;
                        self.center.y += ev.movementY * 10 / self.cam.zoom;
                    } else if (button === 2) {
                        self.zoom(- ev.movementY * 0.05);
                    }
                }

                function stopDrag() {
                    $window.removeEventListener("mousemove", drag);
                    $window.removeEventListener("mouseup", stopDrag);
                    $window.removeEventListener("mouseleave", stopDrag);
                }
            });
        };

        return OrthographicControl;
    }]);
});