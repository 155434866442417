define(["./timeModule", "text!./datePickerButton.html"], function (timeModule, template) {
    "use strict";

    return timeModule.directive("datePickerButton", ["dateService", function (dateService) {
        return {
            restrict: "E",
            template: template,
            scope: {
                ngDisabled: "=?ngDisabled"
            },
            require: "ngModel",
            link: function ($scope, $element, $attrs, ngModelController) {
                ngModelController.$formatters.push(function (modelValue) {
                    return modelValue;
                });

                ngModelController.$parsers.push(function (viewValue) {
                    return viewValue;
                });

                ngModelController.$render = function () {
                    $scope.model = ngModelController.$viewValue;
                };

                $scope.change = function () {
                    if (!dateService.dateEquals($scope.model, ngModelController.$viewValue)) {
                        ngModelController.$setViewValue($scope.model);
                    }
                };
            },
            controller: ["$scope", function ($scope) {
                $scope.popUp = {open: false};
                $scope.openPopUp = function () {
                    $scope.popUp.open = true;
                };
            }]
        };
    }]);
});