define(["./threeModule", "three"], function (threeModule, THREE) {
    "use strict";

    return threeModule.service("threeBoxMeshFactory", ["colorOfObject3D", function (colorOfObject3D) {
        var self = this;

        self.createMesh = function (object) {
            var width = object.dimensions.width;
            var height = object.dimensions.height;
            var depth = object.dimensions.length;

            var material = new THREE.MeshPhongMaterial({
                color: colorOfObject3D(object),
                specular: 0x999999,
                shininess: 7,
                side: THREE.DoubleSide
            });

            if (object.children && object.children.length > 0) {
                material.opacity = 0.2;
                material.transparent = true;
            }

            var mesh = new THREE.Mesh(getGeometryFromCache(width, height, depth), material);
            mesh.rotation.y = Math.PI / 2;
			if(object.rotation){
				mesh.rotateX(object.rotation.x);
				mesh.rotateY(object.rotation.y);
				mesh.rotateZ(object.rotation.z);				
			}
            return mesh;
        };

        var cache = {};
        function getGeometryFromCache(width, height, depth) {
            var cacheKey = width + "/" + height + "/" + depth;
            if (!cache[cacheKey]) {
                cache[cacheKey] = new THREE.BoxGeometry(width, height, depth);
            }
            return cache[cacheKey];
        }

        return self;
    }]);
});