define(["./mapsModule"], function (mapsModule) {
    "use strict";

    return mapsModule.factory("MoveStopAction", [
        "alterStopsService",
        "routingSelectionDialog",
        "OptimizationSource",
        "FlowType",
        function (alterStopsService, routingSelectionDialog, OptimizationSource, FlowType) {
            function MoveStopAction(fromTrip, toTrip, fromStopId, toStopId) {
                this.fromTrip = fromTrip;
                this.toTrip = toTrip;
                this.fromStopId = fromStopId;
                this.toStopId = toStopId;
            }

            MoveStopAction.prototype.perform = function () {
                var self = this;
                return routingSelectionDialog({
                    source: OptimizationSource.MANUAL_OPERATION,
                    flow: FlowType.ANALYSIS,
                    trips: [self.fromTrip.id, self.toTrip.id],
                    dus: [],
                    single: true
                }).then(function (response) {
                    var moveStopParameter = {
                        fromTrip: self.fromTrip,
                        toTrip: self.toTrip,
                        fromStopId: self.fromStopId,
                        toStopId: self.toStopId,
                        routingConfig: response.routingConfig
                    };
                    return alterStopsService.moveStop(moveStopParameter);
                });
            };
            return MoveStopAction;
        }]);
});