define([
    "./nlgLGPDModule"
], function (nlgLGPDModule) {
    "use strict";
    nlgLGPDModule.provider("nlgLGPD", [function () {
        var mainArtifactName;
        var filterServicePath;
        var sqlFilterServicePath;
        var availableServicePaths;
        var defaultPath;
        var attributePath;
        var classificationPath;
        var genericParamPath;

        this.setServicePath = function (path) {
            mainArtifactName = path.mainArtifactName;
            defaultPath = path.path;
            filterServicePath = path.filterServicePath;
            sqlFilterServicePath = path.sqlFilterServicePath;
            availableServicePaths = path.availableServices;
            attributePath = path.attributePath;
            classificationPath = path.classificationPath;
            genericParamPath = path.genericParamPath;
        };

        this.$get = [function () {
            return {
                getMainArtifactName: function() {
                    return mainArtifactName;
                },
                createLGPDFilterServicePath: function () {
                    return filterServicePath;
                },
                createLGPDSQLFilterServicePath: function () {
                    return sqlFilterServicePath;
                },
                getLGPDServiceNames: function () {
                    return availableServicePaths;
                },
                getLGPDDefaultPath: function () {
                    return defaultPath;
                },
                getAttributePath: function() {
                    return attributePath;
                },
                getClassificationPath: function() {
                    return classificationPath;
                },
                getGenericParamPath: function() {
                    return genericParamPath;
                },
            };
        }];
    }]);
});
