define([
    "./routingConfigModule",
    "text!./nlgRoutingSelection.html"
], function (operationModule, nlgRoutingSelectionTemplate) {
    "use strict";

    return operationModule.directive("nlgRoutingSelection", [function () {
        return {
            restrict: "E",
            template: nlgRoutingSelectionTemplate,
            scope: {
                routingConfigs: "=",
                model: "=",
                nlgRequired: "@?",
                showConverterDecorator: "<"
            },
            controller: ["$scope", "autocompleteFactory", "pathService", "$q", function ($scope, autocompleteFactory, pathService, $q) {
                $scope.model = {
                    routingConfig: null,
                    converterDecorator: null
                };
                $q.when($scope.routingConfigs("%%%")).then(function (routingConfigs) {
                    $scope.model.routingConfig = routingConfigs[0];
                });

                if($scope.showConverterDecorator){
                    $scope.converterDecorator = autocompleteFactory.lazyLoader(pathService.getPath("scripts"), [], {
                        "scriptType": "CPL_TO_ROUTING_CONVERTER_DECORATOR"
                    });
                }
            }]
        };
    }]);
});