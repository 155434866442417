define([
    "./filterModule",
    "./filterDomain",
    "./filterConfiguration",
    "./nlgFilter",
    "./nlgFilterEdit",
    "./nlgFilterSave",
    "./filterController",
    "./filterState",
    "./filterServiceFactory",
    "./genericAttributeFilterComponent",
    "./nlgBetween"
], function () {
});