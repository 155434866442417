define([
    "./autoRefreshModule",
    "text!./nlgAutoRefresh.html"
], function (module, template) {
    "use strict";

    var SECONDS = 1000;
    var MINUTES = 60 * SECONDS;
    var INTERVAL =  "Interval";

    return module.directive("nlgAutoRefresh", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                onRefresh: "&",
                interval: "<?",
                intervals: "<?",
                identifier: "@?",
            },
            controller: ["$scope", "$window", "$timeout", function ($scope, $window, $timeout) {
                var autoRefreshInterval;
                setInitialInterval();
                $scope.intervals = $scope.intervals || [0, 15 * SECONDS, 30 * SECONDS, MINUTES, 5 * MINUTES, 10 * MINUTES];

                createInterval();
                $scope.$on("$destroy", cancelInterval);

                $scope.toggleInterval = function (newInterval) {
                    $scope.interval = newInterval;
                    createInterval();
                    executeInterval();
                };

                function createInterval() {
                    cancelInterval();
                    localStorage.setItem(getLocalStorageName(), $scope.interval);
                    if ($scope.interval) {
                        autoRefreshInterval = $timeout(function () {
                            $window.requestAnimationFrame(function () {
                                executeInterval();
                                createInterval();
                            });
                        }, $scope.interval);
                    }
                }

                function getLocalStorageName(){
                    if ($scope.identifier){
                        return $scope.identifier + INTERVAL;
                    }
                    return INTERVAL;
                }

                function cancelInterval() {
                    $timeout.cancel(autoRefreshInterval);
                }

                function executeInterval() {
                    if ($scope.interval) {
                        $timeout($scope.onRefresh);
                    }
                }
                function setInitialInterval(){
                    if($scope.interval){
                        localStorage.setItem(getLocalStorageName(), $scope.interval);
                        return;
                    }
                    var currentInterval = parseInt(localStorage.getItem(getLocalStorageName()));
                    if(currentInterval){
                        $scope.interval = currentInterval;
                        return;
                    }
                    $scope.interval = 0;
                }
            }]
        };
    }]);
});