define(["angular",
    "codemirror/lib/codemirror",
    "codemirror/mode/clike/clike",
    "codemirror/mode/javascript/javascript",
    "codemirror/mode/sql/sql",
    "codemirror/addon/display/autorefresh",
    "angular-ui-codemirror",
    "../violation/violation",
], function (angular, codemirror) {
    "use strict";

    window.CodeMirror = codemirror;

    return angular.module("commonScriptModule", [
        "ui.codemirror",
        "violationModule"
    ]);
});