define([
    "./monitorModule",
    "lodash"
], function (module, _) {
    "use strict";

    return module.service("monitorMappingParserV2", [function () {
        return function createMappings(springBootMappingFormat) {
            return _.chain(springBootMappingFormat.contexts)
                .flatMap(function (context) {
                    return context.mappings.dispatcherServlets.dispatcherServlet;
                }).filter(function (dispatcherServlet) {
                    return !!dispatcherServlet.details;
                }).flatMap(function (dispatcherServlet) {
                    var details = dispatcherServlet.details;
                    return {
                        route: details.requestMappingConditions.patterns,
                        bean: dispatcherServlet.handler,
                        method: details.handlerMethod.className + "#" + details.handlerMethod.name + details.handlerMethod.descriptor,
                        extraInfo: {
                            methods: _.map(details.requestMappingConditions.methods, getMethodName),
                            consumes: _.map(details.requestMappingConditions.consumes, getMediaType),
                            produces: _.map(details.requestMappingConditions.produces, getMediaType),
                        }
                    };
                }).flatMap(function (details) {
                    return _.map(details.route, function (route) {
                        return {
                            route: route,
                            bean: details.bean,
                            method: details.method,
                            extraInfo: details.extraInfo
                        };
                    });
                })
                .flatten()
                .value();
        };

        function getMethodName(method) {
            return method.split(".").pop();
        }

        function getMediaType(consumes) {
            return consumes.mediaType;
        }
    }]);
});