define([
    "./genericParamsModule",
    "../objects/objects",
], function (crudModule, objects) {
    "use strict";

    return crudModule.service("genericParamAutocompleteService", [
        "autocompleteFactory",
        "$q",
        function (autocompleteFactory, $q) {
            return function (metadata, viewValue) {
                if (!metadata.serviceLink) {
                    return $q.when(metadata.possibleValues || []);
                }
                var componentMetadata = objects.enrich(metadata, {
                    additionalInfo: {}
                });
                var optionalArguments = {};
                if (metadata.additionalInfo.property) {
                    optionalArguments[componentMetadata.additionalInfo.property] = componentMetadata.additionalInfo.value;
                }
                var dependencies = metadata.dependencyInfo || [];
                return autocompleteFactory.lazyLoader(metadata.serviceLink, dependencies, optionalArguments)(viewValue);
            };
        }]);
});