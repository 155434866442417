define([
    "./monitorModule",
    "c3",
    "angular",
    "lodash",
    "text!./nlgMonitorMetricsV2.html"
], function (module, c3, angular, _, template) {
    "use strict";

    return module.directive("nlgMonitorMetricsV2", [function () {
        return {
            scope: {
                instance: "<",
                metricsNames: "="
            },
            template: template,
            controller: [
                "$scope",
                "monitorService",
                "loadingService",
                "remoteExceptionHandler",
                function ($scope, monitorService, loadingService, remoteExceptionHandler) {
                    var _selection = null;

                    $scope.isSelected = function (metricName) {
                        return _selection === metricName;
                    };

                    $scope.selectMapping = function (metricName) {
                        _selection = metricName;
                        return loadingService(monitorService.findEndpoint($scope.instance, "metrics/" + metricName))
                            .catch(remoteExceptionHandler())
                            .then(function (metric) {
                                $scope.metricData = metric;
                            });
                    };
                }],
        };
    }]);
});