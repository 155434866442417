define(["./arrangementModule", "angular", "../arrays/arrays", "three"], function (arrangementModule, angular, arrays, THREE) {
    "use strict";

    var MOUSE_LEFT_BUTTON = 1,
        PERSPECTIVE_CAMERA_NAME = "nlgArrangement.perspective",
        ORTHOGRAPHIC_XY_CAMERA_NAME = "nlgArrangement.orthographicXY",
        ORTHOGRAPHIC_ZY_CAMERA_NAME = "nlgArrangement.orthographicZY",
        ORTHOGRAPHIC_ZX_CAMERA_NAME = "nlgArrangement.orthographicZX";

    return arrangementModule.directive("nlgArrangementDrawer", ["OrthographicControl", "Object3DInfo", function (OrthographicControl, Object3DInfo) {
        return {
            restrict: "E",
            template: "<select class='nlgArrangementCameras' ng-options='camera as (camera.name | translate) for camera in cameras' ng-model='selectedCamera' ng-change='selectCamera(selectedCamera)'></select>",
            scope: {
                object: "=",
                selectedObjects: "=",
                onObjectClick: "&?",
                meshs: "=?",
                additionalObjects: "=?",
                onRegisterApi: "&?",
                viewType: "=",
                autoRotate: "=?"
            },
            controller: ["$scope", "$element", "arrangementDrawerService", "ThreeRenderLoop", function ($scope, $element, arrangementDrawerService, ThreeRenderLoop) {
                $scope.meshs = [];
                $scope.additionalObjects = [];
                $scope.combinedMeshes = [];

                var width = 150;
                var height = 500;
                $element.addClass("nlgArrangementDrawer").css({
                    "min-width": width,
                    "min-height": height,
                    "max-height": height
                });

                var scene = arrangementDrawerService.createBaseScene();

                var renderer = arrangementDrawerService.createRenderer(width, height);
                var domObject = renderer.getDomObject();
                var oldDomObjectParent = domObject.parentNode;
                $element.append(domObject);

                var camera = new THREE.PerspectiveCamera(60, width / height, 100, 5000000);
                /** @type {NlgArrangementDrawerCamera} */
                var perspectiveCamera = {
                    name: PERSPECTIVE_CAMERA_NAME,
                    impl: camera,
                    controls: new THREE.OrbitControls(camera, domObject),
                    reset: angular.noop
                };
                /** @type {NlgArrangementDrawerCamera} */
                var orthographicXYCamera = createOrthographicCamera(ORTHOGRAPHIC_XY_CAMERA_NAME);
                /** @type {NlgArrangementDrawerCamera} */
                var orthographicZYCamera = createOrthographicCamera(ORTHOGRAPHIC_ZY_CAMERA_NAME);
                /** @type {NlgArrangementDrawerCamera} */
                var orthographicZXCamera = createOrthographicCamera(ORTHOGRAPHIC_ZX_CAMERA_NAME);
                /** @type {NlgArrangementDrawerCamera[]} */
                $scope.cameras = [perspectiveCamera, orthographicXYCamera, orthographicZYCamera, orthographicZXCamera];

                var raycaster = new THREE.Raycaster();
                $scope.onClick = function (event) {
                    if (!$scope.onObjectClick) {
                        return;
                    }
                    var mouse = new THREE.Vector2((event.offsetX / getCanvasWidth()) * 2 - 1, -(event.offsetY / getCanvasHeight()) * 2 + 1);
                    raycaster.setFromCamera(mouse, renderLoop.camera);
                    var intersections = raycaster.intersectObjects(scene.children);
                    arrays.each(intersections, function (intersection) {
                        if (intersection.object.children.length) {
                            return arrays.each.CONTINUE;
                        }
                        var objectMesh = findObjectForMesh(intersection);
                        if (objectMesh !== null) {
                            if (event && (event.ctrlKey || event.shiftKey)) {
                                if ($scope.selectedObjects.contains(objectMesh)) {
                                    $scope.selectedObjects.remove(objectMesh);
                                } else {
                                    $scope.selectedObjects.add(objectMesh);
                                }
                            } else {
                                if (!$scope.selectedObjects.contains(objectMesh) || $scope.selectedObjects.size() > 1) {
                                    $scope.selectedObjects.clear().add(objectMesh);
                                } else {
                                    $scope.selectedObjects.clear();
                                }
                            }
                            recalcObjectHighlight();
                            $scope.onObjectClick({
                                renderedMesh: objectMesh
                            });
                            return arrays.each.BREAK;
                        }
                    });
                };

                function recalcObjectHighlight() {
                    var emptySelection = $scope.selectedObjects.isEmpty();
                    if (!emptySelection) {
                        arrays.each($scope.combinedMeshes, arrangementDrawerService.setOpacity.bind(null, 0.2));
                        if ($scope.combinedMeshes.orientationLine) {
                            arrangementDrawerService.setOpacity(0.2, $scope.combinedMeshes.orientationLine);
                        }
                        $scope.selectedObjects.each(function(object) {
                            arrangementDrawerService.setOpacity(1, object);
                        });
                    } else {
                        arrays.each($scope.combinedMeshes, function(object) {
                            arrangementDrawerService.setOpacity(getDefaultOpacity(object), object);
                        });
                        if ($scope.combinedMeshes.orientationLine) {
                            arrangementDrawerService.setOpacity(1, $scope.combinedMeshes.orientationLine);
                        }
                    }
                }

                var renderLoop = new ThreeRenderLoop(renderer).setScene(scene);

                $scope.selectCamera = function (camera) {
                    var implementation = camera.impl;
                    /** @type {NlgArrangementDrawerCamera} */
                    $scope.selectedCamera = camera;
                    camera.reset();
                    renderLoop.setCamera(implementation);
                };
                $scope.selectCamera($scope.cameras[0]);

                renderLoop.start(function () {
                    var width = getCanvasWidth();
                    var height = getCanvasHeight();
                    renderer.setSize(width, height);
                    camera.aspect = width / height;
                    camera.updateProjectionMatrix();

                    orthographicXYCamera.controls.setAspect(camera.aspect);
                    orthographicZYCamera.controls.setAspect(camera.aspect);
                    orthographicZXCamera.controls.setAspect(camera.aspect);
                    $scope.selectedCamera.controls.update();
                });

                if ($scope.onRegisterApi) {
                    $scope.onRegisterApi({
                        drawerApi: {
                            toBlob: function (callback) {
                                return renderer.renderAsBlob(scene, $scope.selectedCamera.impl, callback);
                            },
                            getWidth: function () {
                                return renderer.width;
                            },
                            getHeight: function () {
                                return renderer.height;
                            },
                            setSize: function (width, height) {
                                return renderer.setSize(width, height);
                            },
                            recalcObjectHighlight: recalcObjectHighlight,
                            enableRotate: function () {
                                /**
                                 * Não surtirá efeito quando `$scope.selectedCamera.controls` for do tipo {@link OrthographicControl}
                                 */
                                $scope.selectedCamera.controls.autoRotate = true;
                                $scope.selectedCamera.controls.update();
                            }
                        }
                    });
                }

                $scope.$on("$destroy", function () {
                    renderLoop.stop();
                    angular.element(oldDomObjectParent).append(domObject);
                });

                var firstTime = true;
                $scope.$watchGroup(["object", "viewType"], function (values) {
                    var container = values[0];
                    var viewType = values[1];
                    scene = arrangementDrawerService.createBaseScene();
                    renderLoop.setScene(scene);
                    if (angular.isDefined(container)) {
                        $scope.meshs = arrangementDrawerService.draw(scene, container, viewType);
                        $scope.additionalObjects = container.additionalObjects.flatMap(function(object) {
                            return arrangementDrawerService.draw(scene, object, viewType);
                        });
                        $scope.combinedMeshes = $scope.meshs.concat($scope.additionalObjects);
                        if (firstTime) {
                            perspectiveCamera.reset = function () {
                                perspectiveCamera.impl.position.x = container.center.x;
                                perspectiveCamera.impl.position.y = container.center.y;
                                perspectiveCamera.impl.position.z = cameraZ();
                                perspectiveCamera.controls.target.set(container.center.x, container.center.y, container.center.z);
                            };
                            perspectiveCamera.reset();

                            orthographicXYCamera.init(
                                container.center.x,
                                container.center.y,
                                container.dimensions.width,
                                container.dimensions.length,
                                container.dimensions.height,
                                container.dimensions.width);

                            orthographicZYCamera.init(
                                -container.center.z,
                                container.center.y,
                                container.dimensions.length,
                                container.dimensions.width,
                                container.dimensions.height,
                                container.dimensions.length);
                            orthographicZYCamera.impl.rotation.y = Math.PI / 2;

                            orthographicZXCamera.init(
                                container.center.x,
                                -container.center.z,
                                container.dimensions.height,
                                container.dimensions.length,
                                container.dimensions.width,
                                container.dimensions.height);
                            orthographicZXCamera.impl.rotation.x = -Math.PI / 2;

                            firstTime = false;
                        }
                    }

                    function cameraZ() {
                        var center = container.center.z;
                        var nearestContainerSide = container.dimensions.width / 2;
                        var distanceToFitContainer = container.dimensions.length * camera.far / farWidth();
                        return center + nearestContainerSide + distanceToFitContainer;
                    }

                    function farWidth() {
                        var aspect = 1;
                        return farHeight() * aspect;
                    }

                    function farHeight() {
                        return 2 * camera.far * Math.tan(THREE.Math.degToRad(camera.fov) / 2);
                    }
                });

                function getCanvasWidth() {
                    return $element.innerWidth() - 5;
                }

                function getCanvasHeight() {
                    return height;
                }

                function findObjectForMesh(intersection) {
                    var values;
                    if (!(intersection.object.material instanceof THREE.MultiMaterial)) {
                        values = arrays.filter($scope.combinedMeshes, function (value) {
                            return value.mesh.material === intersection.object.material && !value.object.children;
                        });
                        if (values.length !== 1) {
                            return null;
                        }
                        return values[0];
                    }

                    var materialIndex = intersection.face.materialIndex;
                    if (angular.isUndefined(materialIndex) || materialIndex === null) {
                        return null;
                    }

                    var material = intersection.object.material.materials[intersection.face.materialIndex];
                    values = arrays.filter($scope.combinedMeshes, function (value) {
                        return value.mesh.material === material && !value.object.children;
                    });
                    if (values.length !== 1) {
                        return null;
                    }
                    return values[0];
                }

                function createOrthographicCamera(name) {
                    var _x, _y, _z, _width, _height, _length;
                    var impl = new THREE.OrthographicCamera(-1, 1, 1, -1, -1, 1);
                    var controls = new OrthographicControl(impl, domObject);
                    return {
                        name: name,
                        impl: impl,
                        controls: controls,
                        init: function (x, y, z, width, height, length) {
                            _x = x;
                            _y = y;
                            _z = z;
                            _width = width;
                            _height = height;
                            _length = length;
                            this.reset = function () {
                                impl.zoom = 1;
                                controls.setCenter(_x, _y, _z);
                                controls.setDimensions(_width, _height, _length);
                            };
                            this.reset();
                        },
                        reset: angular.noop()
                    };
                }

                function getDefaultOpacity(object) {
                    var opacity = arrangementDrawerService.getExtraInfo(Object3DInfo.OPACITY, object);
                    if (angular.isNumber(opacity)) return opacity;
                    return 1;
                }

                /**
                 * @typedef {object} NlgArrangementDrawerCamera
                 * @property {string} name
                 * @property {THREE.Camera} impl
                 * @property {object} controls
                 * @property {Function} reset
                 */
            }],
            link: function ($scope, $element) {
                $element.mousedown(function (event) {
                    if (event.which !== MOUSE_LEFT_BUTTON) {
                        return;
                    }

                    var initialPosition = {pageX: event.pageX, pageY: event.pageY};

                    $element.on("mousemove", onMouseMove);
                    $element.one("mouseup", onClick);

                    function onClick(event) {
                        detach();
                        $scope.$apply($scope.onClick.bind($scope, event));
                    }

                    function onMouseMove(event) {
                        if (initialPosition.pageX === event.pageX && initialPosition.pageY === event.pageY) {
                            return;
                        }
                        detach();
                    }

                    function detach() {
                        $element.off("mousemove", onMouseMove);
                        $element.off("mouseup", onClick);
                    }
                });
            }
        };
    }]);
});