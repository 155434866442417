define([
    "../nlgHealthCheckerModule",
    "text!./nlgHealthCheckerEntityListReason.html",
], function (module, template) {
    "use strict";

    return module.directive("nlgHealthCheckerEntityListReason", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                reason: "<"
            },
            controller: ["$scope", "nlgTagInputLinkService", function ($scope, nlgTagInputLinkService) {
                $scope.createLink = nlgTagInputLinkService.createLink;
                $scope.paginationOptions = {
                    records: [],
                    pagination: {
                        onPaginate: function (firstResult, maxResults) {
                            return {
                                firstResult: firstResult,
                                maxResults: maxResults,
                                count: $scope.reason.length,
                                result: $scope.reason.slice(firstResult, firstResult + maxResults)
                            };
                        }
                    },
                    onRegisterPagination: function (api) {
                        // carrega primeira p�gina assim que abre a diretiva
                        api.seekPage(1);
                    }
                };
            }]
        };
    }]);
});
