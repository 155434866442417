define([
    "./moveTripsToUserModule",
    "text!./moveTripsToUserModal.html"
], function (moveTripsToUserModule, template) {
    "use strict";
    return moveTripsToUserModule.provider("moveTripsToUserModal", [function () {
        var shouldLoadCurrentUser = false;

        this.shouldLoadCurrentUser = function (value) {
            shouldLoadCurrentUser = value;
        };

        this.$get = ["$http", "$modal", "autocompleteFactory", "remoteExceptionHandler", "loadingService", "pathService", "commonsUserService",
            function ($http, $modal, autocompleteFactory, remoteExceptionHandler, loadingService, pathService, commonsUserService) {
                return function (selection, isFromSelectView) {
                    return $modal.open({
                        template: template,
                        controller: ["$scope", "messagesModal", function ($scope, messagesModal) {
                            $scope.users = autocompleteFactory.lazyLoader(pathService.getPath("users"), [], {
                                "ignoreUserRestrictions": false
                            });

                            $scope.model = {
                                user: null
                            };

                            $scope.openSuccessMessageModal = function (message) {
                                return messagesModal("dialog.success", [message]);
                            };

                            if (shouldLoadCurrentUser) {
                                commonsUserService.getCurrentUser()
                                    .then(function (userDto) {
                                        $scope.model = {
                                            user: userDto
                                        };
                                    });
                            }


                            function isFromSelectionView(isFromSelectView) {
                                if (isFromSelectView) {
                                    return loadingService($http.post(pathService.getPath("move-selected-trip-from-user-to-another-user") + $scope.model.user.id, selection));
                                }
                                return loadingService($http.post(pathService.getPath("move-trip-to-user") + $scope.model.user.id, selection));
                            }


                            $scope.okPressed = function () {
                                isFromSelectionView(isFromSelectView)
                                    .catch(remoteExceptionHandler())
                                    .then(function () {
                                        $scope.$close();
                                    }).then(function () {
                                    return $scope.openSuccessMessageModal({
                                        keyBundle: "trip.movimentation.successful"
                                    });
                                });
                            };
                        }]
                    }).result;
                };
            }];
    }]);
});