define(["./nodeCommandsModule", "../arrays/arrays"], function (nodeCommandsModule, arrays) {
    "use strict";

    return nodeCommandsModule.service("nodeIdentifiers", [function () {
        return {
            toNodeIdentifier: toNodeIdentifier,
            toNodeIdentifiers: toNodeIdentifiers,
            toHierarchyIdentifier: toHierarchyIdentifier
        };

        function toNodeIdentifiers(nodes) {
            return arrays.map(nodes, function (node) {
                return toNodeIdentifier(node);
            });
        }

        function toNodeIdentifier(node) {
            return {
                id: node.id,
                type: node.type,
                version: node.version,
                targetClass: node.targetClass
            };
        }

        function toHierarchyIdentifier(nodes) {
            if (arrays.isArray(nodes)) {
                return arrays.map(nodes, toHierarchyIdentifier);
            }
            return {
                identifier: toNodeIdentifier(nodes),
                parent: nodes.$parent ? toHierarchyIdentifier(nodes.$parent) : null
            };
        }
    }]);
});