define([
    "./templateFactoryModule",
    "./templates/templateFactoryDefaultTemplate",
    "./templates/templateFactoryTextTemplate",
    "./templates/templateFactoryNumberTemplate",
    "./templates/templateFactoryPasswordTemplate",
    "./templates/templateFactorySingleTagInputTemplate",
    "./templates/templateFactoryCheckBoxTemplate",
    "./templates/templateFactoryRadioTemplate",
    "./templates/templateFactoryColorTemplate",
    "./templates/templateFactoryDateTimeTemplate",
    "./templates/templateFactoryDateTemplate",
    "./templates/templateFactoryTimeTemplate",
    "./templates/templateFactorySmallTimeTemplate",
    "./templates/templateFactoryDateIntervalTemplate",
    "./templates/templateFactoryDateTimeIntervalTemplate",
    "./templates/templateFactoryMultiSelectTemplate",
    "./templates/templateFactoryGeoTemplate",
    "./templates/templateFactoryPieTemplate",
    "./templates/templateFactoryRangeChartTemplate",
    "./templates/templateFactoryBarTemplate",
    "./templates/templateFactoryStackedBarTemplate",
    "./templates/templateFactoryHorizontalStackedBarTemplate",
    "./templates/templateFactoryTreemapTemplate",
    "./templates/templateFactoryTableTemplate",
    "./templates/templateFactorySeriesTemplate",
    "./templates/templateFactoryChartGroupTemplate",
    "./templates/templateFactoryDurationTemplate",
    "./templates/templateFactorySummaryTemplate",
    "./templates/templateFactoryEntityFormatTemplate",
    "./templates/templateFactoryMultiBarTemplate"
], function (templateFactoryModule) {
    "use strict";

    /**
     * @ngdoc service
     * @name templateFactory.templateFactoryMapper
     * @description
     * Serviço utilizado para determinar qual templateFactory que será usada para criar e preencher o template, dado
     * seu tipo.
     * */
    return templateFactoryModule.service("templateFactoryMapper", [
        "templateFactoryDefaultTemplate",
        "templateFactoryTextTemplate",
        "templateFactoryNumberTemplate",
        "templateFactoryPasswordTemplate",
        "templateFactorySingleTagInputTemplate",
        "templateFactoryCheckBoxTemplate",
        "templateFactoryRadioTemplate",
        "templateFactoryColorTemplate",
        "templateFactoryDateTimeTemplate",
        "templateFactoryDateTemplate",
        "templateFactoryTimeTemplate",
        "templateFactorySmallTimeTemplate",
        "templateFactoryDateIntervalTemplate",
        "templateFactoryDateTimeIntervalTemplate",
        "templateFactoryMultiSelectTemplate",
        "templateFactoryGeoTemplate",
        "templateFactoryPieTemplate",
        "templateFactoryRangeChartTemplate",
        "templateFactoryBarTemplate",
        "templateFactoryStackedBarTemplate",
        "templateFactoryHorizontalStackedBarTemplate",
        "templateFactoryTreemapTemplate",
        "templateFactoryTableTemplate",
        "templateFactorySeriesTemplate",
        "templateFactoryChartGroupTemplate",
        "templateFactoryDurationTemplate",
        "templateFactorySummaryTemplate",
        "templateFactoryEntityFormatTemplate",
        "templateFactoryMultiBarTemplate",
        function (templateFactoryDefaultTemplate,
                  templateFactoryTextTemplate,
                  templateFactoryNumberTemplate,
                  templateFactoryPasswordTemplate,
                  templateFactorySingleTagInputTemplate,
                  templateFactoryCheckBoxTemplate,
                  templateFactoryRadioTemplate,
                  templateFactoryColorTemplate,
                  templateFactoryDateTimeTemplate,
                  templateFactoryDateTemplate,
                  templateFactoryTimeTemplate,
                  templateFactorySmallTimeTemplate,
                  templateFactoryDateIntervalTemplate,
                  templateFactoryDateTimeIntervalTemplate,
                  templateFactoryMultiSelectTemplate,
                  templateFactoryGeoTemplate,
                  templateFactoryPieTemplate,
                  templateFactoryRangeChartTemplate,
                  templateFactoryBarTemplate,
                  templateFactoryStackedBarTemplate,
                  templateFactoryHorizontalStackedBarTemplate,
                  templateFactoryTreemapTemplate,
                  templateFactoryTableTemplate,
                  templateFactorySeriesTemplate,
                  templateFactoryChartGroupTemplate,
                  templateFactoryDurationTemplate,
                  templateFactorySummaryTemplate,
                  templateFactoryEntityFormatTemplate,
                  templateFactoryMultiBarTemplate) {
            return {
                "default": templateFactoryDefaultTemplate,
                "text": templateFactoryTextTemplate,
                "number": templateFactoryNumberTemplate,
                "password": templateFactoryPasswordTemplate,
                "singleTagInput": templateFactorySingleTagInputTemplate,
                "checkbox": templateFactoryCheckBoxTemplate,
                "radio": templateFactoryRadioTemplate,
                "color": templateFactoryColorTemplate,
                "datetime": templateFactoryDateTimeTemplate,
                "date": templateFactoryDateTemplate,
                "time": templateFactoryTimeTemplate,
                "sm-time": templateFactorySmallTimeTemplate,
                "dateInterval": templateFactoryDateIntervalTemplate,
                "dateTimeInterval": templateFactoryDateTimeIntervalTemplate,
                "multiSelect": templateFactoryMultiSelectTemplate,
                "geo": templateFactoryGeoTemplate,
                "pie": templateFactoryPieTemplate,
                "rangeChart": templateFactoryRangeChartTemplate,
                "bar": templateFactoryBarTemplate,
                "stackedBar": templateFactoryStackedBarTemplate,
                "horizontalStackedBar": templateFactoryHorizontalStackedBarTemplate,
                "treemap": templateFactoryTreemapTemplate,
                "table": templateFactoryTableTemplate,
                "series": templateFactorySeriesTemplate,
                "chartGroup": templateFactoryChartGroupTemplate,
                "duration": templateFactoryDurationTemplate,
                "summary": templateFactorySummaryTemplate,
                "entityFormat": templateFactoryEntityFormatTemplate,
                "multiBar": templateFactoryMultiBarTemplate
            };
        }]);
});
