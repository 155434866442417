define(["./dashboardModule", "text!./nlgDashboardLayout.html"], function (dashboardModule, template) {
    "use strict";

    return dashboardModule.directive("nlgDashboardLayout", [function () {
        return {
            restrict: "E",
            scope: {
                layout: "<content",
                layoutApi: "<",
                templateScope: "<"
            },
            template: template,
            controller: ["$scope", function ($scope) {
                $scope.getColumnClass = function (row) {
                    return "col-xs-12 col-lg-" + (12 / row.columns.length);
                };
            }]
        };
    }]);
});