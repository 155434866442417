define(["../nlgHealthCheckerModule"], function (module) {
    "use strict";

    var prefix = "br.com.neolog.healthcheck.api.reason.TypeFormat.";

    return module.constant("TypeFormat", {
        BYTES: prefix + "BYTES",
        PERCENTAGE: prefix + "PERCENTAGE",
        NUMBER: prefix + "NUMBER",
        WEIGHT: prefix + "WEIGHT",
        VOLUME: prefix + "VOLUME",
        DURATION: prefix + "DURATION",
        CURRENCY: prefix + "CURRENCY",
    });
});
