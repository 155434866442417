define([
    "./commonScriptModule",
], function (module) {
    "use strict";

    return module.provider("scriptUrls", [function () {
        var urls = {
            autocompleteUrl: "scriptUrls.autocompleteUrl",
            scriptValidator: "scriptUrls.scriptValidator",
            scriptTypes: "scriptUrls.scriptTypes",
            requiredGenericParams: "scriptUrls.requiredGenericParams",
        };
        this.setUrls = function (_urls) {
            urls = _urls;
        };
        this.$get = [function () {
            return urls;
        }];
    }]);
});