define(["./nodeCommandsModule"], function (nodeCommandsModule) {
    "use strict";

    return nodeCommandsModule.service("nodeCommandViewRoute", [
        "mapsRouteModal",
        "routeService",
        "remoteExceptionHandler",
        function (mapsRouteModal, routeService, remoteExceptionHandler) {
            return function (selection, context) {
                if (selection && selection.length > 0) {
                    routeService.getRouteFromNodeIdentifiers(selection).then(function (routes) {
                        mapsRouteModal.programmable(routes, context);
                    }).catch(remoteExceptionHandler());
                }
            };
        }
    ]);
});