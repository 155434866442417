define(["./nlgAnalysisTreeModule", "../arrays/arrays"], function (nlgAnalysisTreeModule, arrays) {
    "use strict";

    function normalize(messageKey) {
        var attributeName = messageKey
            .replace(/^presentationnode\.nodeproperty\./, "")
            .replace(/\./g, "-")
            .replace(/\s/g, "_");
        if (String.prototype.normalize !== undefined) {
            attributeName = attributeName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }
        return "data-" + attributeName;
    }


    /**
     * @ngdoc directive
     * @name nlgAnalysisTreeModule.directive:nlgAnalysisTreeAdditionalInfo
     * @element any
     * @restrict A
     * @description
     * Adiciona as propriedades de um `node` como atributos tipo `data` do elemento em que
     * esta diretiva é usada.
     */
    return nlgAnalysisTreeModule.directive("nlgAnalysisTreeAdditionalInfo", ["nodePropertyFormatter", function (nodePropertyFormatter) {
        return {
            restrict: "A",
            link: function ($scope, $element, $attrs) {
                var attrNames = [];
                $scope.$watch($attrs.nlgAnalysisTreeAdditionalInfo, function (node) {
                    if (!node) {
                        return;
                    }

                    $element.attr("data-node-type", (node.type || {}).name);
                    $element.attr("data-node-id", node.id);
                    $element.attr("data-presentation-id", node.presentationId);

                    arrays.each(attrNames, function (attrName) {
                        $element.attr(attrName, null);
                    });

                    attrNames = arrays.map(node.properties || [], function (property) {
                        var attrName = normalize(property.messageKey);
                        $element.attr(attrName, nodePropertyFormatter(property));
                        return attrName;
                    });
                });
            }
        };
    }]);
});