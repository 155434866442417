define([
    "./remoteModule",
    "./remoteModuleConfig",
    "./remoteLogger",
    "./remoteUrlNormalizer",
    "./loadingService",
    "./remoteExceptionHandler",
    "./downloadBinary",
    "./upload",
    "./uploadService"
],function(){});