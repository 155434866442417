define(["./nlgPermissionModule"], function (nlgPermissionModule) {
    "use strict";

    /**
     * @ngdoc directive
     * @name directives.directive:nlgPermission
     * @description
     * Diretiva para ocultar/exibir elementos, dado a permissão do usuário.
     *
     * @property {string} nlgPermission Nome do grupo que irá determinar se o elemento será visível.
     * @example
     * <pre>
     *     <div nlg-permission="grupo.secreto">
     *         Conteúdo secreto!
     *     </div>
     * </pre>
     * A `div` será ocultada caso o usuário não tenha permissão de leitura para o `grupo.secreto`
     * */
    return nlgPermissionModule.directive("nlgPermission", [function () {
        return {
            restrict: "A",
            controller: ["$attrs", "$element", "permissionService", function ($attrs, $element, permissionService) {
                $element.hide();
                permissionService.canRead($attrs.nlgPermission).then(function (isEnabled) {
                    if (isEnabled) {
                        $element.show();
                    }
                });
            }]
        };
    }]);
});
