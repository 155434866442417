define(["angular"], function (angular) {
    "use strict";

    /**
     * @ngdoc overview
     * @name collectionsModule
     * @description
     * Utilidades referentes à coleções
     * */
    return angular.module("collectionsModule", []);
});
