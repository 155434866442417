define([
    "./healthCheckerRouter",
    "./history/HealthCheckerHistoryController",
    "./history/healthCheckerHistoryGraphFactory",
    "./history/nlgHealthCheckerHistory",
    "./history/nlgHealthCheckerHistoryGraph",
    "./nlgHealthChecker",
    "./nlgHealthCheckerConfiguration",
    "./nlgHealthCheckerConfigurationModal",
    "./nlgHealthCheckerDetails",
    "./nlgHealthCheckerDetailsModal",
    "./nlgHealthCheckerModule",
    "./nlgHealthCheckerQuickFixActions",
    "./nlgHealthCheckerService",
    "./nlgHealthInfo",
    "./nlgHealthInfoDetailed",
    "./reason/TypeFormat",
    "./reason/nlgHealthCheckCompositeHealthInfoReason",
    "./reason/nlgHealthCheckReason",
    "./reason/nlgHealthCheckerApplicationErrorCountReason",
    "./reason/nlgHealthCheckerBarReason",
    "./reason/nlgHealthCheckerMultiBarReason",
    "./reason/nlgHealthCheckerEntityListReason",
    "./reason/nlgHealthCheckerErrorLevelCountReason",
    "./reason/nlgHealthCheckerPercentageRangeReason",
    "./reason/nlgHealthCheckerPieReason",
    "./reason/nlgHealthCheckerTableReason",
    "./reason/typeFormatService"
], function () {
});