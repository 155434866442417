define([
    "angular",
    "./mapsModule"
], function (angular, mapsModule) {
    "use strict";

    return mapsModule.service("routeStopPopupService", [function () {
        var popupCreatorDelegate;

        return {
            setDelegate: function(popupCreatorCallback){
                if(! angular.isFunction(popupCreatorCallback)){
                    throw new Error("Stop popup creator delegate must be a function");
                }
                popupCreatorDelegate = popupCreatorCallback;
            },
            createStopPopup: function(route, stop){
                if(!popupCreatorDelegate){
                    throw new Error("Stop popup creator delegate must be defined");
                }
                return popupCreatorDelegate(route, stop);
            }
        };
    }]);
});