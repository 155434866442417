/**
 * Module TagInputDirectiveFactory
 *
 */
define(["angular",
    "../arrays/arrays",
    "../objects/objects",
    "./nlgTagInputModule",
    "text!./nlgTagInput.html"
], function TagInputDirectiveFactory(angular, arrays, objects, nlgTagInputModule, nlgTagInputTemplate) {
    "use strict";
    var each = arrays.each;

    var ESCAPE_FILTER = "%%%";
    var delimiter = ",";

    function isDropDownOpen() {
        var element = angular.element(".dropdown-menu:visible");
        return element.length ? element.isolateScope().isOpen() : false;
    }

    function getModelAsString(scope) {
        if (angular.isArray(scope.model)) {
            var modelString = scope.doFormat(scope.model[0]);
            var i;
            for (i = 1; i < scope.model.length; i++) {
                modelString = modelString + "," + scope.doFormat(scope.model[i]);
            }
            return modelString;
        }
        return scope.doFormat(scope.model);
    }

    return {
        createDirective: function (directiveName, controller) {
            return nlgTagInputModule.directive(directiveName, [
                "$timeout",
                "$q",
                "fontUtils",
                "entityFormatFilter",
                "$window",
                "$rootScope",
                "nlgTagInputLinkService",
                function ($timeout, $q, fontUtils, entityFormat, $window, $rootScope, nlgTagInputLinkService) {
                    return {
                        restrict: "EA",
                        template: nlgTagInputTemplate,
                        replace: true,
                        require: "ngModel",
                        scope: {
                            model: "=?ngModel",
                            source: "<?",
                            placeholder: "@?",
                            formatTag: "&?",
                            getTags: "&?",
                            typeAheadMinLength: "@?",
                            typeAheadWaitMs: "@?",
                            disabled: "<?ngDisabled",
                            comparator: "&?",
                            showIcon: "@?",
                            translation: "=?",
                        },
                        controller: controller,
                        link: function (scope, element, attrs, ngModelController) {
                            delimiter = new RegExp("[" + (attrs.delimiters || ",\\n") + "]");
                            var ENTER = 13,
                                BACKSPACE = 8,
                                TAB = 9,
                                input = element.find("input");

                            //Override the standard $isEmpty because an empty array means the input is empty.
                            var originalIsEmpty = ngModelController.$isEmpty;
                            ngModelController.$isEmpty = function (value) {
                                return originalIsEmpty.call(ngModelController, value) || (arrays.isArray(value) && value.length === 0);
                            };

                            // o angular sempre cria uma getTags no $scope,
                            // mesmo que o atributo não tenha sido fornecido,
                            // devido ao "&?"
                            scope.getTagsInUse = !!attrs.getTags;
                            if (!scope.getTagsInUse) {
                                scope.doGetTags = function defaultGetTags() {
                                    return scope.source;
                                };
                            }

                            if (angular.isUndefined(scope.translation)) {
                                scope.translation = true;
                            }

                            if (attrs.formatTag) {
                                scope.doFormat = scope.formatTag;
                            } else {
                                scope.doFormat = function defaultFormatter(object) {
                                    if (angular.isDefined(object)) {
                                        var tag = object.tag || object;
                                        var humanString = scope.translation ? entityFormat(tag) : tag;
                                        if (!angular.isString(humanString)) {
                                            return "";
                                        }
                                        if (tag.sourceId && tag.sourceId !== humanString) {
                                            return humanString + " - " + tag.sourceId;
                                        }
                                        return humanString;
                                    }
                                    return "";
                                };
                            }

                            /**
                             * Indica que as opções do typeahead estão sendo carregadas.
                             */
                            scope.loading = false;

                            // faz a filtragem dos valores retornados pela promessa.
                            (function checkDoGetTagsResult(oldFunction) {
                                scope.doGetTags = function (parameters) {
                                    scope.loading = true;
                                    return $q
                                        .when(oldFunction.apply(scope, arrays.copy(arguments)))
                                        .then(checkArray)
                                        .then(function (array) {
                                            if (arrays.isArray(scope.model)) {
                                                return arrays.filter(array, function (item) {
                                                    return !arrays.contains(scope.model, item);
                                                });
                                            } else if (scope.model) {
                                                return arrays.filter(array, function (item) {
                                                    return !angular.equals(item, scope.model);
                                                });
                                            }
                                            return array;
                                        })
                                        .then(function (array) {
                                            if (parameters.viewValue !== ESCAPE_FILTER) {
                                                return arrays.filter(array, function (entity) {
                                                    var translated = scope.doFormat({tag: entity});
                                                    return translated.toLowerCase().indexOf(parameters.viewValue.toLowerCase()) >= 0;
                                                });
                                            }
                                            return array;
                                        })
                                        .then(function (array) {
                                            var comparator = scope.comparator;
                                            if (!comparator) {
                                                comparator = function (params) {
                                                    return objects.compare(scope.doFormat({tag: params.left}), scope.doFormat({tag: params.right}));
                                                };
                                            }
                                            return arrays.copy(array).sort(function (left, right) {
                                                return comparator({
                                                    left: left,
                                                    right: right
                                                });
                                            });
                                        })
                                        .finally(function () {
                                            scope.loading = false;
                                        });
                                };

                                function checkArray(data) {
                                    if (!arrays.isArray(data)) {
                                        throw new Error("getTags must return an array: " + data);
                                    }
                                    return data;
                                }
                            }(scope.doGetTags));

                            scope.createServiceLink = nlgTagInputLinkService.createLink;

                            element.on("mouseup focus", function () {
                                input.focus();
                            });

                            element.on("mouseup", ".tag", function (ev) {
                                ev.stopPropagation();
                            });

                            element.on("copy", function (ev) {
                                var clipboardData = null;
                                if (ev.originalEvent && ev.originalEvent.clipboardData) {
                                    clipboardData = ev.originalEvent.clipboardData;
                                } else if ($window.clipboardData) {
                                    // IE11
                                    clipboardData = $window.clipboardData;
                                }
                                if (clipboardData) {
                                    clipboardData.setData("text", getModelAsString(scope));
                                }
                                ev.preventDefault();
                            });

                            // Define estilo do div emulando form-control:focus
                            input.on("focus", function () {
                                element.addClass("form-control-focus");
                            });
                            input.on("blur", function () {
                                element.removeClass("form-control-focus");
                            });

                            input.on("keyup", function (ev) {
                                if (ev.keyCode === ENTER || delimiter.test(input.val())) {
                                    scope.$apply(parseInput);
                                }
                            });
                            input.on("keydown", function (ev) {
                                if (ev.keyCode === BACKSPACE) {
                                    scope.$apply(function () {
                                        if (input.val().length === 0) {
                                            scope.removeTag();
                                        }
                                    });
                                } else if (ev.keyCode === ENTER) {
                                    // impede que o formulário seja submetido
                                    ev.preventDefault();
                                }
                            });

                            // Previne a digitação quando já estiver completo.
                            // Permite, entretanto, remover tags presentes e navegar entre campos.
                            input.on("keydown", function (event) {
                                if (!scope.isComplete()) {
                                    return true;
                                }
                                return !!(event.keyCode === BACKSPACE || event.keyCode === TAB);

                            });

                            input.on("paste", function ($event) {
                                // Tratamento para utilizar quebra de linha como separador
                                $event.preventDefault();
                                var clipboardData = null;
                                if ($event.originalEvent && $event.originalEvent.clipboardData) {
                                    clipboardData = $event.originalEvent.clipboardData;
                                } else if ($window.clipboardData) {
                                    // IE11
                                    clipboardData = $window.clipboardData;
                                }
                                if (clipboardData) {
                                    $timeout(parseValue.bind(null, clipboardData.getData("text")), 0);
                                } else {
                                    // Browser não suporta clipboardData: desconsidera quebra de linha como separador
                                    $timeout(parseInput, 0);
                                }
                            });

                            input.on("blur", function () {
                                if (!isDropDownOpen()) {
                                    if ($rootScope.$$phase) {
                                        parseInput();
                                    } else {
                                        scope.$apply(parseInput);
                                    }
                                }
                            });

                            function parseInput() {
                                parseValue(input.val().trim());
                            }

                            function parseValue(viewData) {
                                if (viewData.length > 0) {
                                    var strings = viewData.split(delimiter);
                                    each(strings, function () {
                                        scope.addTagString(this.trim());
                                    });
                                }
                                scope.viewData = null;
                                input.val("");
                            }

                            input.on("input", correctInputWidth);
                            input.on("blur", function () {
                                input.css("width", 0);
                            });
                            correctInputWidth();
                            function correctInputWidth() {
                                var stringWidth = fontUtils.stringWidth(input.val() + " ", input) + 10;
                                input.css("width", stringWidth + "px");
                            }

                            element.on("keydown", onArrow.bind(element, onArrowDown));
                            element.on("keydown", onArrow.bind(element, onArrowUp));

                            function onArrow(handler, evt) {
                                if (evt.which === handler.keyCode) {
                                    var dropDownMenu = angular.element("[typeahead-popup]:visible");
                                    var selectedItem = angular.element("[typeahead-popup] li.active");
                                    handler(dropDownMenu, selectedItem);
                                }
                            }

                            function onArrowDown(dropDownMenu, selectedItem) {
                                var selectedItemPosition = selectedItem.position();
                                if (selectedItemPosition && selectedItemPosition.top > dropDownMenu.height() - selectedItem.height()) {
                                    // Detectou-se um avanço sobre as opções visíveis: scrolla o diálogo em um nível para baixo
                                    dropDownMenu.scrollTop(dropDownMenu.scrollTop() + selectedItem.height());
                                } else if (selectedItemPosition && selectedItemPosition.top < 0) {
                                    // Overflow, volta ao início
                                    dropDownMenu.scrollTop(0);
                                }
                            }

                            onArrowDown.keyCode = 40;

                            function onArrowUp(dropDownMenu, selectedItem) {
                                var selectedItemPosition = selectedItem.position();
                                if (selectedItemPosition && selectedItemPosition.top < 0) {
                                    dropDownMenu.scrollTop(dropDownMenu.scrollTop() - selectedItem.height());
                                } else if (selectedItemPosition && dropDownMenu.find("li").length - 1 === selectedItem.index()) {
                                    // Underflow, scroll para visualizar a última opção
                                    dropDownMenu.scrollTop(selectedItem.index() * selectedItem.height());
                                }
                            }

                            onArrowUp.keyCode = 38;

                            scope.updateModel = function (newValue) {
                                if (!angular.equals(ngModelController.$modelValue, newValue)) {
                                    var copy = angular.copy(newValue);
                                    ngModelController.$setViewValue(copy);
                                    ngModelController.$commitViewValue();
                                    scope.model = copy;
                                }
                            };

                            ngModelController.$viewChangeListeners.push(function () {
                                scope.$eval(attrs.ngChange);
                            });
                        }
                    };
                }
            ])
                .directive("typeaheadFocus", function () {
                    return {
                        require: "ngModel",
                        link: function (scope, element, attr, modelCtrl) {
                            //trigger the popup on 'click' because 'focus'
                            //is also triggered after the item selection
                            //element.parent().on("click", forceTypeahead);
                            var parentDiv = element.parent();
                            var input = parentDiv.find("input");

                            parentDiv.on("dblclick", forceTypeahead);
                            element.on("keydown", function (ev) {
                                if (ev.keyCode === 40 && !isDropDownOpen()) {
                                    forceTypeahead(ev);
                                }
                            });

                            var caret = parentDiv.find(".caret-pointer");
                            if (caret.length) {
                                // Adiciona botão para dropdown
                                caret.on("click", forceTypeahead);
                            }

                            function forceTypeahead(event) {
                                event.stopPropagation();
                                if (scope.isComplete()) {
                                    return;
                                }
                                // Dispara click para forçar blur em eventual outro tagInput com typeahead visível
                                input.trigger("click");

                                var viewValue = modelCtrl.$viewValue;
                                modelCtrl.$setViewValue(viewValue === ESCAPE_FILTER ? null : ESCAPE_FILTER);
                                if (viewValue) {
                                    modelCtrl.$setViewValue(viewValue);
                                }
                                modelCtrl.$commitViewValue();
                            }
                        }
                    };
                });
        }
    };
});
