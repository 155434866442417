define(["require", "./nlgOperationOverviewModule"], function (require, nlgOperationOverviewModule) {
    "use strict";

    return nlgOperationOverviewModule.directive("nlgOperationOverview", [function () {
        return {
            restrict: "E",
            templateUrl: require("link!./nlgOperationOverview.html"),
            scope: {
                instance: "@instance",
                healthCheckerName: "@healthCheckerName",
                additionalConfig: "=?",
                registerApi: "&?"
            },
            controller: ["$scope", "nlgOperationOverviewService",
                function ($scope, nlgOperationOverviewService) {
                    if ($scope.registerApi) {
                        $scope.registerApi({api: {refresh: refresh}});
                    }
                    $scope.filter = {};

                    (function getChecker() {
                        return nlgOperationOverviewService.getChecker()
                            .then(function (response) {
                                $scope.filter.checkerName = response.data;
                            })
                            .then(refresh);
                    })();

                    $scope.checkerTypeSelected = function () {
                        if ($scope.filter.checkerName) {
                            refresh();
                            updateChecker();
                        }
                    };

                    $scope.getCheckerNames = function () {
                        return nlgOperationOverviewService.getCheckerViews()
                            .then(function (response) {
                                return response.data;
                            });
                    };

                    $scope.formatTag = function formatTag(tag) {
                        return !tag ? "" : tag;
                    };

                    var lastProperty = null;

                    function refresh() {
                        var configuration;
                        if ($scope.filter.checkerName) {
                            lastProperty = $scope.filter.checkerName;
                        } else {
                            $scope.filter.checkerName = lastProperty;
                        }

                        if ($scope.additionalConfig) {
                            configuration = $scope.additionalConfig;
                            configuration.property = $scope.filter.checkerName;
                        } else {
                            configuration = {property: $scope.filter.checkerName};
                        }

                        return nlgOperationOverviewService.getHealthInfos($scope.instance, $scope.healthCheckerName, configuration)
                            .then(function (healthInfos) {
                                $scope.healthInfos = healthInfos;
                            });
                    }

                    function updateChecker() {
                        nlgOperationOverviewService.updateChecker($scope.filter.checkerName);
                    }
                }]
        };
    }]);
});

