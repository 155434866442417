define([
    "./dashboardModule"
], function (dashboardModule) {
    "use strict";

    dashboardModule.provider("dashboardDomain", [function () {
        var basePathByDomain = {};
        var defaultDomain;
        var dashboardPathsByDomain = {};

        this.$get = [function () {
            return {
                getBasePath: function (domain) {
                    return basePathByDomain[domain];
                },
                getDefaultDomain: function () {
                    return defaultDomain;
                },
                getDashboardPaths: function (domain) {
                    return dashboardPathsByDomain[domain];
                }
            };
        }];

        this.registerDomain = function (domain, path) {
            basePathByDomain[domain] = path;
        };

        this.setDefaultDomain = function (domain) {
            defaultDomain = domain;
        };

        this.setDashboardPaths = function (domain, paths) {
            dashboardPathsByDomain[domain] = paths;
        };
    }]);
});
