define([
    "angular",
    "text!./nlgFilterFieldInputTemplate.html"
], function (angular, template) {
    "use strict";

    return angular.module("nlgFilterFieldInputModule", [])
        .run(["$templateCache", function ($templateCache) {
            $templateCache.put("nlgFilterFieldInputTemplate.html", template);
        }]);
});
