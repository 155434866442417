define(["./nlgPortletModule",
    "text!./portlet.html"
], function (nlgPortletModule, portletTemplate) {
    "use strict";

    return nlgPortletModule.directive("nlgPortlet", function () {
        return {
            restrict: "A",
            template: portletTemplate,
            replace: true,
            transclude: true,
            scope: {
                collapsed: "=?nlgPortlet"
            },
            controller: ["$scope", function PortletController($scope) {
                $scope.collapsed = !!$scope.collapsed;

                this.isCollapsed = function () {
                    return $scope.collapsed;
                };

                this.toggleCollapse = function () {
                    $scope.collapsed = !$scope.collapsed;
                };

                this.setCollapsed = function (collapsed) {
                    $scope.collapsed = collapsed;
                };
            }]
        };
    });
});
