define([
    "./genericParamsModule",
], function (module) {
    "use strict";

    return module.provider("genericParamsUrls", [function () {
        var urls = {
            // Url base para acesso ao sistema
            basePath: "",
            // Url para controller para buscar e manipular dados de parametros generics
            genericParamDataUrl: "",
            // Url para buscar componentes adaptados para front. Necessario incluide {definition-id} no path
            genericParamComponents: "",
            // Url para buscar definitions
            definitionsServiceUrl: "",
        };
        this.setUrls = function (_urls) {
            urls = _urls;
        };
        this.$get = [function () {
            return urls;
        }];
    }]);
});