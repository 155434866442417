define(["./filterModule",
        "angular"],
    function (filterModule, angular) {
        "use strict";

        var nlgBetweenComponent = [function () {
            return {
                require: ["^^nlgBetween", "ngModel"],
                link: function ($scope, $element, $attrs, controllers) {
                    var nlgBetween = controllers[0];
                    var ngModelController = controllers[1];
                    ngModelController.$viewChangeListeners.push(nlgBetween.change);
                }
            };
        }];

        return filterModule.directive("nlgBetween", [function () {
            return {
                restrict: "A",
                scope: true,
                require: ["nlgBetween", "ngModel"],
                controller: ["$scope", function ($scope) {
                    var ngModelController = null;
                    var self = this;
                    self.init = function (_ngModelController) {
                        ngModelController = _ngModelController;
                        ngModelController.$viewChangeListeners.push(self.change);
                    };

                    self.change = function () {
                        if (!angular.equals($scope.between, ngModelController.$viewValue)) {
                            ngModelController.$setViewValue(angular.copy($scope.between));
                        }
                    };
                }],
                link: function BetweenController($scope, $element, $attrs, controllers) {
                    var ngModelController = controllers[1];
                    controllers[0].init(ngModelController);

                    $scope.between = {from: null, to: null};
                    $scope.change = controllers[0].change;

                    function isEmpty(value) {
                        return angular.isUndefined(value) || value === null;
                    }

                    ngModelController.$formatters.push(function (modelValue) {
                        modelValue = modelValue || [];
                        return {
                            from: modelValue[0],
                            to: modelValue[1]
                        };
                    });

                    ngModelController.$render = function () {
                        $scope.between = angular.copy(ngModelController.$viewValue);
                    };

                    ngModelController.$parsers.push(function (viewValue) {
                        var valid = isEmpty(viewValue.from) === isEmpty(viewValue.to);
                        ngModelController.$setValidity("fromOrToInvalid", valid);
                        if (!valid) {
                            return null;
                        }
                        return viewValue;
                    });

                    ngModelController.$parsers.push(function (viewValue) {
                        if (!viewValue) {
                            return null;
                        }
                        return [viewValue.from, viewValue.to];
                    });

                    ngModelController.$validators.validRange = function (modelValue, viewValue) {
                        var hasFrom = !undefinedOrNull(viewValue.from);
                        var hasTo = !undefinedOrNull(viewValue.to);
                        if (hasFrom !== hasTo) {
                            return false;
                        }
                        if (!hasFrom && !hasTo) {
                            return true;
                        }
                        return viewValue.from < viewValue.to;
                    };

                    $element.closest("form,ng-form").on("reset", function () {
                        $scope.between = {from: null, to: null};
                        $scope.change();
                    });

                    function undefinedOrNull(value) {
                        return angular.isUndefined(value) || value === null;
                    }
                }
            };
        }])
        .directive("nlgBetweenFrom", nlgBetweenComponent)
        .directive("nlgBetweenTo", nlgBetweenComponent);
    });
