define(["./remoteModule", "angular", "lodash"], function (remoteModule, angular, _) {
    "use strict";

    // FIXME somente tem suporte à IE10+, devido à File API (FormData)

    /**
     * @ngdoc service
     * @name remoteModule.uploadService
     * @description
     * Serviço que faz o upload de arquivos selecionados pelo usuário.
     * @param {object} param Parâmetros como definidos em `$http`.
     * @return {promise} promesa que é resolvida quando o arquivo termina de ser enviado.
     * */
    return remoteModule.service("uploadService", ["$http", function ($http) {
        var self = function (param) {
            if (!angular.isObject(param.data)) {
                throw new Error("data is not an object: " + param);
            }

            var formData = new FormData();
            angular.forEach(param.data, function (value, key) {
                if (_.isArray(value)) {
                    angular.forEach(value, function (value) {
                        formData.append(key, value);
                    });
                } else if (!_.isNil(value)) {
                    formData.append(key, value);
                }
            });

            return $http(angular.extend(param, {
                transformRequest: angular.identity,
                headers: {"Content-Type": undefined},
                data: formData
            }));
        };

        /**
         * @ngdoc service
         * @name remoteModule.uploadService#post
         * @methodOf remoteModule.uploadService
         * @description
         * Realiza uma requisição POST, com os dados fornecidos.
         *
         * @param {string} url Url da requisição.
         * @param {object} data Dados enviados para a requisição.
         * @return {promise} Promesa que é resolvida quando o arquivo termina de ser enviado.
         * */
        self.post = function (url, data) {
            return self({
                method: "POST",
                url: url,
                data: data
            });
        };

        return self;
    }]);
});