define(["./nodeCommandsModule"
], function (nodeCommandsModule) {
    "use strict";

    /* Node usado no monitoramento, envia o usuário da tela de painel de monitoramento para a tela de documentos
     */
    return nodeCommandsModule.service("monitoringNodeCommandDocumentView", ["$state", function ($state) {
        return function (selection) {
            if (selection && selection.length > 0) {
                var url = $state.href("monitoring.monitoringDocumentEdition", {
                    documentId: selection[0].id
                });
                window.open(url, "_blank");
            }
        };
    }]);
});