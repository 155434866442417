define([
    "./commonsOperationModule",
    "../arrays/arrays"
], function (commonsOperationModule, arrays) {
    "use strict";

    /**
     * @ngdoc service
     * @name programmingModule.preValidationService
     * @description
     * Serviço para execução de validação pré-operação.
     */
    return commonsOperationModule.factory("preValidationService", ["routingSelectionDialog", "$http", "remoteExceptionHandler", "preViolationModal", "loadingService", "$q", "FlowType", "pathService",
        function (routingSelectionDialog, $http, remoteExceptionHandler, preViolationModal, loadingService, $q, FlowType, pathService) {

            var routingConfigSelector;
            return {
                getViolationsForOptimization: getViolations.bind(null, pathService.getPath("preValidation-optimization")),
                getViolationsForManualOperation: getViolations.bind(null, pathService.getPath("preValidation-manualOperation")),
                setRoutingSelector: setRoutingConfigSelector
            };

            function setRoutingConfigSelector(routingSelector) {
                routingConfigSelector = routingSelector;
            }

            function getViolations(service, source, trips, deliveryUnits, preferredRoutingConfigSourceId, flowType, single) {
                var deliveryUnitsIds = arrays.map(deliveryUnits, function (nodeIdentifier) {
                    return nodeIdentifier.id;
                });
                var duIdentifierById = mapIdentifierById(deliveryUnits);
                var tripsIds = arrays.map(trips, function (nodeIdentifier) {
                    return nodeIdentifier.id;
                });
                var tripIdentifierById = mapIdentifierById(trips);

                return (routingConfigSelector || routingSelectionDialog)({
                    source: source,
                    flow: flowType,
                    trips: tripsIds,
                    dus: deliveryUnitsIds,
                    single: single,
                    preferredRoutingConfigSourceId: preferredRoutingConfigSourceId
                }).then(function (configs) {
                    function doValidate(tripNodeIdentifiersToValidate, deliveryUnitNodeIdentifiersToValidate) {
                        return loadingService($http.post(service, {
                            deliveryUnitNodeIdentifiers: deliveryUnitNodeIdentifiersToValidate,
                            routingConfigId: configs.routingConfig.id,
                            tripNodeIdentifiers: tripNodeIdentifiersToValidate
                        })).catch(function (reason) {
                            remoteExceptionHandler()(reason);
                            return $q.reject(reason);
                        });
                    }

                    return doValidate(trips, deliveryUnits)
                        .then(function showModalIfNecessary(tripsIds, deliveryUnitsIds, response) {
                            var validationResult = response.data;
                            if (!validationResult.hardViolations.length && !validationResult.softViolations.length) {
                                return {
                                    routingConfig: configs,
                                    entitiesToOperate: {
                                        trips: tripsIds,
                                        deliveryUnits: deliveryUnitsIds
                                    }
                                };
                            } else {
                                return preViolationModal.open(validationResult, configs, {
                                    trips: tripsIds,
                                    deliveryUnits: deliveryUnitsIds
                                }).catch(function onDismiss(reason) {
                                    if (reason && reason.status === "reload") {
                                        return doValidate(filterByIds(tripIdentifierById, reason.trips), filterByIds(duIdentifierById, reason.deliveryUnits))
                                            .then(showModalIfNecessary.bind(null, reason.trips, reason.deliveryUnits))
                                            .catch(onDismiss);
                                    } else {
                                        return $q.reject(reason);
                                    }
                                });
                            }
                        }.bind(null, tripsIds, deliveryUnitsIds));
                });

                function mapIdentifierById(identifiers) {
                    var result = {};
                    arrays.each(identifiers, function (identifier) {
                        result[identifier.id] = identifier;
                    });
                    return result;
                }

                function filterByIds(map, ids) {
                    return arrays.map(ids, function (id) {
                        return map[id];
                    });
                }
            }
        }]);
});