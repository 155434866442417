define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    return templateFactoryModule.service("templateFactoryGeoTemplate", [function () {
        return function (config) {
            var element = angular.element("<div>");
            if (config.context) {
                element.attr("geo-chart", config.context);
            }
            if (config.size) {
                element.attr("size", config.size);
            }
            return element;
        };
    }]);
});