define([
    "./commonScriptModule",
    "text!./nlgScriptEntityEdition.html"
], function (controllers, template) {
    "use strict";

    controllers.directive("nlgScriptEntityEdition", [function () {
        return {
            template: template,
            scope: {
                entity: "=",
                isEnabled: "&?"
            }
        };
    }]);
});