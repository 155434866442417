define([
    "./nodeCommandsModule",
    "./commonNodeCommandExecutor",
    "./nodeActions/nodePinAction",
    "./nodeActions/nodeDownloadAttachmentsAction",
    "./nodeActions/nodeCommandAction",
    "./nodeIdentifiers",
    "./NodeCommandExecutor",
    "./rootNodeCommandExecutorSupplier",
    "./monitoringNodeCommandDocumentView"
], function () {
});