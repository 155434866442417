define([
    "./chartingModule",
    "text!./treemapChart.html",
    "d3"
], function (chartingModule, template, d3) {
    "use strict";

    /**
     * @ngdoc directive
     * @name chartingModule.directive:treemapChart
     * @description
     * Diretiva que exibe um gráfico treemap
     *
     * @param {object[]=} treemapChart dados a serem representados no gráfico.
     * @param {string@?} width largura do gráfico em px.
     * @param {string@?} height altura do gráfico em px.
     *
     * @example
     * <example module="FrontEndWeb">
     *     <file name="index.html">
     *         <div ng-controller="example">
     *            <div treemap-chart="treemapChartInput"></div>
     *         </div>
     *     </file>
     *     <file name="index.js">
     *         angular.module("FrontEndWeb").controller("example", function ($scope) {
     *              $scope.treemapChartInput = (function () {
     *                  return {
     *                      configs: {
     *                          chartTitle: "The Chart Title",
     *                          type: "treemap"
     *                      },
     *                      data: [
     *                          {
     *                              label: "label1",
     *                              value: 623
     *                          },
     *                          {
     *                              label: "label2",
     *                              value: 465
     *                          },
     *                          {
     *                              label: "label3",
     *                              value: 984
     *                          },
     *                          {
     *                              label: "label4",
     *                              value: 468
     *                          }
     *                      ]
     *                  }
     *              }());
     *         });
     *     </file>
     * </example>
     * */
    chartingModule.directive("treemapChart", [function () {
        return {
            restrict: "A",
            scope: {
                context: "=treemapChart",
                width: "@?",
                height: "@?"
            },
            template: template,
            link: function ($scope, $element) {

                $scope.width = parseInt($scope.width) || 700;
                $scope.height = parseInt($scope.height) || 500;

                var color = d3.scale.category20c();

                var treemap = d3.layout.treemap()
                    .size([$scope.width, $scope.height])
                    .value(function (d) {
                        return d.size;
                    });

                var rootNode = d3.select($element[0]);
                var svg = rootNode.select(".treemap-chart")
                    .append("svg")
                    .attr("width", $scope.width)
                    .attr("height", $scope.height)
                    .attr("transform", "translate(-.5,-.5)");

                var percentageFormat = d3.format("0.2%");

                generate(processData($scope.context.data));

                $scope.$watch(function () {
                    return $scope.context.data;
                }, function (newContextData) {
                    load(processData(newContextData));
                }, true);

                function processData(rawData) {
                    $scope.total = 0;
                    var processedData = {
                        name: "Chart",
                        children: []
                    };

                    var label = $scope.context.configs.properties.label || "label";
                    var value = $scope.context.configs.properties.value || "value";

                    rawData.forEach(function (data) {
                        $scope.total += data[value];
                        processedData.children.push({
                            "name": data[label],
                            "size": data[value]
                        });
                    });
                    return processedData;
                }

                function generate(processedData) {
                    var cell = svg.datum(processedData).selectAll("g")
                        .data(treemap.nodes, function (d) {
                            return d.name;
                        })
                        .enter().append("g")
                        .attr("transform", function (d) {
                            return "translate(" + d.x + "," + d.y + ")";
                        });

                    cell.append("rect")
                        .attr("class", "treemap-cell")
                        .attr("width", function (d) {
                            return d.dx;
                        })
                        .attr("height", function (d) {
                            return d.dy;
                        })
                        .style("fill", function (d) {
                            return d.children ? null : color(d.name);
                        });

                    cell.append("text")
                        .attr("x", function (d) {
                            return d.dx / 2;
                        })
                        .attr("y", function (d) {
                            return d.dy / 2;
                        })
                        .attr("dy", ".35em")
                        .attr("text-anchor", "middle")
                        .attr("font-size", function () {
                            return 12;
                        })
                        .text(function (d) {
                            return d.depth === 0 ? "" : d.name;
                        });
                }

                function load(newProcessedData) {
                    //data joins
                    var rects = svg.datum(newProcessedData).selectAll("rect")
                        .data(treemap.nodes, function (d) {
                            return d.name;
                        });

                    var gs = svg.datum(newProcessedData).selectAll("g")
                        .data(treemap.nodes, function (d) {
                            return d.name;
                        });

                    var texts = svg.datum(newProcessedData).selectAll("text")
                        .data(treemap.nodes, function (d) {
                            return d.name;
                        });

                    //update
                    rects.transition()
                        .duration(1000)
                        .attr("width", function (d) {
                            return d.dx;
                        })
                        .attr("height", function (d) {
                            return d.dy;
                        });

                    gs.on("mousemove", mousemove)
                        .on("mouseout", mouseout);

                    gs.transition()
                        .duration(1000)
                        .attr("transform", function (d) {
                            return "translate(" + d.x + "," + d.y + ")";
                        });

                    texts.transition()
                        .duration(1000)
                        .attr("x", function (d) {
                            return d.dx / 2;
                        })
                        .attr("y", function (d) {
                            return d.dy / 2;
                        });

                    //remove
                    gs.exit()
                        .transition()
                        .duration(1000)
                        .attr("width", 0)
                        .attr("height", 0)
                        .remove();

                    rects.exit()
                        .transition()
                        .duration(1000)
                        .attr("width", 0)
                        .attr("height", 0)
                        .remove();

                    texts.exit()
                        .transition()
                        .duration(1000)
                        .attr("x", 0)
                        .attr("y", 0)
                        .attr("font-size", 0)
                        .remove();

                    //add
                    var newCells = gs.enter().append("g")
                        .attr("transform", function (d) {
                            return "translate(" + d.x + "," + d.y + ")";
                        })
                        .on("mousemove", mousemove)
                        .on("mouseout", mouseout);

                    newCells.append("rect")
                        .attr("class", "treemap-cell")
                        .attr("width", 0)
                        .attr("height", 0)
                        .transition()
                        .duration(1000)
                        .attr("width", function (d) {
                            return d.dx;
                        })
                        .attr("height", function (d) {
                            return d.dy;
                        })
                        .style("fill", function (d) {
                            return d.children ? null : color(d.name);
                        });

                    newCells.append("text")
                        .attr("x", 0)
                        .attr("y", 0)
                        .attr("font-size", 0)
                        .transition()
                        .duration(1000)
                        .attr("x", function (d) {
                            return d.dx / 2;
                        })
                        .attr("y", function (d) {
                            return d.dy / 2;
                        })
                        .attr("dy", ".35em")
                        .attr("font-size", function (d) {
                            return getTextSize(d.name, d.dx);
                        })
                        .attr("text-anchor", "middle")
                        .text(function (d) {
                            return d.depth === 0 ? "" : d.name;
                        });
                }

                function getTextSize(text, maxWidth) {
                    if (maxWidth === 0) {
                        return 0;
                    }
                    var fontSize = 14;
                    var ruler = rootNode.selectAll(".ruler");
                    var rulerElement = ruler[0][0];

                    ruler.style("font-size", fontSize + "px")
                        .text(text);

                    while (rulerElement.offsetWidth > maxWidth) {
                        fontSize = fontSize - 1;
                        if (fontSize < 1) {
                            return 0;
                        }
                        ruler.style("font-size", fontSize + "px")
                            .text(text);
                    }
                    return fontSize;
                }

                var mousemove = function (d) {
                    if (d.depth === 0) {
                        return;
                    }

                    var xPosition = d3.event.layerX + 5;
                    var yPosition = d3.event.layerY + 5;

                    if (d.y + d.dy === $scope.height) {
                        yPosition -= 100;
                    }
                    if (d.x + d.dx === $scope.width) {
                        xPosition -= 230;
                    }

                    var tooltip = rootNode.select("div.treemap-chart-tooltip");
                    tooltip.style("left", xPosition + "px")
                        .style("top", yPosition + "px")
                        .style("z-index", 5000);
                    tooltip.select("h1.header").text(d.name);
                    tooltip.select("span.value").text(d.size);
                    tooltip.select("span.percentage").text(percentageFormat(d.size / $scope.total));
                    tooltip.style("display", "inline");
                };

                var mouseout = function () {
                    var tooltip = rootNode.select("div.treemap-chart-tooltip");
                    tooltip.style("display", "none");
                };
            }
        };
    }]);
});