define([
    "./monitorModule",
    "lodash"
], function (module, _) {
    "use strict";

    return module.controller("MonitorOverviewController", ["$scope", "monitorService", "$q", "$interval", function ($scope, monitorService, $q, $interval) {
        init();
        var interval = $interval(init, 10000);
        $scope.$on("$destroy", function () {
            $interval.cancel(interval);
        });

        function init() {
            $scope.loading = true;
            return monitorService.findApps()
                .then(function (applications) {
                    $scope.applications = applications;
                    return loadHealth();
                })
                .finally(function () {
                    $scope.loading = false;
                });
        }

        function loadHealth() {
            return $q.all(_.flatMap($scope.applications.application, function (application) {
                return application.instance;
            }).map(function (instance) {
                return monitorService.findEndpoint(instance.metadata["context-path"], "health")
                    .then(function (health) {
                        instance.healthError = null;
                        instance.health = health;
                    })
                    .catch(function (response) {
                        instance.health = {
                            status: "ERROR"
                        };
                        instance.healthError = response;
                    });
            }));
        }

    }]);
});