define([
    "./nlgProgressBarModule",
    "progressbar",
    "text!./nlgProgressBar.html"
], function (nlgProgressBarModule, progressbar, template) {
    "use strict";

    return nlgProgressBarModule.directive("nlgProgressBar", [function () {
        return {
            restrict: "A",
            template: template,
            scope: {
                registerProgressBarApi: "&"
            },
            link: function ($scope, element) {
                var bar = new progressbar.Line(element[0], {
                    position: "relative",
                    duration: 20,
                    easing: "easeInOut",
                    color: "#66CDAA",
                    trailColor: "#DCDCDC",
                    trailWidth: "0.1",
                    svgStyle: {width: "100%", height: "100%"},
                    text: {
                        style: {
                            color: "#999",
                            right: "0",
                            top: "30px",
                            padding: 0,
                            margin: 0,
                            transform: null
                        },
                        autoStyleContainer: false
                    }
                });

                var progressBarApi = {};
                progressBarApi.animate = function(animationPercent){
                    bar.animate(animationPercent);
                };
                progressBarApi.setText = function(textAnimation){
                    bar.setText(textAnimation);
                };
                $scope.registerProgressBarApi({api: progressBarApi});
            }
        };
    }]);
});

