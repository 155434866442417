define([
    "./chartingModule",
    "text!./summaryChart.html",
], function (chartingModule, template) {
    "use strict";

    chartingModule.directive("summaryChart", ["$translate", function ($translate) {
        return {
            restrict: "A",
            scope: {
                context: "=summaryChart"
            },
            template: template,
            controller: ["$scope", function ($scope) {

                $scope.getSummaryChartTitle = function () {
                    var data = $scope.context.data;
                    var dataLength = data.length;
                    if (!data || dataLength === 0) {
                        return;
                    }
                    if (dataLength > 1) {
                        throw "More than one value per record is not allowed!";
                    }
                    return $translate.instant($scope.context.configs.legendTitle.identifier) + ": " + data[0].identifier;
                };
            }]
        };
    }]);
});
