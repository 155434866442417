define([
    "./backgroundTaskJobModule",
], function (module) {
    "use strict";

    return module.provider("backgroundTaskJobUrls", [function () {
        var urls = {
            // filterService: "transactional/tasks",
            // cancelJob: server.baseUrl + server.services.backgroundTaskJob.cancelTask,
            // downloadJob: server.baseUrl + server.services.backgroundTaskJob.downloadTask,
            // violations: server.baseUrl + server.services.backgroundTaskJob.violations,
            // canDownloadTask: server.baseUrl + server.services.backgroundTaskJob.canDownloadTask,
            // downloadTask: server.baseUrl + server.services.backgroundTaskJob.downloadTask,
            // getExportType: server.baseUrl + server.services.backgroundTaskJob.getExportType,
        };

        this.setUrls = function (_urls) {
            urls = _urls;
        };

        this.$get = [function () {
            return urls;
        }];
    }]);
});