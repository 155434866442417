define(["./backgroundTaskJobModule", "text!./automaticOptimizationViolationModal.html"], function (managerModule, template) {
    "use strict";

    /**
     * @ngdoc service
     * @name managerModule.automaticOptimizationViolationModal
     * @description
     * Serviço que exibe para o usuário as violações de uma otimização automática.
     *
     * @param {violationResult} violationResult Resultado de uma operação manual.
     * @return {Promise} promessa que é resolvida com o próprio `violationResult` fornecido.
     * */
    return managerModule.service("automaticOptimizationViolationModal", ["$modal", function ($modal) {
        return function (violationResult) {
            var hasViolations = violationResult.duPayloadViolations.length + violationResult.tripPayloadpViolations.length;
            return $modal.open({
                size: hasViolations ? "xl" : "",
                template: template,
                controller: ["$scope", function ($scope) {
                    $scope.generalViolations = violationResult.generalViolations;
                    $scope.duPayloadViolations = violationResult.duPayloadViolations;
                    $scope.tripPayloadpViolations = violationResult.tripPayloadpViolations;
                }]
            }).result;
        };
    }]);
});