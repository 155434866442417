define(["../nodeCommandsModule", "text!./nodeDownloadAttachmentsAction.html"], function (nodeCommandsModule, nodeDownloadAttachmentsActionTemplate) {
    "use strict";

    nodeCommandsModule.directive("nodeDownloadAttachmentsAction", [function () {
        return {
            restrict: "E",
            template: nodeDownloadAttachmentsActionTemplate,
            scope: {
                node: "=",
                property: "="
            },
            controller: ["$scope", "rootNodeCommandExecutorSupplier", function ($scope, rootNodeCommandExecutorSupplier) {
                var rootNodeCommandExecutor = rootNodeCommandExecutorSupplier.getRoot();
                $scope.execute = function ($event) {
                    $event.stopPropagation();
                    // tem anexos?
                    if ($scope.property.innerNodeProperty.value) {
                        var nodeCommand = rootNodeCommandExecutor.get($scope.property.command);
                        nodeCommand($scope.node)
                            .then(function () {
                            });
                    }
                };
            }]
        };
    }]);
});