define([
    "angular",
    "../arrays/arrays",
    "lodash",
    "./mapsModule"
], function (angular, arrays, _, mapsModule) {
    "use strict";

    return mapsModule.service("nlgRouteMapMarkerService", ["$rootScope",function ($rootScope) {
        return {
            getSelectedMarkersCodes: getSelectedMarkersCodes,
            filterDuplicatedTripCodes: filterDuplicatedTripCodes,
            toggleMarkers: toggleMarkers,
            selectAllMapMarkers: selectAllMapMarkers,
            cleanSelection: cleanSelection,
            getCoordsOfSelectedMarkers: getCoordsOfSelectedMarkers,
            isSelected: isSelected,
            isEmpty: isEmpty
        };

        function getSelectedMarkersCodes() {
            return arrays.map($rootScope.selectedMapMarkers, function (marker) {
                return marker.identifier;
            });
        }

        function filterDuplicatedTripCodes(tripCodes) {
            return _.filter(tripCodes, function (value, index) {
                return tripCodes.indexOf(value) === index;
            });
        }

        function toggleMarkers(markers) {
            _.forEach(markers, function (marker) {
                if (isSelected(marker)) {
                    setDefaultIconToMarker(marker);
                    arrays.remove($rootScope.selectedMapMarkers, marker);
                    return;
                }
                setSelectedIconToMarker(marker);
                $rootScope.selectedMapMarkers.push(marker);
            });
        }

        function selectAllMapMarkers(markers) {
            _.forEach(markers, function (marker) {
                if (isSelected(marker)) {
                    return;
                }
                setSelectedIconToMarker(marker);
                $rootScope.selectedMapMarkers.push(marker);
            });
        }

        function getCoordsOfSelectedMarkers() {
            return arrays.map($rootScope.selectedMapMarkers, function (marker) {
                return marker.coords;
            });
        }

        function cleanSelection() {
            var copy = arrays.copy($rootScope.selectedMapMarkers);
            _.forEach(copy, function (marker) {
                setDefaultIconToMarker(marker);
                arrays.remove($rootScope.selectedMapMarkers, marker);
            });
        }

        function isSelected(marker) {
            if (arrays.contains($rootScope.selectedMapMarkers, marker)){
                return true;
            }
            return false;
        }
        function isEmpty() {
            return function () {
                return $rootScope.selectedMapMarkers.length === 0;
            };
        }

        function setSelectedIconToMarker(marker) {
            marker.icon = "images/marker-pin-check.png";
        }

        function setDefaultIconToMarker(marker) {
            marker.icon = "images/marker-pin.png";
        }
    }]);
});