define([
    "./commonScriptModule",
    "text!./nlgScriptEditor.html"
], function (scriptModule, template) {
    "use strict";

    /**
     * @ngdoc directive
     * @name scriptModule.directive:nlgScriptEditor
     * @description
     * Directiva que disponibiliza um editor de Script.
     *
     * @param {object[]} ngModel : Script para ser editado.
     * @param {string} language : Linguagem do Script (JAVA, JAVASCRIPT, SQL)
     *
     **/
    return scriptModule.directive("nlgScriptEditor", ["ScriptLanguage", function (ScriptLanguage) {
        return {
            restrict: "E",
            template: template,
            scope: {
                disabled: "=?ngDisabled",
                model: "=ngModel",
                language: "="
            },
            controller: ["$scope", "$attrs", function ($scope, $attrs) {
                $scope.model = $scope.model || "";

                $scope.scriptConfig = {
                    lineNumbers: true,
                    autoRefresh: true,
                    readOnly: $scope.disabled ? "nocursor" : false,
                    onLoad: function (codemirror) {
                        codemirror.setSize($attrs.width || "100%", $attrs.height || 300);
                        $scope.$watch("language", function (newVal) {
                            if (newVal === ScriptLanguage.JAVASCRIPT) {
                                codemirror.setOption("mode", "text/javascript");
                            } else if (newVal === ScriptLanguage.JAVA) {
                                codemirror.setOption("mode", "text/x-java");
                            } else {
                                codemirror.setOption("mode", "text/x-sql");
                            }
                        });
                    }
                };

                $scope.$on("$destroy", function(){
                    $scope.scriptConfig.autoRefresh = false;
                });
            }]
        };
    }]);
});